import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from 'react';

interface ProposalPreviewDefaultSectionTitleStylesProps {
  titleMaxWidth: CSSProperties['maxWidth'];
}

export const getProposalPreviewDefaultSectionTitleStyles = makeStyles<
  Theme,
  ProposalPreviewDefaultSectionTitleStylesProps
>((theme) => {
  return {
    title: {
      maxWidth: ({ titleMaxWidth }): CSSProperties['maxWidth'] => titleMaxWidth,
      textTransform: 'uppercase',
    },
    subtitle: {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  };
});
