import { ProposalLayoutSettingSchemaText } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Settings/Text';
import { ControlledTextField } from '@easysolar/proposals/shared/components/ControlledTextField';

import { ProposalEditorSettingBase } from './Base';

export const ProposalEditorSettingText: ProposalEditorSettingBase<ProposalLayoutSettingSchemaText> =
  ({ formControl, settingSchema }) => {
    return (
      <ControlledTextField
        formControl={formControl}
        label={settingSchema.title}
        type={settingSchema?.inputType}
        maxLength={settingSchema?.maxLength}
      />
    );
  };
