import { makeStyles } from '@material-ui/core';

export const getToggleableTextFieldStyles = makeStyles(() => ({
  formControl: {
    marginBottom: 0,
  },
  formControlLabel: {
    marginRight: 0,
    alignItems: 'flex-start',
  },
  label: {
    flex: 1,
  },
  switch: {
    marginTop: 9,
  },
  textField: {
    width: '100%',
    marginTop: 0,
  },
}));
