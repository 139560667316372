import { useMemo } from 'react';

import {
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName,
} from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema';

import { ProposalPreviewSection } from '../../../../Section';
import { ProposalPreviewDefaultSectionPage } from '../../Shared/SectionPage';
import { ProposalPreviewDefaultProposedSystemElectricityBill } from './ElectricityBill';
import { ProposalPreviewDefaultProposedSystemEnergyBalance } from './EnergyBalance';
import { ProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystem } from './RecommendedSizeOfPVSystem';

export const ProposalPreviewDefaultProposedSystem: ProposalPreviewSection<
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName.ProposedSystem
> = ({ generalSettings, sectionSettings }) => {
  const electricityBill = useMemo(
    () => (
      <ProposalPreviewDefaultProposedSystemElectricityBill
        title={sectionSettings.yourCurrentElectricityBill.title}
        showFacts={sectionSettings.yourCurrentElectricityBill.showFacts}
      />
    ),
    [
      sectionSettings.yourCurrentElectricityBill.showFacts,
      sectionSettings.yourCurrentElectricityBill.title,
    ],
  );

  const electricityBillElement = useMemo(
    () =>
      sectionSettings.yourCurrentElectricityBill.showSubsection &&
      electricityBill,
    [
      electricityBill,
      sectionSettings.yourCurrentElectricityBill.showSubsection,
    ],
  );

  const recommendedSizeOfPVSystem = useMemo(
    () => (
      <ProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystem
        title={sectionSettings.recommendedSizeOfPVSystem.title}
        showEnergyProductionAndConsumptionComparison={
          sectionSettings.recommendedSizeOfPVSystem
            .showEnergyProductionAndConsumptionComparison
        }
      />
    ),
    [
      sectionSettings.recommendedSizeOfPVSystem
        .showEnergyProductionAndConsumptionComparison,
      sectionSettings.recommendedSizeOfPVSystem.title,
    ],
  );

  const recommendedSizeOfPVSystemElement = useMemo(
    () =>
      sectionSettings.recommendedSizeOfPVSystem.showSubsection &&
      recommendedSizeOfPVSystem,
    [
      recommendedSizeOfPVSystem,
      sectionSettings.recommendedSizeOfPVSystem.showSubsection,
    ],
  );

  const energyBalance = useMemo(
    () => (
      <ProposalPreviewDefaultProposedSystemEnergyBalance
        title={sectionSettings.energyBalance.title}
      />
    ),
    [sectionSettings.energyBalance.title],
  );

  const energyBalanceElement = useMemo(
    () => sectionSettings.energyBalance.showSubsection && energyBalance,
    [energyBalance, sectionSettings.energyBalance.showSubsection],
  );

  const page = useMemo(
    () => (
      <ProposalPreviewDefaultSectionPage
        generalSettings={generalSettings}
        title={sectionSettings.title}
        subtitle={sectionSettings.subtitle}
      >
        {electricityBillElement}
        {recommendedSizeOfPVSystemElement}
        {energyBalanceElement}
      </ProposalPreviewDefaultSectionPage>
    ),
    [
      electricityBillElement,
      energyBalanceElement,
      generalSettings,
      recommendedSizeOfPVSystemElement,
      sectionSettings.subtitle,
      sectionSettings.title,
    ],
  );

  return page;
};
