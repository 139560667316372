import { Typography, TypographyProps } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

export interface NumberFormatProps {
  locales: string | string[];
  options?: Intl.NumberFormatOptions;
  value: number;
  display?: TypographyProps['display'];
  align?: TypographyProps['align'];
  className?: string;
  omitParts?: NumberFormatPartType[];
  additionalParts?: NumberFormatPart[];
  defaultProps?: Partial<TypographyProps>;
  partTypesProps?: Partial<{
    [partType in NumberFormatPartType]: Partial<TypographyProps>;
  }>;
}

export const NumberFormat: FunctionComponent<NumberFormatProps> = ({
  locales,
  options = { style: 'decimal' },
  value,
  display = 'inline',
  align,
  className,
  omitParts,
  additionalParts,
  defaultProps,
  partTypesProps,
}) => {
  const formatToParts = useMemo(() => {
    const intl = new Intl.NumberFormat(locales, options);

    return intl.formatToParts.bind(intl);
  }, [locales, options]);

  const omittedPartsSet = useMemo(() => new Set(omitParts), [omitParts]);

  const initialParts = useMemo(() => {
    const parts = formatToParts(value);

    return omittedPartsSet.size
      ? parts.filter((part) => !omittedPartsSet.has(part.type))
      : parts;
  }, [formatToParts, omittedPartsSet, value]);

  const parts = useMemo(
    () => [...initialParts, ...(additionalParts || [])],
    [additionalParts, initialParts],
  );

  const renderedParts = useMemo(
    () =>
      parts.map((part, index) => {
        const props = {
          ...{ component: 'span' },
          ...defaultProps,
          ...partTypesProps?.[part.type],
        };

        return (
          // eslint-disable-next-line react/no-array-index-key, react/jsx-props-no-spreading
          <Typography key={index} {...props}>
            {part.value}
          </Typography>
        );
      }),
    [defaultProps, partTypesProps, parts],
  );

  const container = useMemo(
    () => (
      <Typography
        component="div"
        className={className}
        display={display}
        align={align}
      >
        {renderedParts}
      </Typography>
    ),
    [align, className, display, renderedParts],
  );

  return container;
};

export interface NumberFormatPart {
  type: NumberFormatPartType;
  value: string;
}

type NumberFormatPartType =
  | 'compact'
  | 'currency'
  | 'decimal'
  | 'exponentInteger'
  | 'exponentMinusSign'
  | 'exponentSeparator'
  | 'fraction'
  | 'group'
  | 'infinity'
  | 'integer'
  | 'literal'
  | 'minusSign'
  | 'nan'
  | 'plusSign'
  | 'percentSign'
  | 'unit'
  | 'unknown';
