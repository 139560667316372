import { makeStyles } from '@material-ui/core';

export const getProposalEditorToolbarGenerationSectionStyles = makeStyles(
  (theme) => ({
    grid: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    loader: {
      position: 'absolute',
    },
  }),
);
