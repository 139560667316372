import { Grid, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { CSSProperties, FunctionComponent, useMemo } from 'react';

import { getProposalPreviewDefaultSectionTitleStyles } from './SectionTitle.styles';

export interface ProposalPreviewDefaultSectionTitleProps {
  title?: string;
  subtitle?: string;
  variant?: Variant;
  titleMaxWidth?: CSSProperties['maxWidth'];
}

export const ProposalPreviewDefaultSectionTitle: FunctionComponent<ProposalPreviewDefaultSectionTitleProps> =
  ({ title, subtitle, variant = 'h4', titleMaxWidth }) => {
    const classes = getProposalPreviewDefaultSectionTitleStyles({
      titleMaxWidth,
    });

    const renderedTitle = useMemo(
      () => (
        <Typography className={classes.title} variant={variant}>
          {title}
        </Typography>
      ),
      [classes.title, title, variant],
    );

    const renderedSubtitle = useMemo(
      () => (
        <Typography className={classes.subtitle} variant={variant}>
          {subtitle}
        </Typography>
      ),
      [classes.subtitle, subtitle, variant],
    );

    return (
      <Grid container direction="column" wrap="nowrap">
        {renderedTitle}
        {renderedSubtitle}
      </Grid>
    );
  };
