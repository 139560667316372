import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { NumberFormat } from '@easysolar/proposals/shared/components/NumberFormat';
import { UnitNumberFormatProps } from '@easysolar/proposals/shared/components/UnitNumberFormat';

export type ProposalNumberFormatProps = Omit<UnitNumberFormatProps, 'locales'>;

export const ProposalNumberFormat: FunctionComponent<ProposalNumberFormatProps> =
  (props) => {
    const {
      i18n: { language },
    } = useTranslation();

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <NumberFormat {...props} locales={language} />;
  };
