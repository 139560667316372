import { SvgIcon } from '@material-ui/core';
import {
  FunctionComponent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { ProposalEditorToolbarTemplatesSectionButton } from './Button';

export interface UseProposalTemplateActionProps<DialogResultType = undefined> {
  button: {
    tooltip: string;
    IconComponent: typeof SvgIcon;
  };
  dialog: {
    title: string;
    confirmButtonLabel: string;
    render: FunctionComponent<{
      open: boolean;
      title: string;
      confirmButtonLabel: string;
      onClose: () => void;
      onConfirm: (result?: DialogResultType) => void;
    }>;
  };
  disabled: boolean;
  loading: boolean;
  onConfirm: (result?: DialogResultType) => void;
}

interface UseProposalTemplateActionResult {
  button: ReactElement;
}

export function useProposalTemplateAction<DialogResultType = undefined>({
  button: { tooltip: buttonTooltip, IconComponent: ButtonIconComponent },
  dialog: {
    title: dialogTitle,
    confirmButtonLabel: dialogConfirmButtonLabel,
    render: renderDialog,
  },
  disabled,
  loading,
  onConfirm,
}: UseProposalTemplateActionProps<DialogResultType>): UseProposalTemplateActionResult {
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);
  const openDialog = useCallback(() => setDialogOpen(true), []);

  const button = useMemo(
    () => (
      <ProposalEditorToolbarTemplatesSectionButton
        title={buttonTooltip}
        IconComponent={ButtonIconComponent}
        disabled={disabled}
        loading={loading}
        onClick={openDialog}
      />
    ),
    [ButtonIconComponent, buttonTooltip, disabled, loading, openDialog],
  );

  const onDialogConfirm = useCallback(
    (result?: DialogResultType) => {
      if (onConfirm) {
        onConfirm(result);
      }

      closeDialog();
    },
    [closeDialog, onConfirm],
  );

  const dialog = useMemo(
    () =>
      renderDialog({
        open: dialogOpen,
        title: dialogTitle,
        confirmButtonLabel: dialogConfirmButtonLabel,
        onClose: closeDialog,
        onConfirm: onDialogConfirm,
      }),
    [
      closeDialog,
      dialogConfirmButtonLabel,
      dialogOpen,
      dialogTitle,
      onDialogConfirm,
      renderDialog,
    ],
  );

  const fragment = useMemo(
    () => (
      <>
        {button}
        {dialog}
      </>
    ),
    [button, dialog],
  );

  return { button: fragment };
}
