import { Grid } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

import { ProposalUnitNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalUnitNumberFormat';
import {
  ProposalPreviewDefaultIcon,
  ProposalPreviewDefaultSvgIcon,
} from '@easysolar/proposals/modules/ProposalPreview/components/Layouts/Default/Shared/SvgIcon';

import { getProposalPreviewDefaultProposedSystemEnergyBalanceFlowStyles } from './Flow.styles';

export interface ProposalPreviewDefaultProposedSystemEnergyBalanceFlowProps {
  icon: ProposalPreviewDefaultIcon;
  direction: ProposalPreviewDefaultProposedSystemEnergyBalanceFlowDirection;
  value: number;
}

export enum ProposalPreviewDefaultProposedSystemEnergyBalanceFlowDirection {
  Up = 'up',
  Down = 'down',
}

export const ProposalPreviewDefaultProposedSystemEnergyBalanceFlow: FunctionComponent<ProposalPreviewDefaultProposedSystemEnergyBalanceFlowProps> =
  ({ icon, direction, value }) => {
    const classes =
      getProposalPreviewDefaultProposedSystemEnergyBalanceFlowStyles();

    const directionIcon = useMemo(() => {
      switch (direction) {
        case ProposalPreviewDefaultProposedSystemEnergyBalanceFlowDirection.Up: {
          return ProposalPreviewDefaultIcon.ChevronUp;
        }

        case ProposalPreviewDefaultProposedSystemEnergyBalanceFlowDirection.Down: {
          return ProposalPreviewDefaultIcon.ChevronDown;
        }

        default: {
          return ProposalPreviewDefaultIcon.ChevronUp;
        }
      }
    }, [direction]);

    return (
      <Grid item container direction="column" alignItems="center">
        <ProposalPreviewDefaultSvgIcon className={classes.icon} icon={icon} />
        <Grid
          className={classes.flowWithValue}
          item
          container
          direction="column"
          alignItems="center"
        >
          <ProposalPreviewDefaultSvgIcon
            className={classes.flow}
            icon={directionIcon}
          />
          <ProposalUnitNumberFormat
            className={classes.value}
            value={value}
            unit="kWh"
            options={{
              maximumFractionDigits: 0,
            }}
            defaultProps={{
              color: 'primary',
              className: classes.nonUnit,
            }}
            partTypesProps={{
              unit: {
                className: classes.unit,
              },
            }}
          />
          <ProposalPreviewDefaultSvgIcon
            className={classes.flow}
            icon={directionIcon}
          />
        </Grid>
        <ProposalPreviewDefaultSvgIcon
          className={classes.icon}
          icon={ProposalPreviewDefaultIcon.HomeWithPanels}
        />
      </Grid>
    );
  };
