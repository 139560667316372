import {
  FormControl,
  InputBaseComponentProps,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { FunctionComponent, useCallback } from 'react';
import * as React from 'react';

import { ImageFieldDropzone } from './ImageFieldDropzone';

export interface ImageFieldProps {
  value: string;
  onChange: (value: File) => void;
  label: string;
  isLoading: boolean;
}

export const ImageField: FunctionComponent<ImageFieldProps> = ({
  value,
  onChange,
  label,
  isLoading,
}) => {
  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const file = event.target.files?.[0];
      if (file) {
        onChange(file);
      }
    },
    [onChange],
  );

  const inputComponent = useCallback(
    (props: InputBaseComponentProps) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <ImageFieldDropzone isLoading={isLoading} {...props} />
    ),
    [isLoading],
  );

  return (
    <FormControl margin="normal">
      <InputLabel shrink variant="outlined">
        {label}
      </InputLabel>
      <OutlinedInput
        value={value}
        onChange={onInputChange}
        label={label}
        notched
        inputComponent={inputComponent}
      />
    </FormControl>
  );
};
