import { Grid } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { FunctionComponent, useMemo } from 'react';

import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';

import { ProposalPreviewDefaultSubsection } from '../Subsection';
import { getProposalPreviewDefaultContactSubsectionStyles } from './ContactSubsection.styles';
import { ProposalPreviewDefaultContactSubsectionLabel } from './Label';
import { ProposalPreviewDefaultContactSubsectionRow } from './Row';

interface ProposalPreviewDefaultContactSubsectionProps {
  title: string;
  showCompanyName?: boolean;
  showEmployeeName?: boolean;
  showEmployeeEmailAddress?: boolean;
  showEmployeePhoneNumber?: boolean;
}

export const ProposalPreviewDefaultContactSubsection: FunctionComponent<ProposalPreviewDefaultContactSubsectionProps> =
  ({
    title,
    showCompanyName = true,
    showEmployeeName = true,
    showEmployeeEmailAddress = true,
    showEmployeePhoneNumber = true,
  }) => {
    const { company, employee } = useProposalContext();

    const classes = getProposalPreviewDefaultContactSubsectionStyles();

    const companyName = useMemo(
      () =>
        showCompanyName && (
          <ProposalPreviewDefaultContactSubsectionLabel
            Icon={HomeOutlinedIcon}
            text={company.name}
          />
        ),
      [company.name, showCompanyName],
    );

    const employeeName = useMemo(
      () =>
        showEmployeeName && (
          <ProposalPreviewDefaultContactSubsectionLabel
            Icon={AccountBoxIcon}
            text={employee.name}
            justifyContent="flex-end"
          />
        ),
      [employee.name, showEmployeeName],
    );

    const firstRow = useMemo(
      () =>
        (companyName || employeeName) && (
          <ProposalPreviewDefaultContactSubsectionRow>
            {companyName}
            {employeeName}
          </ProposalPreviewDefaultContactSubsectionRow>
        ),
      [companyName, employeeName],
    );

    const employeeEmailAddress = useMemo(
      () =>
        showEmployeeEmailAddress && (
          <ProposalPreviewDefaultContactSubsectionLabel
            Icon={MailOutlineIcon}
            text={employee.emailAddress}
          />
        ),
      [employee.emailAddress, showEmployeeEmailAddress],
    );

    const employeePhoneNumber = useMemo(
      () =>
        showEmployeePhoneNumber && (
          <ProposalPreviewDefaultContactSubsectionLabel
            Icon={CallOutlinedIcon}
            text={employee.phoneNumber}
            justifyContent="flex-end"
          />
        ),
      [employee.phoneNumber, showEmployeePhoneNumber],
    );

    const secondRow = useMemo(
      () =>
        (employeeEmailAddress || employeePhoneNumber) && (
          <ProposalPreviewDefaultContactSubsectionRow>
            {employeeEmailAddress}
            {employeePhoneNumber}
          </ProposalPreviewDefaultContactSubsectionRow>
        ),
      [employeeEmailAddress, employeePhoneNumber],
    );

    return firstRow || secondRow ? (
      <ProposalPreviewDefaultSubsection title={title}>
        <Grid
          container
          className={classes.root}
          direction="column"
          wrap="nowrap"
        >
          {firstRow}
          {secondRow}
        </Grid>
      </ProposalPreviewDefaultSubsection>
    ) : null;
  };
