import { Typography } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

import { getProposalPreviewDefaultEnergyProductionCoordinateStyles } from './Coordinate.styles';

export enum CoordinateAxis {
  NorthSouth = 'NorthSouth',
  EastWest = 'EastWest',
}

export interface ProposalPreviewDefaultEnergyProductionCoordinateProps {
  coordinate: number;
  axis: CoordinateAxis;
}

export const ProposalPreviewDefaultEnergyProductionCoordinate: FunctionComponent<ProposalPreviewDefaultEnergyProductionCoordinateProps> =
  ({ coordinate, axis }) => {
    const classes = getProposalPreviewDefaultEnergyProductionCoordinateStyles();

    const parts = useMemo(() => {
      const absoluteCoordinate = Math.abs(coordinate);

      const degrees = Math.floor(absoluteCoordinate);
      const minutes = Math.floor(absoluteCoordinate * 60) % 60;
      const seconds = Math.round(absoluteCoordinate * 60 * 60) % 60;

      return {
        degrees,
        minutes,
        seconds,
      };
    }, [coordinate]);

    const direction = useMemo((): string | null => {
      switch (axis) {
        case CoordinateAxis.NorthSouth: {
          return coordinate >= 0 ? 'N' : 'S';
        }

        case CoordinateAxis.EastWest: {
          return coordinate >= 0 ? 'W' : 'E';
        }

        default: {
          return null;
        }
      }
    }, [axis, coordinate]);

    const text = useMemo(
      () => `${parts.degrees}°${parts.minutes}′${parts.seconds}″ ${direction}`,
      [direction, parts.degrees, parts.minutes, parts.seconds],
    );

    return (
      <Typography variant="body2" className={classes.root}>
        {text}
      </Typography>
    );
  };
