import { useTheme } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  XAxis,
  YAxis,
} from 'recharts';

import { usePrintingContext } from '@easysolar/proposals/modules/ProposalPreview/hooks/usePrintingContext';
import { millimetresToPixels } from '@easysolar/proposals/modules/ProposalPreview/utils/units';

export interface ProposalPreviewDefaultChartProps<
  DataType extends Record<string, unknown>,
> {
  width: number;
  height: number;
  data: DataType[];
  xAxisDataKey: keyof DataType;
  yAxisDataKey: keyof DataType;
  yAxisCompactNotation?: boolean;
}

export const ProposalPreviewDefaultChart = <
  DataType extends Record<string, unknown>,
>({
  width,
  height,
  data,
  xAxisDataKey,
  yAxisDataKey,
  yAxisCompactNotation = false,
}: ProposalPreviewDefaultChartProps<DataType>): React.ReactElement => {
  const {
    i18n: { language },
  } = useTranslation();

  const { printing } = usePrintingContext();

  const theme = useTheme();
  const strokeDasharray = '1 2';

  const barCategoryGap = useMemo(() => millimetresToPixels(1.7), []);

  const axesStyle = useMemo(
    () => ({
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.caption.fontSize,
    }),
    [theme.typography.caption.fontSize, theme.typography.fontFamily],
  );

  const xAxisPadding = useMemo(() => millimetresToPixels(3), []);

  const yAxisWidth = useMemo(() => millimetresToPixels(15), []);

  const shape = useCallback(
    ({ height: barHeight, ...props }) => {
      const fill =
        barHeight >= 0
          ? theme.palette.primary.main
          : theme.palette.text.secondary;

      return (
        <Rectangle
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          height={barHeight}
          fill={fill}
          isAnimationActive={!printing}
        />
      );
    },
    [printing, theme.palette.primary.main, theme.palette.text.secondary],
  );

  const cartesianGrid = useMemo(
    () => (
      <CartesianGrid
        vertical={false}
        stroke={theme.palette.text.hint}
        strokeDasharray={strokeDasharray}
      />
    ),
    [theme.palette.text.hint],
  );

  const xAxis = useMemo(
    () => (
      <XAxis
        dataKey={xAxisDataKey as string}
        padding={{ left: xAxisPadding, right: xAxisPadding }}
        tickLine={false}
        stroke={theme.palette.text.secondary}
        strokeDasharray={strokeDasharray}
        style={axesStyle}
      />
    ),
    [axesStyle, theme.palette.text.secondary, xAxisDataKey, xAxisPadding],
  );

  const compactNumberFormatter = useMemo(
    () =>
      new Intl.NumberFormat(language, {
        notation: 'compact',
        compactDisplay: 'short',
      }),
    [language],
  );

  const yAxisTickFormatter = useCallback(
    (value: number): string => {
      return compactNumberFormatter.format(value);
    },
    [compactNumberFormatter],
  );

  const yAxis = useMemo(
    () => (
      <YAxis
        width={yAxisWidth}
        stroke={theme.palette.text.secondary}
        strokeDasharray={strokeDasharray}
        style={axesStyle}
        tickFormatter={yAxisCompactNotation ? yAxisTickFormatter : undefined}
      />
    ),
    [
      axesStyle,
      yAxisCompactNotation,
      theme.palette.text.secondary,
      yAxisTickFormatter,
      yAxisWidth,
    ],
  );

  const bar = useMemo(
    () => (
      <Bar
        dataKey={yAxisDataKey as string}
        shape={shape}
        fill={theme.palette.primary.main}
        isAnimationActive={!printing}
      />
    ),
    [printing, shape, theme.palette.primary.main, yAxisDataKey],
  );

  const chart = useMemo(
    () => (
      <BarChart
        width={width}
        height={height}
        data={data}
        barGap={0}
        barCategoryGap={barCategoryGap}
      >
        {cartesianGrid}
        {xAxis}
        {yAxis}
        {bar}
      </BarChart>
    ),
    [bar, barCategoryGap, cartesianGrid, data, height, width, xAxis, yAxis],
  );

  return chart;
};
