import { Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { ProposalUnitNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalUnitNumberFormat';

import {
  ProposalPreviewDefaultProgressBar,
  ProposalPreviewDefaultProgressBarProps,
} from '../../../../Shared/ProgressBar';

export interface ProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystemProgressBarProps
  extends Omit<
    ProposalPreviewDefaultProgressBarProps,
    'leftLabel' | 'rightLabel' | 'barHeight'
  > {
  label: string;
  value: number;
}

export const ProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystemProgressBar: FunctionComponent<ProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystemProgressBarProps> =
  ({ label, value, ...props }) => {
    return (
      <ProposalPreviewDefaultProgressBar
        leftLabel={
          <Typography component="span" variant="body2">
            {label}
          </Typography>
        }
        rightLabel={
          <ProposalUnitNumberFormat
            value={value}
            unit="kWh"
            options={{
              maximumFractionDigits: 0,
            }}
            defaultProps={{
              variant: 'h5',
              color: 'textPrimary',
            }}
            partTypesProps={{
              unit: {
                variant: 'body2',
              },
            }}
          />
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  };
