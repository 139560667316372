import {
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { ConfigurationService } from '@easysolar/proposals/infrastructure/services/ConfigurationService';

import { easysolarAPI } from '../services/easysolarAPI';
import { proposalTemplatesSlice } from './proposalTemplates';
import { webComponentPropsSlice } from './webComponentProps';

const combinedReducer = combineReducers({
  [webComponentPropsSlice.name]: webComponentPropsSlice.reducer,
  [easysolarAPI.reducerPath]: easysolarAPI.reducer,
  [proposalTemplatesSlice.name]: proposalTemplatesSlice.reducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

export const RESET_ACTION_TYPE = 'root/reset';

export const resetStore = (): AnyAction => ({ type: RESET_ACTION_TYPE });

const reducer: Reducer = (state: RootState, action: AnyAction) => {
  switch (action.type) {
    case RESET_ACTION_TYPE: {
      return combinedReducer(undefined, { type: undefined });
    }

    default: {
      return combinedReducer(state, action);
    }
  }
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(easysolarAPI.middleware),
  devTools: ConfigurationService.APP_CONFIG.debug
    ? {
        name: 'easysolar-proposals',
      }
    : false,
});

setupListeners(store.dispatch);
