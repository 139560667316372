import { Typography } from '@material-ui/core';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ProposalCurrencyNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalCurrencyNumberFormat';
import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';

import {
  ProposalPreviewDefaultTable,
  ProposalPreviewDefaultTableColumn,
  ProposalPreviewDefaultTableColumnOnRenderCell,
} from '../../../../Shared/Table';

interface Data {
  key: 'subsidies' | 'savingsAndSubsidies';
  label: JSX.Element;
  value: number;
}

export const ProposalPreviewDefaultInvestmentCostsElectricityBillTable: FunctionComponent =
  () => {
    const { t } = useTranslation();

    const {
      project: {
        analysisPeriod,
        financialAnalysis: {
          forecast: { savings, receivedSubsidies },
        },
      },
    } = useProposalContext();

    const onRenderLabelCell: ProposalPreviewDefaultTableColumnOnRenderCell<Data> =
      useCallback(
        ({ item: { label } }) => (
          <Typography variant="body2" color="textSecondary" align="left">
            {label}
          </Typography>
        ),
        [],
      );

    const onRenderValueCell: ProposalPreviewDefaultTableColumnOnRenderCell<Data> =
      useCallback(({ item: { key, value } }) => {
        const color =
          key === 'savingsAndSubsidies' ? 'primary' : 'textSecondary';

        return (
          <ProposalCurrencyNumberFormat
            value={value}
            display="block"
            align="right"
            defaultProps={{
              variant: 'h5',
              color,
            }}
            partTypesProps={{
              currency: {
                variant: 'body2',
              },
            }}
          />
        );
      }, []);

    const columns: ProposalPreviewDefaultTableColumn<Data>[] = useMemo(
      () => [
        {
          key: 'label',
          name: '',
          fieldName: 'label',
          width: '70%',
          onRenderCell: onRenderLabelCell,
        },
        {
          key: 'value',
          name: '',
          fieldName: 'value',
          width: '30%',
          onRenderCell: onRenderValueCell,
        },
      ],
      [onRenderLabelCell, onRenderValueCell],
    );

    const items: Data[] = useMemo(
      () => [
        {
          key: 'subsidies',
          label: (
            <Trans
              t={t}
              i18nKey="Layouts:Default.Sections.InvestmentCosts.Subsections.ElectricityBill.ReceivedSubsidiesLabel"
              count={analysisPeriod}
            >
              NormalText
              <strong>BoldText</strong>
            </Trans>
          ),
          value: receivedSubsidies,
        },
        {
          key: 'savingsAndSubsidies',
          label: (
            <Trans
              t={t}
              i18nKey="Layouts:Default.Sections.InvestmentCosts.Subsections.ElectricityBill.SavingsAndReceivedSubsidiesLabel"
              count={analysisPeriod}
            >
              NormalText
              <strong>BoldText</strong>
            </Trans>
          ),
          value: savings + receivedSubsidies,
        },
      ],
      [analysisPeriod, receivedSubsidies, savings, t],
    );

    const table = useMemo(
      () => (
        <ProposalPreviewDefaultTable
          columns={columns}
          items={items}
          showHeader={false}
        />
      ),
      [columns, items],
    );

    return table;
  };
