import { useEffect, useState } from 'react';
import { debounceTime, filter, map } from 'rxjs';

import { AbstractFormControl } from '@easysolar/proposals/shared/forms/models';

export function useLastValidFormData<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T = Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
>(formControl: AbstractFormControl<T, E>, dueTime = 200): T {
  const [lastValidFormData, setLastValidFormData] = useState(formControl.value);

  useEffect(() => {
    const lastValidValue$ = formControl.stateChanges.pipe(
      filter(({ valid }) => !!valid),
      map(({ value }) => value),
    );

    const debouncedLastValidValue$ = dueTime
      ? lastValidValue$.pipe(debounceTime(dueTime))
      : lastValidValue$;

    const subscription = debouncedLastValidValue$.subscribe((validValue) =>
      setLastValidFormData(validValue),
    );

    return (): void => {
      subscription.unsubscribe();
    };
  }, [dueTime, formControl.stateChanges]);

  return lastValidFormData;
}
