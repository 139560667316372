import { OutlinedTextFieldProps, TextField } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextFieldErrorType } from './TextFieldErrorType';

export interface TypedErrorTextFieldProps
  extends Omit<
    OutlinedTextFieldProps,
    'variant' | 'margin' | 'error' | 'helperText'
  > {
  value: string;
  maxLength?: number;
  errorType?: TextFieldErrorType | null;
}

export const TypedErrorTextField: FunctionComponent<TypedErrorTextFieldProps> =
  ({ errorType, maxLength, disabled, value, ...props }) => {
    const { t } = useTranslation();

    const errorMessage = useMemo((): string | null => {
      if (errorType) {
        switch (errorType) {
          case TextFieldErrorType.Required: {
            return t('Editor:ErrorMessages.Required');
          }

          case TextFieldErrorType.MaxLength: {
            return t('Editor:ErrorMessages.MaxLength', { maxLength });
          }

          default: {
            return t('Editor:ErrorMessages.Generic');
          }
        }
      }

      return null;
    }, [errorType, maxLength, t]);

    const showErrorState = useMemo(
      () => !disabled && !!errorMessage,
      [disabled, errorMessage],
    );

    const helperText = useMemo((): string => {
      if (disabled) {
        return ' ';
      }

      if (errorMessage) {
        return errorMessage;
      }

      if (maxLength) {
        return `${value?.length || 0} / ${maxLength}`;
      }

      return ' ';
    }, [disabled, errorMessage, maxLength, value]);

    return (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        margin="normal"
        variant="outlined"
        disabled={disabled}
        error={showErrorState}
        helperText={helperText}
        value={value}
      />
    );
  };
