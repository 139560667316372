import { makeStyles, Theme } from '@material-ui/core';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

export interface ColorFieldStylesProps {
  paletteColor: PaletteColor;
}

export const getColorFieldStyles = makeStyles<Theme, ColorFieldStylesProps>(
  (theme) => ({
    button: {
      height: theme.spacing(4),
      width: '90%',
      alignSelf: 'center',
      margin: theme.spacing(1),
      backgroundColor: ({ paletteColor }): string => paletteColor.main,
      '&:hover': {
        backgroundColor: ({ paletteColor }): string => paletteColor.light,
      },
    },
  }),
);
