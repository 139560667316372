import { Button, CircularProgress, Grid, useTheme } from '@material-ui/core';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ProposalLayoutAnyData } from '@easysolar/proposals/modules/Common/models/LayoutData/Layout';
import { ProposalLayoutVersion } from '@easysolar/proposals/modules/Common/models/ProposalLayoutVersion';
import { useFormGroup } from '@easysolar/proposals/shared/forms/hooks/useFormGroup';
import { FormGroup } from '@easysolar/proposals/shared/forms/models';
import { useDownloadFile } from '@easysolar/proposals/shared/hooks/useDownloadFile';

import { useLastValidFormData } from '../../../hooks/useLastValidFormData';
import { useCreateProposalSharedMutation } from '../../../services/proposalsAPI';
import { selectProjectId } from '../../../store/webComponentProps';
import { getProposalEditorToolbarGenerationSectionStyles } from './GenerationSection.styles';
import { GetLinkPopper } from './GetLinkPopper';

interface ProposalEditorToolbarGenerationSectionProps {
  layoutVersion: ProposalLayoutVersion;
  proposalForm: FormGroup<ProposalLayoutAnyData>;
  selectedTemplateId: string | null;
  disabled: boolean;
}

enum ProposalGenerationHandling {
  GetLink = 'getLink',
  DownloadAsPDF = 'downloadAsPDF',
}

export const ProposalEditorToolbarGenerationSection: FunctionComponent<ProposalEditorToolbarGenerationSectionProps> =
  ({ layoutVersion, proposalForm, selectedTemplateId, disabled }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const classes = getProposalEditorToolbarGenerationSectionStyles();

    const projectId = useSelector(selectProjectId);
    const lastValidFormData = useLastValidFormData(proposalForm);

    const [proposalGenerationHandling, setProposalGenerationHandling] =
      useState<ProposalGenerationHandling | null>(null);
    const [popperOpen, setPopperOpen] = useState(false);

    const [createProposal, { data: proposal, isLoading: isCreatingProposal }] =
      useCreateProposalSharedMutation();

    const generateProposal = useCallback(() => {
      if (projectId) {
        createProposal({
          projectId,
          layoutName: layoutVersion.name,
          layoutVersion: layoutVersion.version,
          proposalTemplateId:
            selectedTemplateId === null ? undefined : selectedTemplateId,
          layoutData: lastValidFormData,
        });
      }
    }, [
      createProposal,
      lastValidFormData,
      layoutVersion.name,
      layoutVersion.version,
      projectId,
      selectedTemplateId,
    ]);

    const onGetLinkButtonClick = useCallback(() => {
      setPopperOpen(false);
      setProposalGenerationHandling(ProposalGenerationHandling.GetLink);

      generateProposal();
    }, [generateProposal]);

    const onDownloadButtonClick = useCallback(() => {
      setPopperOpen(false);
      setProposalGenerationHandling(ProposalGenerationHandling.DownloadAsPDF);

      generateProposal();
    }, [generateProposal]);

    const loaderSize = useMemo(
      () =>
        theme.typography.fontSize *
        (theme.typography.button.lineHeight as number),
      [theme.typography.button.lineHeight, theme.typography.fontSize],
    );

    const {
      downloadFile: downloadPDF,
      loading: downloadingFile,
      progress,
    } = useDownloadFile();

    const loaderValue = useMemo(
      () => (typeof progress === 'number' ? progress * 100 : undefined),
      [progress],
    );

    const loader = useMemo(
      () => (
        <CircularProgress
          className={classes.loader}
          size={loaderSize}
          variant={
            typeof loaderValue === 'number' ? 'determinate' : 'indeterminate'
          }
          value={loaderValue}
        />
      ),
      [classes.loader, loaderSize, loaderValue],
    );

    useEffect(() => {
      if (proposal) {
        switch (proposalGenerationHandling) {
          case ProposalGenerationHandling.GetLink: {
            setPopperOpen(true);

            break;
          }

          case ProposalGenerationHandling.DownloadAsPDF: {
            downloadPDF(proposal.pdfFile);

            break;
          }

          case null: {
            break;
          }
        }

        setProposalGenerationHandling(null);
      }
    }, [downloadPDF, proposal, proposalGenerationHandling]);

    const getLinkButtonRef = useRef(null);
    const closePopper = useCallback(() => setPopperOpen(false), []);

    const getLinkPopper = useMemo(
      () =>
        proposal && (
          <GetLinkPopper
            anchorEl={getLinkButtonRef.current}
            open={popperOpen}
            link={proposal.pdfFile}
            onClose={closePopper}
          />
        ),
      [closePopper, popperOpen, proposal],
    );

    const { valid } = useFormGroup(proposalForm);

    const generationDisabled = useMemo(
      () => disabled || !valid || isCreatingProposal || downloadingFile,
      [disabled, downloadingFile, isCreatingProposal, valid],
    );

    const loading = useMemo(
      () => isCreatingProposal || downloadingFile,
      [downloadingFile, isCreatingProposal],
    );

    return (
      <Grid
        container
        className={classes.grid}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          ref={getLinkButtonRef}
          variant="contained"
          color="primary"
          disabled={generationDisabled}
          onClick={onGetLinkButtonClick}
        >
          {t('Editor:Toolbar.GetLinkButton.Label')}
          {loading && loader}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={generationDisabled}
          onClick={onDownloadButtonClick}
        >
          {t('Editor:Toolbar.DownloadAsPDFButton.Label')}
          {loading && loader}
        </Button>
        {getLinkPopper}
      </Grid>
    );
  };
