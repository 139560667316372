import {
  createEntityAdapter,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';

import { PROPOSAL_PREVIEW_NEWEST_LAYOUTS_VERSIONS } from '../../ProposalPreview/api';
import { ProposalTemplate } from '../models/ProposalTemplate';
import { proposalTemplatesAPI } from '../services/proposalTemplatesAPI';
import type { RootState } from './root';

export const proposalTemplatesAdapter = createEntityAdapter<ProposalTemplate>();

const initialState = proposalTemplatesAdapter.getInitialState();

type State = typeof initialState;

export const proposalTemplatesSlice = createSlice({
  name: 'proposalTemplates',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addMatcher(
        proposalTemplatesAPI.endpoints.getProposalTemplates.matchFulfilled,
        (state, action) => {
          const templates = action.payload;

          // API can return templates of a layout version that is not yet supported.
          // Do not store such templates.
          const supportedTemplates = templates.filter((template) => {
            const newestVersionSupportedByPreview =
              PROPOSAL_PREVIEW_NEWEST_LAYOUTS_VERSIONS[template.layoutName];

            return (
              newestVersionSupportedByPreview !== undefined &&
              template.layoutVersion <= newestVersionSupportedByPreview
            );
          });

          proposalTemplatesAdapter.upsertMany(state, supportedTemplates);
        },
      )
      .addMatcher(
        isAnyOf(
          proposalTemplatesAPI.endpoints.createProposalTemplate.matchFulfilled,
          proposalTemplatesAPI.endpoints.updateProposalTemplate.matchFulfilled,
        ),
        (state, action) => {
          const template = action.payload;

          proposalTemplatesAdapter.upsertOne(state, template);
        },
      )
      .addMatcher(
        proposalTemplatesAPI.endpoints.deleteProposalTemplate.matchFulfilled,
        (state, action) => {
          const templateId = action.payload;

          proposalTemplatesAdapter.removeOne(state, templateId);
        },
      ),
});

const selectProposalTemplatesSlice = (state: RootState): State =>
  state.proposalTemplates;

export const {
  selectAll: selectProposalTemplates,
  selectEntities: selectProposalTemplatesById,
} = proposalTemplatesAdapter.getSelectors(selectProposalTemplatesSlice);

export const selectGetProposalLayoutTemplates = createSelector(
  [selectProposalTemplates, (_state, layoutName: string): string => layoutName],
  (templates, layoutName) =>
    templates.filter((template) => template.layoutName === layoutName),
);
