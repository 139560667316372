import { Grid, Paper, Typography } from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import {
  DragEventHandler,
  FunctionComponent,
  useCallback,
  useMemo,
} from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import { SwitchField } from '@easysolar/proposals/shared/components/SwitchField';
import { useFormControl } from '@easysolar/proposals/shared/forms/hooks/useFormControl';
import { FormControl } from '@easysolar/proposals/shared/forms/models';

import { getProposalEditorSideBarSectionsListItemStyles } from './Item.styles';

interface ProposalEditorSideBarSectionsListItemProps {
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isDragging: boolean;
  sectionTitle: string;
  enabledFormControl: FormControl<boolean>;
  disabled?: boolean;
}

export const ProposalEditorSideBarSectionsListItem: FunctionComponent<ProposalEditorSideBarSectionsListItemProps> =
  ({
    dragHandleProps,
    isDragging,
    sectionTitle,
    enabledFormControl,
    disabled = false,
  }) => {
    const classes = getProposalEditorSideBarSectionsListItemStyles({
      isDragging,
      disabled,
    });

    const dragHandle = useMemo(
      () => (
        <Grid
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dragHandleProps}
          container
          item
          justifyContent="center"
          alignItems="center"
          className={classes.dragHandle}
          onDragStart={dragHandleProps?.onDragStart as DragEventHandler}
        >
          <DragHandleIcon />
        </Grid>
      ),
      [classes.dragHandle, dragHandleProps],
    );

    const title = useMemo(
      () => (
        <Typography component="div" variant="body2" className={classes.title}>
          {sectionTitle}
        </Typography>
      ),
      [classes.title, sectionTitle],
    );

    const { value: enabled, setValue: setEnabledValue } =
      useFormControl(enabledFormControl);

    const onEnabledChange = useCallback(
      (newValue: boolean) => {
        setEnabledValue(newValue, { triggeredByUser: true });
      },
      [setEnabledValue],
    );

    const switchField = useMemo(
      () => (
        <SwitchField
          value={enabled || false}
          onChange={onEnabledChange}
          size="small"
          disabled={disabled}
        />
      ),
      [enabled, onEnabledChange, disabled],
    );

    const paper = useMemo(
      () => (
        <Paper className={classes.paper}>
          <Grid container direction="row" alignItems="center">
            {dragHandle}
            {title}
            {switchField}
          </Grid>
        </Paper>
      ),
      [classes.paper, switchField, dragHandle, title],
    );

    return paper;
  };
