import { useEffect, useRef } from 'react';

interface FontDefinition {
  family: string;
  weights: number[];
}

export const useLoadGoogleFonts = (fontDefinitions: FontDefinition[]): void => {
  const loadedFonts = useRef(new Set<string>());

  useEffect(() => {
    fontDefinitions.forEach((fontDefinition) => {
      const family = fontDefinition.family.split(' ').join('+');
      const weights = fontDefinition.weights.join(';');
      const href = `https://fonts.googleapis.com/css2?family=${family}:wght@${weights}&display=swap`;

      if (!loadedFonts.current.has(href)) {
        const linkElement = document.createElement('link');
        linkElement.href = href;
        linkElement.rel = 'stylesheet';

        document.head.appendChild(linkElement);

        loadedFonts.current.add(href);
      }
    });
  }, [fontDefinitions]);
};
