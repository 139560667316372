import { GetProposalLayoutParams } from '../models/GetProposalLayout';
import { ProposalLayout } from '../models/ProposalLayout';
import { easysolarAPI } from './easysolarAPI';

export const proposalLayoutAPI = easysolarAPI.injectEndpoints({
  endpoints: (builder) => ({
    getProposalLayout: builder.query<ProposalLayout, GetProposalLayoutParams>({
      query: ({ layoutName }: GetProposalLayoutParams) =>
        `/proposal-layouts/${layoutName}`,
    }),
  }),
});

export const { useGetProposalLayoutQuery } = proposalLayoutAPI;
