import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from 'react';

interface ProposalPreviewDefaultTableStylesProps {
  showHeader: boolean;
}

export const getProposalPreviewDefaultTableStyles = makeStyles<
  Theme,
  ProposalPreviewDefaultTableStylesProps
>((theme) => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  cell: {
    borderWidth: '0.5mm',
    padding: '1.5mm',
    textAlign: 'center',
  },
  header: {
    visibility: ({ showHeader }): CSSProperties['visibility'] =>
      showHeader ? 'visible' : 'collapse',
  },
  headerCell: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  headerCellText: {
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.fontWeightRegular,
  },
  bodyRow: {
    '&:last-child $bodyCell': {
      borderBottomStyle: 'none',
    },
    '& $bodyCell:last-child': {
      borderRightStyle: 'none',
    },
  },
  bodyCell: {
    borderTopStyle: 'none',
    borderLeftStyle: 'none',
    borderColor: theme.palette.background.default,
    borderStyle: 'dotted',
  },
  bodyCellText: {
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1,
  },
}));
