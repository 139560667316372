import { makeStyles } from '@material-ui/core';
import { CSSProperties } from 'react';

export const getProposalPreviewDefaultCustomerProposalCaptionStyles =
  makeStyles((theme) => ({
    root: {
      fontSize: (): CSSProperties['fontSize'] => {
        const captionFontSize = theme.typography.caption.fontSize;

        if (typeof captionFontSize === 'number') {
          return captionFontSize * 0.8;
        }

        return captionFontSize;
      },
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1,
    },
  }));
