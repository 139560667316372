import { Grid } from '@material-ui/core';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';
import {
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName,
} from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema';

import { ProposalPreviewSection } from '../../../../../Section';
import { ProposalPreviewDefaultContactSubsection } from '../../../Shared/ContactSubsection';
import { ProposalPreviewDefaultSectionTitle } from '../../../Shared/SectionTitle';
import { ProposalPreviewDefaultCoverPageClientDetails } from '../ClientDetails';
import { getProposalPreviewDefaultCoverPageContentStyles } from './Content.styles';

export const ProposalPreviewDefaultCoverPageContent: ProposalPreviewSection<
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName.CoverPage
> = ({ sectionSettings }) => {
  const classes = getProposalPreviewDefaultCoverPageContentStyles();

  const { client, project } = useProposalContext();

  const { t } = useTranslation();

  const { contact: contactSettings } = sectionSettings;

  const title = useMemo(
    () => (
      <Grid item className={classes.titleContainer}>
        <ProposalPreviewDefaultSectionTitle
          title={sectionSettings.title}
          subtitle={sectionSettings.subtitle}
          variant="h1"
          titleMaxWidth="80%"
        />
      </Grid>
    ),
    [classes.titleContainer, sectionSettings.subtitle, sectionSettings.title],
  );

  const clientName = useMemo(
    () => (
      <ProposalPreviewDefaultCoverPageClientDetails
        Icon={PersonOutlineOutlinedIcon}
        label={t('Layouts:Default.Sections.CoverPage.ClientNameLabel')}
        value={client.name}
      />
    ),
    [client.name, t],
  );

  const projectAddress = useMemo(
    () => (
      <ProposalPreviewDefaultCoverPageClientDetails
        Icon={LocationOnOutlinedIcon}
        label={t('Layouts:Default.Sections.CoverPage.ProjectAddressLabel')}
        value={project.address}
      />
    ),
    [project.address, t],
  );

  const companySubsection = useMemo(
    () => (
      <ProposalPreviewDefaultContactSubsection
        title={contactSettings.title}
        showCompanyName={contactSettings.showCompanyName}
        showEmployeeName={contactSettings.showEmployeeName}
        showEmployeeEmailAddress={contactSettings.showEmployeeEmailAddress}
        showEmployeePhoneNumber={contactSettings.showEmployeePhoneNumber}
      />
    ),
    [
      contactSettings.showCompanyName,
      contactSettings.showEmployeeEmailAddress,
      contactSettings.showEmployeeName,
      contactSettings.showEmployeePhoneNumber,
      contactSettings.title,
    ],
  );

  const content = useMemo(
    () => (
      <>
        {title}
        {clientName}
        {projectAddress}
        {companySubsection}
      </>
    ),
    [clientName, companySubsection, projectAddress, title],
  );

  return content;
};
