import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName,
} from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema';

import { ProposalPreviewSection } from '../../../../Section';
import { ProposalPreviewDefaultContactSubsection } from '../../Shared/ContactSubsection';
import { ProposalPreviewDefaultSectionPage } from '../../Shared/SectionPage';
import { ProposalPreviewDefaultSubsection } from '../../Shared/Subsection';
import { ProposalPreviewDefaultCashFlowChart } from './Chart';
import { ProposalPreviewDefaultCashFlowTable } from './Table';

export const ProposalPreviewDefaultCashFlow: ProposalPreviewSection<
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName.CashFlow
> = ({ generalSettings, sectionSettings }) => {
  const { t } = useTranslation();

  const { contact: contactSettings } = sectionSettings;

  const tableSubsection = useMemo(
    () => (
      <ProposalPreviewDefaultSubsection
        title={t('Layouts:Default.Sections.CashFlow.Subsections.Table.Title')}
      >
        <ProposalPreviewDefaultCashFlowTable
          tableSettings={sectionSettings.table}
        />
      </ProposalPreviewDefaultSubsection>
    ),
    [sectionSettings.table, t],
  );

  const tablePage = useMemo(
    () => (
      <ProposalPreviewDefaultSectionPage
        generalSettings={generalSettings}
        title={sectionSettings.title}
        subtitle={sectionSettings.subtitle}
      >
        {tableSubsection}
      </ProposalPreviewDefaultSectionPage>
    ),
    [
      generalSettings,
      sectionSettings.subtitle,
      sectionSettings.title,
      tableSubsection,
    ],
  );

  const chartSubsection = useMemo(
    () => (
      <ProposalPreviewDefaultSubsection
        title={t(
          'Layouts:Default.Sections.CashFlow.Subsections.Visualization.Title',
        )}
      >
        <ProposalPreviewDefaultCashFlowChart />
      </ProposalPreviewDefaultSubsection>
    ),
    [t],
  );

  const contactSubsection = useMemo(
    () => (
      <ProposalPreviewDefaultContactSubsection
        title={contactSettings.title}
        showCompanyName={contactSettings.showCompanyName}
        showEmployeeName={contactSettings.showEmployeeName}
        showEmployeeEmailAddress={contactSettings.showEmployeeEmailAddress}
        showEmployeePhoneNumber={contactSettings.showEmployeePhoneNumber}
      />
    ),
    [
      contactSettings.showCompanyName,
      contactSettings.showEmployeeEmailAddress,
      contactSettings.showEmployeeName,
      contactSettings.showEmployeePhoneNumber,
      contactSettings.title,
    ],
  );

  const chartPage = useMemo(
    () => (
      <ProposalPreviewDefaultSectionPage
        generalSettings={generalSettings}
        title={sectionSettings.title}
        subtitle={sectionSettings.subtitle}
      >
        {chartSubsection}
        {contactSubsection}
      </ProposalPreviewDefaultSectionPage>
    ),
    [
      chartSubsection,
      contactSubsection,
      generalSettings,
      sectionSettings.subtitle,
      sectionSettings.title,
    ],
  );

  return (
    <>
      {tablePage}
      {chartPage}
    </>
  );
};
