import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ConfigurationService } from '@easysolar/proposals/infrastructure/services/ConfigurationService';

import type { RootState } from '../store/root';
import {
  selectApiBaseUrl,
  selectAuthToken,
  selectLanguage,
} from '../store/webComponentProps';

export const easysolarAPI = createApi({
  reducerPath: 'easysolarAPI',
  baseQuery: (args, api, extraOptions) => {
    const { getState } = api;
    const state = getState() as RootState;
    const apiBaseUrl: string =
      selectApiBaseUrl(state) || ConfigurationService.APP_CONFIG.apiUrl;
    const authToken: string | null = selectAuthToken(state);
    const language = selectLanguage(state);

    return fetchBaseQuery({
      baseUrl: apiBaseUrl,
      prepareHeaders: (headers) => {
        headers.set('Authorization', `Bearer ${authToken}`);
        headers.set('Accept-Language', language);

        return headers;
      },
    })(args, api, extraOptions);
  },
  endpoints: () => ({}),
});
