import { Grid } from '@material-ui/core';
import { FunctionComponent } from 'react';

import {
  isProposalSettingOrGroupOfSettingsSchema,
  ProposalLayoutGroupOfSettingsSchema,
} from '@easysolar/proposals/modules/Common/models/LayoutSchema/GroupOfSettings';
import {
  FormControl,
  FormGroup,
} from '@easysolar/proposals/shared/forms/models';

import { ProposalEditorGroupOfSettings } from '../GroupOfSettings';
import { ProposalEditorSetting } from '../Setting';

export interface ProposalEditorSettingsProps {
  formGroup: FormGroup;
  groupOfSettingsSchema: ProposalLayoutGroupOfSettingsSchema;
}

export const ProposalEditorSettings: FunctionComponent<ProposalEditorSettingsProps> =
  ({ formGroup, groupOfSettingsSchema }) => {
    return (
      <Grid container direction="column">
        {groupOfSettingsSchema.settings.map((settingName) => {
          const {
            isGroup,
            groupOfSettingsSchema: childGroupOfSettingsSchema,
            settingSchema,
          } = isProposalSettingOrGroupOfSettingsSchema(
            groupOfSettingsSchema.properties[settingName],
          );

          const control = formGroup.controls[settingName];

          if (isGroup) {
            return (
              <ProposalEditorGroupOfSettings
                key={settingName}
                formGroup={control as FormGroup}
                groupOfSettingsSchema={childGroupOfSettingsSchema}
              />
            );
          }

          return (
            <ProposalEditorSetting
              key={settingName}
              formControl={control as FormControl}
              settingSchema={settingSchema}
            />
          );
        })}
      </Grid>
    );
  };
