import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from 'react';

export interface ProposalPreviewPageStylesProps {
  printing: boolean;
  backgroundImageURL?: string;
  margins: [number, number, number, number];
}

export const A4_PAPER_SIZE_WIDTH_IN_MILLIMETRES = 210;

export const getProposalPreviewPageStyles = makeStyles<
  Theme,
  ProposalPreviewPageStylesProps
>(() => ({
  paper: {
    margin: ({ printing }): CSSProperties['margin'] =>
      !printing ? `5mm auto` : 'unset',
    width: `${A4_PAPER_SIZE_WIDTH_IN_MILLIMETRES}mm`,
    // Chrome A4 page seems to be a little bit smaller than the standard 297mm
    height: '296.8mm',
    breakAfter: 'page',
    pageBreakAfter: 'always',
    pageBreakBefore: 'always',
    transformOrigin: 'left top',
  },
  background: {
    width: '100%',
    height: '100%',
    backgroundImage: ({ backgroundImageURL }): string =>
      backgroundImageURL ? `url(${backgroundImageURL})` : 'none',
    backgroundOrigin: 'padding-box',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  container: {
    width: '100%',
    height: '100%',
    padding: ({ margins }): CSSProperties['margin'] =>
      margins.map((margin) => `${margin}mm`).join(' '),
    boxSizing: 'border-box',
  },
  '@media print': {
    paper: {
      borderRadius: 'unset',
      boxShadow: 'unset',
    },
  },
}));
