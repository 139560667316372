import { useMemo } from 'react';

import { FieldWrapperProps } from '../ControlledField';
import { RadioGroupField, RadioGroupFieldProps } from '../RadioGroupField';

export interface RadioGroupFieldWrapperProps<T extends string>
  extends FieldWrapperProps<T>,
    RadioGroupFieldProps<T> {}

export const RadioGroupFieldWrapper = <T extends string>({
  label,
  choices,
  value,
  onChange,
}: RadioGroupFieldWrapperProps<T>): JSX.Element => {
  const field = useMemo(
    () => (
      <RadioGroupField
        label={label}
        choices={choices}
        value={value}
        onChange={onChange}
      />
    ),
    [choices, label, onChange, value],
  );

  return field;
};
