import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { ChangeEvent, useCallback, useMemo } from 'react';

export interface RadioGroupChoice<T extends string> {
  value: T;
  label: string;
}

export interface RadioGroupFieldProps<T extends string> {
  label: string;
  // eslint-disable-next-line react/require-default-props
  ariaLabel?: string;
  choices: RadioGroupChoice<T>[];
  value: T;
  onChange: (newValue: T) => void;
}

export const RadioGroupField = <T extends string>({
  label,
  ariaLabel,
  choices,
  value,
  onChange,
}: RadioGroupFieldProps<T>): JSX.Element => {
  const formLabel = useMemo(
    () => <FormLabel component="legend">{label}</FormLabel>,
    [label],
  );

  const control = useMemo(() => <Radio />, []);

  const formControlLabels = useMemo(
    () =>
      choices.map(({ value: choiceValue, label: choiceLabel }) => (
        <FormControlLabel
          key={choiceValue}
          value={choiceValue}
          control={control}
          label={choiceLabel}
        />
      )),
    [choices, control],
  );

  const onRadioGroupChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: string) =>
      onChange(newValue as T),
    [onChange],
  );

  const radioGroup = useMemo(
    () => (
      <RadioGroup
        name={label}
        aria-label={ariaLabel || label}
        value={value}
        onChange={onRadioGroupChange}
      >
        {formControlLabels}
      </RadioGroup>
    ),
    [ariaLabel, formControlLabels, label, onRadioGroupChange, value],
  );

  const formControl = useMemo(
    () => (
      <FormControl component="fieldset" margin="normal">
        {formLabel}
        {radioGroup}
      </FormControl>
    ),
    [formLabel, radioGroup],
  );

  return formControl;
};
