import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from 'react';

interface ProposalPreviewDefaultProposedSystemElectricityBillFactStylesProps {
  first: boolean;
}

export const getProposalPreviewDefaultProposedSystemElectricityBillFactStyles =
  makeStyles<
    Theme,
    ProposalPreviewDefaultProposedSystemElectricityBillFactStylesProps
  >((theme) => ({
    icon: {
      fontSize: '24pt',
    },
    fact: {
      paddingTop: ({ first }): CSSProperties['paddingTop'] =>
        first ? undefined : '6mm',
    },
    factValue: {
      whiteSpace: 'nowrap',
      fontWeight: theme.typography.fontWeightBold,
    },
  }));
