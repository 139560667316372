import { mapValues } from 'lodash';

import { PROPOSAL_PREVIEW_COMPONENTS } from './components/Preview/Preview';
import { ProposalPreviewWrapperProps } from './components/PreviewWrapper';

export const PROPOSAL_PREVIEW_WEB_COMPONENT_TAG_NAME =
  'easysolar-proposal-preview';

export type ProposalPreviewWebComponentProps = ProposalPreviewWrapperProps;

export const PROPOSAL_PREVIEW_SUPPORTED_LAYOUTS_VERSIONS: Record<
  string,
  number[]
> = mapValues(PROPOSAL_PREVIEW_COMPONENTS, (previewComponentVersions) =>
  Object.keys(previewComponentVersions).map((version) => +version),
);

export const PROPOSAL_PREVIEW_NEWEST_LAYOUTS_VERSIONS: Record<string, number> =
  mapValues(PROPOSAL_PREVIEW_SUPPORTED_LAYOUTS_VERSIONS, (versions) =>
    Math.max(...versions),
  );
