import { Box, Grid, useTheme } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';

import { ProposalPreviewDefaultSubsection } from '../../../Shared/Subsection';
import { ProposalPreviewDefaultInvestmentCostsElectricityBillCircle } from './Circle';
import { getProposalPreviewDefaultInvestmentCostsElectricityBillStyles } from './ElectricityBill.styles';
import { ProposalPreviewDefaultInvestmentCostsElectricityBillTable } from './Table';

interface ProposalPreviewDefaultInvestmentCostsElectricityBillProps {
  title: string;
  showUpfrontCostsAfterIncentives: boolean;
  showAverageAnnualSavings: boolean;
  showSavings: boolean;
  showSubsidies: boolean;
}

export const ProposalPreviewDefaultInvestmentCostsElectricityBill: FunctionComponent<ProposalPreviewDefaultInvestmentCostsElectricityBillProps> =
  ({
    title,
    showUpfrontCostsAfterIncentives,
    showAverageAnnualSavings,
    showSavings,
    showSubsidies,
  }) => {
    const theme = useTheme();

    const showCircles = useMemo(
      () =>
        [
          showUpfrontCostsAfterIncentives,
          showAverageAnnualSavings,
          showSavings,
        ].some((value) => !!value),
      [showAverageAnnualSavings, showSavings, showUpfrontCostsAfterIncentives],
    );

    const classes =
      getProposalPreviewDefaultInvestmentCostsElectricityBillStyles({
        showCircles,
      });

    const {
      project: {
        analysisPeriod,
        financialAnalysis: {
          forecast: {
            upfrontCostsAfterIncentives,
            savings,
            averageAnnualSavings,
          },
        },
      },
    } = useProposalContext();

    const upfrontCostsAfterIncentivesCircle = useMemo(
      () => (
        <ProposalPreviewDefaultInvestmentCostsElectricityBillCircle
          color={theme.palette.text.primary}
          value={upfrontCostsAfterIncentives}
          i18nKey="Layouts:Default.Sections.InvestmentCosts.Subsections.ElectricityBill.InitialCostsLabel"
        />
      ),
      [theme.palette.text.primary, upfrontCostsAfterIncentives],
    );

    const upfrontCostsAfterIncentivesElement = useMemo(
      () =>
        showUpfrontCostsAfterIncentives && upfrontCostsAfterIncentivesCircle,
      [showUpfrontCostsAfterIncentives, upfrontCostsAfterIncentivesCircle],
    );

    const averageAnnualSavingsCircle = useMemo(
      () => (
        <ProposalPreviewDefaultInvestmentCostsElectricityBillCircle
          color={theme.palette.secondary.main}
          value={averageAnnualSavings}
          i18nKey="Layouts:Default.Sections.InvestmentCosts.Subsections.ElectricityBill.AverageAnnualSavingsLabel"
        />
      ),
      [averageAnnualSavings, theme.palette.secondary.main],
    );

    const averageAnnualSavingsElement = useMemo(
      () => showAverageAnnualSavings && averageAnnualSavingsCircle,
      [averageAnnualSavingsCircle, showAverageAnnualSavings],
    );

    const savingsCircle = useMemo(
      () => (
        <ProposalPreviewDefaultInvestmentCostsElectricityBillCircle
          color={theme.palette.primary.main}
          value={savings}
          i18nKey="Layouts:Default.Sections.InvestmentCosts.Subsections.ElectricityBill.SavingsLabel"
          i18nCount={analysisPeriod}
        />
      ),
      [analysisPeriod, savings, theme.palette.primary.main],
    );

    const savingsElements = useMemo(
      () => showSavings && savingsCircle,
      [savingsCircle, showSavings],
    );

    const circlesElement = useMemo(
      () =>
        showCircles && (
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            wrap="nowrap"
          >
            {upfrontCostsAfterIncentivesElement}
            {averageAnnualSavingsElement}
            {savingsElements}
          </Grid>
        ),
      [
        averageAnnualSavingsElement,
        savingsElements,
        showCircles,
        upfrontCostsAfterIncentivesElement,
      ],
    );

    const table = useMemo(
      () => (
        <Box className={classes.table}>
          <ProposalPreviewDefaultInvestmentCostsElectricityBillTable />
        </Box>
      ),
      [classes.table],
    );

    const tableElement = useMemo(
      () => showSubsidies && table,
      [showSubsidies, table],
    );

    const subsection = useMemo(
      () => (
        <ProposalPreviewDefaultSubsection title={title}>
          {circlesElement}
          {tableElement}
        </ProposalPreviewDefaultSubsection>
      ),
      [circlesElement, tableElement, title],
    );

    return subsection;
  };
