import { Tooltip, TooltipProps } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { ShadowRootPopper } from './Popper';

export const ShadowRootTooltip: FunctionComponent<TooltipProps> = ({
  children,
  ...props
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip PopperComponent={ShadowRootPopper} {...props}>
      {children}
    </Tooltip>
  );
};
