import { Grid, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { ProposalPreviewDefaultCustomerProposalCaption } from '../Caption';
import { getProposalPreviewDefaultCustomerProposalCommentStyles } from './Comment.styles';

export interface ProposalPreviewDefaultCustomerProposalCommentProps {
  label: string;
  comment: string;
}

export const ProposalPreviewDefaultCustomerProposalComment: FunctionComponent<ProposalPreviewDefaultCustomerProposalCommentProps> =
  ({ label, comment }) => {
    const classes = getProposalPreviewDefaultCustomerProposalCommentStyles();

    return (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignContent="flex-start"
      >
        <ProposalPreviewDefaultCustomerProposalCaption caption={label} />
        <Typography
          variant="caption"
          component="div"
          className={classes.comment}
        >
          {comment}
        </Typography>
      </Grid>
    );
  };
