import ReorderIcon from '@material-ui/icons/Reorder';
import { FunctionComponent, useCallback, useMemo } from 'react';
import {
  DragDropContext,
  DragDropContextProps,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { useShadowRootContainer } from '@easysolar/proposals/infrastructure/hooks/useShadowRootContainer';
import { ProposalLayoutArrayOfSectionsData } from '@easysolar/proposals/modules/Common/models/LayoutData/Layout';
import { ProposalLayoutSectionSchema } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Section';
import { FormArray } from '@easysolar/proposals/shared/forms/models';

import { ProposalEditorSideBarAccordion } from '../Accordion';
import { ProposalEditorSideBarSectionsListDroppableContainer } from './DroppableContainer';

interface ProposalEditorSideBarSectionsListProps {
  sectionsForm: FormArray<ProposalLayoutArrayOfSectionsData>;
  sectionsSchemasByName: Record<string, ProposalLayoutSectionSchema>;
  disabled: boolean;
}

export const ProposalEditorSideBarSectionsList: FunctionComponent<ProposalEditorSideBarSectionsListProps> =
  ({ sectionsForm, sectionsSchemasByName, disabled }) => {
    const { t } = useTranslation();

    const shadowRootContainerRef = useShadowRootContainer();

    const onDragEnd = useCallback(
      ({ source, destination }: DropResult) => {
        if (destination && source.index !== destination.index) {
          sectionsForm.reorder(source.index, destination.index, {
            triggeredByUser: true,
          });
        }
      },
      [sectionsForm],
    );

    const dragDropContextProps = useMemo<
      Omit<DragDropContextProps & { stylesInsertionPoint?: Node }, 'children'>
    >(
      () => ({
        onDragEnd,
        stylesInsertionPoint: shadowRootContainerRef?.getRootNode(),
      }),
      [onDragEnd, shadowRootContainerRef],
    );

    const content = useMemo(
      () => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <DragDropContext {...dragDropContextProps}>
          <ProposalEditorSideBarSectionsListDroppableContainer
            sectionsForm={sectionsForm}
            sectionsSchemasByName={sectionsSchemasByName}
            disabled={disabled}
          />
        </DragDropContext>
      ),
      [disabled, dragDropContextProps, sectionsForm, sectionsSchemasByName],
    );

    const accordion = useMemo(
      () => (
        <ProposalEditorSideBarAccordion
          title={t('Editor:SectionsList.Title')}
          icon={<ReorderIcon />}
          disabled={disabled}
        >
          {content}
        </ProposalEditorSideBarAccordion>
      ),
      [content, disabled, t],
    );

    return accordion;
  };
