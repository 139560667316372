import { makeStyles, Theme } from '@material-ui/core';
import { clamp } from 'lodash';
import { CSSProperties } from 'react';

interface ProposalPreviewDefaultProgressBarStylesProps {
  progress: number;
  color?: string;
  barHeight?: CSSProperties['height'];
}

export const getProposalPreviewDefaultProgressBarStyles = makeStyles<
  Theme,
  ProposalPreviewDefaultProgressBarStylesProps
>((theme) => ({
  labels: {
    paddingBottom: '3mm',
  },
  background: {
    width: '100%',
    height: ({ barHeight = '4mm' }): CSSProperties['height'] => barHeight,
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
    border: '0px solid',
    borderRadius: '5px',
  },
  progress: {
    width: ({ progress }): string => `${clamp(progress, 0, 1) * 100}%`,
    height: 'inherit',
    minHeight: 'inherit',
    backgroundColor: ({ color }): string => color || theme.palette.primary.main,
  },
}));
