import { SvgIcon } from '@material-ui/core';
import { ReactElement, useCallback } from 'react';

import { ProposalEditorToolbarTemplatesSectionNameDialog } from './NameDialog';
import {
  useProposalTemplateAction,
  UseProposalTemplateActionProps,
} from './useProposalTemplateAction';

interface UseProposalTemplateNameActionProps {
  button: {
    tooltip: string;
    IconComponent: typeof SvgIcon;
  };
  dialog: {
    title: string;
    confirmButtonLabel: string;
  };
  disabled: boolean;
  loading: boolean;
  initialTemplateName: string;
  onConfirm: (templateName: string) => void;
}

interface UseProposalTemplateNameActionResult {
  button: ReactElement;
}

export function useProposalTemplateNameAction({
  initialTemplateName,
  onConfirm,
  ...props
}: UseProposalTemplateNameActionProps): UseProposalTemplateNameActionResult {
  const renderDialog: UseProposalTemplateActionProps<string>['dialog']['render'] =
    useCallback(
      (dialogProps) => (
        <ProposalEditorToolbarTemplatesSectionNameDialog
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dialogProps}
          initialTemplateName={initialTemplateName}
        />
      ),
      [initialTemplateName],
    );

  const onDialogConfirm = useCallback(
    (result?: string) => {
      if (result) {
        onConfirm(result);
      }
    },
    [onConfirm],
  );

  return useProposalTemplateAction({
    ...props,
    dialog: {
      ...props.dialog,
      render: renderDialog,
    },
    onConfirm: onDialogConfirm,
  });
}
