import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultEnergyProductionIrradiationStyles =
  makeStyles(() => ({
    root: {
      height: '100%',
      paddingBottom: '12mm',
    },
    content: {
      flex: 1,
    },
    coordinates: {
      paddingBottom: '2mm',
    },
    value: {
      paddingBottom: '2mm',
    },
  }));
