import { RefObject, useEffect, useMemo, useState } from 'react';

export const useResizeObserver = (
  elementRef: RefObject<HTMLObjectElement>,
): DOMRectReadOnly | undefined => {
  const [contentRect, setContentRect] = useState<DOMRectReadOnly>();

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries: ResizeObserverEntry[]) => {
        const elementEntry = entries.find(
          (entry) => entry.target === elementRef.current,
        );

        if (elementEntry) {
          setContentRect(elementEntry.contentRect);
        }
      }),
    [elementRef],
  );

  useEffect(() => {
    resizeObserver.disconnect();

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return (): void => resizeObserver.disconnect();
  }, [elementRef, resizeObserver]);

  return contentRect;
};
