import { ConfigurationService } from '@easysolar/proposals/infrastructure/services/ConfigurationService';

import { createProposalImageMock } from '../mocks/createProposalImage';
import {
  CreateProposalImageData,
  CreateProposalImageResult,
} from '../models/CreateProposaImage';
import { easysolarAPI } from './easysolarAPI';

export const proposalAPI = easysolarAPI.injectEndpoints({
  endpoints: (builder) => ({
    createProposalImage: builder.mutation<
      CreateProposalImageResult,
      CreateProposalImageData
    >({
      query: ({ file }) => {
        const formData = new FormData();
        formData.append('file', file);

        return {
          url: '/proposal-images',
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: async (response: CreateProposalImageResult, meta) => {
        if (ConfigurationService.APP_CONFIG.useMocks) {
          // MSW is handling files incorrectly currently.
          // Remove this logic and just use the one in mocks/handlers after
          // https://github.com/mswjs/msw/issues/929
          // is resolved
          return createProposalImageMock(meta);
        }

        return response;
      },
    }),
  }),
});

export const { useCreateProposalImageMutation } = proposalAPI;
