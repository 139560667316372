import { i18n as I18NType } from 'i18next';
import React, { useCallback, useRef } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { FillLoader } from '@easysolar/proposals/shared/components/FillLoader';

import {
  InternationalizationService,
  Language,
  Namespace,
} from '../services/InternationalizationService';

export interface WithLanguageProps {
  language?: Language;
}

const withLanguage = <P extends object>(
  Component: React.ComponentType<P>,
): React.ComponentType<P & WithLanguageProps> => {
  const WithLanguage = ({
    language,
    ...props
  }: P & WithLanguageProps): JSX.Element | null => {
    const { i18n, ready } = useTranslation();

    React.useEffect(() => {
      if (ready) {
        i18n.changeLanguage(language);
      }
    }, [i18n, language, ready]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return ready ? <Component {...(props as P)} /> : null;
  };

  WithLanguage.displayName = `WithLanguage(${Component.displayName})`;

  return WithLanguage;
};

export const withI18NInstance = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  namespaces: Namespace[],
  defaultNamespace: Namespace,
): React.ComponentType<P & WithLanguageProps> => {
  const WithLanguage = withLanguage(WrappedComponent);

  const WithI18NInstance = (
    props: P & WithLanguageProps,
  ): JSX.Element | null => {
    const { language } = props;

    const i18nextInstanceRef = useRef<I18NType>();

    const [initialized, setInitialized] = React.useState(false);

    const onInit = useCallback(() => {
      setInitialized(true);
    }, []);

    React.useEffect(() => {
      if (!i18nextInstanceRef.current && language) {
        i18nextInstanceRef.current = InternationalizationService.initialize(
          namespaces,
          {
            defaultNamespace,
            initialLanguage: language,
            onInit,
          },
        );
      }
    }, [language, onInit]);

    const i18nextInstance = i18nextInstanceRef.current;

    return initialized && i18nextInstance ? (
      <I18nextProvider i18n={i18nextInstance}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <WithLanguage {...props} />
      </I18nextProvider>
    ) : (
      <FillLoader />
    );
  };

  WithI18NInstance.displayName = `WithI18NInstance(${WrappedComponent.displayName})`;

  return WithI18NInstance;
};
