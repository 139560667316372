import { FunctionComponent } from 'react';

import { ProposalPreviewPage } from '../../../../Page';
import { ProposalPreviewPageProps } from '../../../../Page/Page';

export type ProposalPreviewDefaultPageProps = ProposalPreviewPageProps;

export const ProposalPreviewDefaultPage: FunctionComponent<ProposalPreviewDefaultPageProps> =
  ({ children, ...props }) => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <ProposalPreviewPage {...props} margins={[15, 20, 15, 40]}>
        {children}
      </ProposalPreviewPage>
    );
  };
