import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ProposalLayoutName } from '../../Common/models/Layouts/Base';
import { ProposalLayoutVersion } from '../../Common/models/ProposalLayoutVersion';
import { PROPOSAL_PREVIEW_SUPPORTED_LAYOUTS_VERSIONS } from '../../ProposalPreview/api';
import { useGetProposalLayoutQuery } from '../services/proposalLayoutsAPI';
import { useGetProposalLayoutVersionQuery } from '../services/proposalLayoutVersionsAPI';
import { selectProjectId } from '../store/webComponentProps';

interface Result {
  layoutVersion?: ProposalLayoutVersion;
  isLoading: boolean;
  isFetching: boolean;
}

export function useNewestSupportedLayoutVersion(
  layoutName: ProposalLayoutName,
): Result {
  const {
    i18n: { language },
  } = useTranslation();

  const projectId = useSelector(selectProjectId);

  const {
    data: layout,
    isLoading: isLoadingLayout,
    isFetching: isFetchingLayout,
  } = useGetProposalLayoutQuery({ layoutName });

  const newestCommonSupportedLayoutVersion: number | null = useMemo(() => {
    if (!layout) {
      return null;
    }

    const apiSupportedVersions = new Set(layout.versions);
    const previewLayoutSupportedVersions =
      PROPOSAL_PREVIEW_SUPPORTED_LAYOUTS_VERSIONS[layoutName] || [];
    const commonSupportedVersions = previewLayoutSupportedVersions.filter(
      (version) => apiSupportedVersions.has(version),
    );

    if (!commonSupportedVersions.length) {
      return null;
    }

    return Math.max(...commonSupportedVersions);
  }, [layout, layoutName]);

  const {
    data: layoutVersion,
    isLoading: isLoadingLayoutVersion,
    isFetching: isFetchingLayoutVersion,
    refetch: refetchProposalLayoutVersion,
  } = useGetProposalLayoutVersionQuery(
    {
      projectId: projectId as string,
      layoutName,
      layoutVersion: newestCommonSupportedLayoutVersion as number,
    },
    {
      skip: !projectId || newestCommonSupportedLayoutVersion === null,
    },
  );

  useEffect(() => {
    refetchProposalLayoutVersion();
  }, [language, refetchProposalLayoutVersion]);

  const isLoading = useMemo(
    () => isLoadingLayout || isLoadingLayoutVersion,
    [isLoadingLayout, isLoadingLayoutVersion],
  );
  const isFetching = useMemo(
    () => isFetchingLayout || isFetchingLayoutVersion,
    [isFetchingLayout, isFetchingLayoutVersion],
  );

  return { layoutVersion, isLoading, isFetching };
}
