import { Grid } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProposalUnitNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalUnitNumberFormat';
import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';
import { ProposalLayoutSettingToggleableText } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Settings/ToggleableText';

import { ProposalPreviewDefaultEnergyProductionCaption } from '../Caption';
import { ProposalPreviewDefaultEnergyProductionCoordinate } from '../Coordinate';
import { CoordinateAxis } from '../Coordinate/Coordinate';
import { getProposalPreviewDefaultEnergyProductionIrradiationStyles } from './Irradiation.styles';

export interface ProposalPreviewDefaultEnergyProductionIrradiationProps {
  source: ProposalLayoutSettingToggleableText;
}

export const ProposalPreviewDefaultEnergyProductionIrradiation: FunctionComponent<ProposalPreviewDefaultEnergyProductionIrradiationProps> =
  ({ source }) => {
    const { t } = useTranslation();

    const classes =
      getProposalPreviewDefaultEnergyProductionIrradiationStyles();

    const { project } = useProposalContext();

    const { latitude, longitude } = project.location;

    const irradiationCaption = useMemo(
      () => (
        <ProposalPreviewDefaultEnergyProductionCaption>
          {t(
            'Layouts:Default.Sections.EnergyProductionAndEnvironmentalImpact.Subsections.EnergyProduction.IrradiationCaption',
          )}
        </ProposalPreviewDefaultEnergyProductionCaption>
      ),
      [t],
    );

    const coordinates = useMemo(
      () => (
        <Grid item className={classes.coordinates}>
          <ProposalPreviewDefaultEnergyProductionCoordinate
            coordinate={latitude}
            axis={CoordinateAxis.NorthSouth}
          />
          <ProposalPreviewDefaultEnergyProductionCoordinate
            coordinate={longitude}
            axis={CoordinateAxis.EastWest}
          />
        </Grid>
      ),
      [classes.coordinates, latitude, longitude],
    );

    const averageAnnualIrradiation = useMemo(
      () => (
        <ProposalUnitNumberFormat
          className={classes.value}
          value={project.averageAnnualIrradiation}
          unit="kWh/m²"
          options={{
            maximumFractionDigits: 0,
          }}
          defaultProps={{
            variant: 'h3',
            color: 'primary',
          }}
          partTypesProps={{
            unit: {
              variant: 'body1',
            },
          }}
        />
      ),
      [classes.value, project.averageAnnualIrradiation],
    );

    const renderedSource = useMemo(
      () =>
        source.enabled && (
          <ProposalPreviewDefaultEnergyProductionCaption>
            {`* ${t(
              'Layouts:Default.Sections.EnergyProductionAndEnvironmentalImpact.Subsections.EnergyProduction.Settings.Source.Label',
            )}: ${source.text}`}
          </ProposalPreviewDefaultEnergyProductionCaption>
        ),
      [source.enabled, source.text, t],
    );

    const content = useMemo(
      () => (
        <Grid
          className={classes.content}
          item
          container
          direction="column"
          justifyContent="center"
        >
          {coordinates}
          {averageAnnualIrradiation}
          {renderedSource}
        </Grid>
      ),
      [averageAnnualIrradiation, classes.content, coordinates, renderedSource],
    );

    const root = useMemo(
      () => (
        <Grid className={classes.root} container direction="column">
          {irradiationCaption}
          {content}
        </Grid>
      ),
      [classes.root, content, irradiationCaption],
    );

    return root;
  };
