import { Grid, SvgIconTypeMap, Typography } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { FunctionComponent, useMemo } from 'react';

import { ProposalPreviewDefaultLabelWithIcon } from '../../../Shared/LabelWithIcon';
import { getProposalPreviewDefaultCoverPageClientDetailsStyles } from './ClientDetails.styles';

export interface ProposalPreviewDefaultCoverPageClientDetailsProps {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  label: string;
  value: string;
}

export const ProposalPreviewDefaultCoverPageClientDetails: FunctionComponent<ProposalPreviewDefaultCoverPageClientDetailsProps> =
  ({ Icon, label, value }) => {
    const classes = getProposalPreviewDefaultCoverPageClientDetailsStyles();

    const renderedLabel = useMemo(
      () => (
        <Grid item container justifyContent="flex-start" alignItems="center">
          <ProposalPreviewDefaultLabelWithIcon Icon={Icon}>
            <Typography className={classes.label} variant="body2">
              {label}:
            </Typography>
          </ProposalPreviewDefaultLabelWithIcon>
        </Grid>
      ),
      [Icon, classes.label, label],
    );

    const renderedValue = useMemo(
      () => (
        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          wrap="nowrap"
        >
          <Typography className={classes.value} variant="body1">
            {value}
          </Typography>
        </Grid>
      ),
      [classes.value, value],
    );

    return (
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        className={classes.root}
      >
        {renderedLabel}
        {renderedValue}
      </Grid>
    );
  };
