import { useCallback, useEffect } from 'react';

import { ProposalLayoutSettingSchemaImage } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Settings/Image';
import { ImageField } from '@easysolar/proposals/shared/components/ImageField';
import { useFormControl } from '@easysolar/proposals/shared/forms/hooks/useFormControl';

import { useCreateProposalImageMutation } from '../../../services/proposalImagesAPI';
import { ProposalEditorSettingBase } from './Base';

export const ProposalEditorSettingImage: ProposalEditorSettingBase<ProposalLayoutSettingSchemaImage> =
  ({ formControl, settingSchema }) => {
    const [
      createProposalImage,
      { data: createProposalImageResult, isLoading },
    ] = useCreateProposalImageMutation();

    const { value, setValue } = useFormControl(formControl);

    const onChange = useCallback(
      (file: File): void => {
        setValue(URL.createObjectURL(file), { triggeredByUser: true });
        createProposalImage({ file });
      },
      [createProposalImage, setValue],
    );

    useEffect(() => {
      if (createProposalImageResult) {
        setValue(createProposalImageResult.file);
      }
    }, [createProposalImageResult, setValue]);

    return (
      <ImageField
        value={value as string}
        onChange={onChange}
        label={settingSchema.title}
        isLoading={isLoading}
      />
    );
  };
