import {
  createTheme,
  lighten,
  rgbToHex,
  ThemeProvider,
} from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

import { ProposalLayoutDefaultData } from '@easysolar/proposals/modules/Common/models/Layouts/Default/Data';
import { ProposalLayoutDefaultSectionName } from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema';

import { ProposalPreviewSections } from '../../Sections';
import { ProposalPreviewDefaultCashFlow } from './Sections/CashFlow';
import { ProposalPreviewDefaultCoverPage } from './Sections/CoverPage';
import { ProposalPreviewDefaultCustomerProposal } from './Sections/CustomerProposal';
import { ProposalPreviewDefaultEnergyProductionAndEnvironmentalImpact } from './Sections/EnergyProductionAndEnvironmentalImpact';
import { ProposalPreviewDefaultInvestmentCosts } from './Sections/InvestmentCosts';
import { ProposalPreviewDefaultProposedSystem } from './Sections/ProposedSystem';
import { ProposalPreviewDefaultSystemDesign } from './Sections/SystemDesign';

export interface ProposalPreviewDefaultProps {
  layoutData: ProposalLayoutDefaultData;
  activeSectionName: string | null;
}

export const ProposalPreviewDefault: FunctionComponent<ProposalPreviewDefaultProps> =
  ({ layoutData, activeSectionName }) => {
    const theme = useMemo(() => {
      const {
        primaryColor = '#0099a1',
        secondaryColor = '#80cc28',
        textColor = '#646263',
      } = layoutData.generalSettings;
      const secondaryTextColor = rgbToHex(lighten(textColor, 0.25));
      const backgroundColor = rgbToHex(lighten(textColor, 0.5));

      return createTheme({
        palette: {
          primary: {
            main: primaryColor,
          },
          secondary: {
            main: secondaryColor,
          },
          text: {
            primary: textColor,
            secondary: secondaryTextColor,
          },
          background: {
            default: backgroundColor,
          },
        },
        typography: {
          fontFamily: ["'Roboto Condensed'", 'sans-serif'].join(','),
          fontWeightLight: 300,
          allVariants: {
            lineHeight: 1.375,
            fontWeight: 'normal',
            color: textColor,
          },
          h1: {
            fontSize: '45pt',
            lineHeight: 1,
            textTransform: 'uppercase',
          },
          h2: {
            fontSize: '40pt',
            lineHeight: 1,
            fontWeight: 'bold',
            color: primaryColor,
          },
          h3: {
            fontSize: '30pt',
            lineHeight: 1,
            color: primaryColor,
          },
          h4: {
            fontSize: '25pt',
            lineHeight: 1,
          },
          h5: {
            fontSize: '14pt',
            lineHeight: 1,
            color: primaryColor,
          },
          body1: {
            fontSize: '12pt',
          },
          body2: {
            fontSize: '10pt',
          },
          caption: {
            fontSize: '8pt',
            fontWeight: 300,
          },
        },
      });
    }, [layoutData.generalSettings]);

    return (
      <ThemeProvider theme={theme}>
        <ProposalPreviewSections
          layoutData={layoutData}
          sectionsComponents={{
            [ProposalLayoutDefaultSectionName.CoverPage]:
              ProposalPreviewDefaultCoverPage,
            [ProposalLayoutDefaultSectionName.ProposedSystem]:
              ProposalPreviewDefaultProposedSystem,
            [ProposalLayoutDefaultSectionName.SystemDesign]:
              ProposalPreviewDefaultSystemDesign,
            [ProposalLayoutDefaultSectionName.CustomerProposal]:
              ProposalPreviewDefaultCustomerProposal,
            [ProposalLayoutDefaultSectionName.EnergyProductionAndEnvironmentalImpact]:
              ProposalPreviewDefaultEnergyProductionAndEnvironmentalImpact,
            [ProposalLayoutDefaultSectionName.InvestmentCosts]:
              ProposalPreviewDefaultInvestmentCosts,
            [ProposalLayoutDefaultSectionName.CashFlow]:
              ProposalPreviewDefaultCashFlow,
          }}
          activeSectionName={activeSectionName}
        />
      </ThemeProvider>
    );
  };
