import i18next, { i18n } from 'i18next';
import Backend from 'i18next-http-backend';

import { ConfigurationService } from './ConfigurationService';

export enum Language {
  BgBg = 'bg-BG',
  DeDe = 'de-DE',
  EnUs = 'en-US',
  EsEs = 'es-ES',
  NbNo = 'nb-NO',
  NlNl = 'nl-NL',
  PlPl = 'pl-PL',
  PtBr = 'pt-BR',
  SkSk = 'sk-SK',
  SvSe = 'sv-SE',
  UkUa = 'uk-UA',
}

export enum Namespace {
  Editor = 'Editor',
  Layouts = 'Layouts',
  Shared = 'Shared',
}

export class InternationalizationService {
  public static SUPPORTED_LANGUAGES = [
    Language.BgBg,
    Language.DeDe,
    Language.EnUs,
    Language.EsEs,
    Language.NbNo,
    Language.NlNl,
    Language.PlPl,
    Language.PtBr,
    Language.SkSk,
    Language.SvSe,
    Language.UkUa,
  ];

  public static DEFAULT_LANGUAGE = Language.EnUs;

  private static LANGUAGES_MAPPING =
    InternationalizationService.SUPPORTED_LANGUAGES.reduce(
      (mapping, languageCode) => {
        // eslint-disable-next-line no-param-reassign
        mapping[InternationalizationService.getLanguageCodeKey(languageCode)] =
          languageCode;

        return mapping;
      },
      {} as Record<string, string>,
    );

  public static initialize(
    namespaces: Namespace[],
    options: {
      defaultNamespace?: Namespace;
      initialLanguage?: Language;
      onInit?: () => void;
    },
  ): i18n {
    const { defaultNamespace, initialLanguage, onInit } = options;

    const i18nextInstance = i18next.createInstance();

    i18nextInstance.use(Backend).init(
      {
        debug: ConfigurationService.APP_CONFIG.debug,

        lng: initialLanguage,
        fallbackLng: InternationalizationService.getFallbackLanguage,
        supportedLngs: InternationalizationService.SUPPORTED_LANGUAGES,
        cleanCode: true,
        load: 'currentOnly',
        ns: namespaces,
        defaultNS: defaultNamespace || namespaces[0],

        interpolation: {
          escapeValue: false,
        },

        react: {
          useSuspense: false,
        },

        backend: {
          loadPath: async (
            [language]: string[],
            [namespace]: string[],
          ): Promise<string> => {
            // Translation file is saved as JSON but loaded by webpack as an URL (string).
            // eslint-disable-next-line no-unsanitized/method
            const translationsModule = (await import(
              `../../assets/locales/${language}/${namespace}.json`
            )) as { default: string };

            return translationsModule.default;
          },
          crossDomain: true,
        },
      },
      onInit,
    );

    return i18nextInstance;
  }

  private static getLanguageCodeKey(languageCode: string): string {
    return languageCode?.slice(0, 2).toLocaleLowerCase();
  }

  private static getFallbackLanguage(languageCode: string): string {
    return (
      InternationalizationService.LANGUAGES_MAPPING[languageCode] ||
      InternationalizationService.DEFAULT_LANGUAGE
    );
  }
}
