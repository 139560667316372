import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultEnergyProductionStyles = makeStyles(
  () => ({
    irradiation: {
      flex: '30%',
    },
    chart: {
      flex: '70%',
    },
  }),
);
