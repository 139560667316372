import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultProposedSystemElectricityBillStyles =
  makeStyles((theme) => ({
    leftColumn: {
      width: '55%',
    },
    rightColumn: {
      width: '45%',
      paddingLeft: '7%',
    },
    annualElectricityBill: {
      paddingBottom: '6%',
    },
    priceOfElectricity: {
      paddingTop: '1%',
    },
    priceOfElectricityText: {
      color: theme.palette.text.secondary,
    },
    factValue: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }));
