import { Box, Container, Typography } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import { keyBy } from 'lodash';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ProposalLayoutAnyData } from '@easysolar/proposals/modules/Common/models/LayoutData/Layout';
import { ProposalLayoutSchema } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Layout';
import { useFormArray } from '@easysolar/proposals/shared/forms/hooks/useFormArray';
import { FormArray, FormGroup } from '@easysolar/proposals/shared/forms/models';

import { ProposalTemplatesPermission } from '../../models/ProposalTemplatesPermission';
import { selectEmployeeProposalTemplatesPermission } from '../../store/webComponentProps';
import { ProposalEditorSideBarSection } from './Section';
import { ProposalEditorSideBarSectionsList } from './SectionsList';
import { ProposalEditorSideBarSettings } from './Settings';
import { getProposalEditorSideBarStyles } from './SideBar.styles';

export interface ProposalEditorSideBarProps {
  layoutFormGroup: FormGroup<ProposalLayoutAnyData>;
  layoutSchema: ProposalLayoutSchema;
  disabled: boolean;
  activeSectionName: string | null;
  onActiveSectionNameChange: (activeSectionName: string | null) => void;
}

export const ProposalEditorSideBar: FunctionComponent<ProposalEditorSideBarProps> =
  ({
    layoutFormGroup,
    layoutSchema,
    disabled,
    activeSectionName,
    onActiveSectionNameChange,
  }) => {
    const classes = getProposalEditorSideBarStyles();
    const { t } = useTranslation();

    const permission = useSelector(selectEmployeeProposalTemplatesPermission);
    const canEmployeeEditGeneralSettings = useMemo(
      () => permission === ProposalTemplatesPermission.FullAccess,
      [permission],
    );

    const generalSettingsSection = useMemo(
      () => (
        <ProposalEditorSideBarSettings
          icon={<BusinessIcon />}
          formGroup={layoutFormGroup.controls.generalSettings as FormGroup}
          groupOfSettingsSchema={layoutSchema.properties.generalSettings}
          disabled={disabled}
        />
      ),
      [
        disabled,
        layoutFormGroup.controls.generalSettings,
        layoutSchema.properties.generalSettings,
      ],
    );

    const sectionsSchema = useMemo(
      () => layoutSchema.properties.sections.items.oneOf,
      [layoutSchema.properties.sections.items.oneOf],
    );

    const sectionsSchemasByName = useMemo(
      () => keyBy(sectionsSchema, (schema) => schema.name),
      [sectionsSchema],
    );

    const sectionsList = useMemo(
      () => (
        <ProposalEditorSideBarSectionsList
          sectionsForm={layoutFormGroup.controls.sections as FormArray}
          sectionsSchemasByName={sectionsSchemasByName}
          disabled={disabled}
        />
      ),
      [disabled, layoutFormGroup.controls.sections, sectionsSchemasByName],
    );

    const { value: sectionsFormData } = useFormArray(
      layoutFormGroup.controls.sections,
    );

    const getOnSectionExpand = useCallback(
      (sectionName: string) =>
        (isExpanded: boolean): void => {
          onActiveSectionNameChange(isExpanded ? sectionName : null);
        },
      [onActiveSectionNameChange],
    );

    const sections = useMemo(
      () =>
        sectionsFormData.map((section, index) => {
          const sectionName = section.name;

          const sectionSchema =
            sectionName && sectionsSchemasByName[sectionName];
          const sectionFormGroup =
            layoutFormGroup.controls.sections.controls[index];

          return (
            sectionName &&
            sectionSchema && (
              <ProposalEditorSideBarSection
                key={sectionName}
                sectionSchema={sectionSchema}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                formGroup={sectionFormGroup as any}
                disabled={disabled}
                expanded={sectionName === activeSectionName}
                onExpand={getOnSectionExpand(sectionName)}
              />
            )
          );
        }),
      [
        sectionsFormData,
        sectionsSchemasByName,
        layoutFormGroup.controls.sections.controls,
        disabled,
        activeSectionName,
        getOnSectionExpand,
      ],
    );

    const sectionSettings = useMemo(
      () => (
        <Box className={classes.sectionSettings}>
          <Typography variant="body2" className={classes.sectionSettingsTitle}>
            {t('Editor:SectionsSettings.Title')}:
          </Typography>
          <Box>{sections}</Box>
        </Box>
      ),
      [classes.sectionSettings, classes.sectionSettingsTitle, sections, t],
    );

    return (
      <Container className={classes.container} maxWidth="xs">
        {canEmployeeEditGeneralSettings && generalSettingsSection}

        {sectionsList}

        {sectionSettings}
      </Container>
    );
  };
