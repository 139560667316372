import { makeStyles, Theme } from '@material-ui/core';

interface ProposalPreviewDefaultInvestmentCostsElectricityBillCircleStylesProps {
  color: string;
}

export const getProposalPreviewDefaultInvestmentCostsElectricityBillCircleStyles =
  makeStyles<
    Theme,
    ProposalPreviewDefaultInvestmentCostsElectricityBillCircleStylesProps
  >(() => ({
    circle: {
      width: '40mm',
      height: '40mm',
      borderStyle: 'solid',
      borderRadius: '50%',
      borderWidth: '0.75mm',
      borderColor: ({ color }): string => color,
      marginBottom: '6mm',
    },
    value: {
      color: ({ color }): string => color,
    },
    label: {
      textTransform: 'uppercase',
      textAlign: 'center',
      lineHeight: 1,
    },
    colorLabel: {
      color: ({ color }): string => color,
      fontWeight: 'bold',
    },
  }));
