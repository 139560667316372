import { CircularProgress, IconButton, SvgIcon } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { ShadowRootTooltip } from '@easysolar/proposals/shared/components/ShadowRootMaterialUI/Tooltip';

import { getProposalEditorToolbarTemplatesSectionButtonStyles } from './Button.styles';

interface ProposalEditorToolbarTemplatesSectionButtonProps {
  title: string;
  IconComponent: typeof SvgIcon;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

export const ProposalEditorToolbarTemplatesSectionButton: FunctionComponent<ProposalEditorToolbarTemplatesSectionButtonProps> =
  ({ title, IconComponent, disabled = false, loading = false, onClick }) => {
    const classes = getProposalEditorToolbarTemplatesSectionButtonStyles();

    return (
      <ShadowRootTooltip title={title}>
        <span>
          <IconButton aria-label={title} disabled={disabled} onClick={onClick}>
            <IconComponent />
            {loading && <CircularProgress className={classes.loader} />}
          </IconButton>
        </span>
      </ShadowRootTooltip>
    );
  };
