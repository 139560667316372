import { Grid, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { FunctionComponent, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ProposalCurrencyNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalCurrencyNumberFormat';

import { getProposalPreviewDefaultInvestmentCostsElectricityBillCircleStyles } from './Circle.styles';

interface ProposalPreviewDefaultInvestmentCostsElectricityBillCircleProps {
  color: string;
  value: number;
  i18nKey: string;
  i18nCount?: number;
}

export const ProposalPreviewDefaultInvestmentCostsElectricityBillCircle: FunctionComponent<ProposalPreviewDefaultInvestmentCostsElectricityBillCircleProps> =
  ({ color, value, i18nKey, i18nCount }) => {
    const { t } = useTranslation();

    const classes =
      getProposalPreviewDefaultInvestmentCostsElectricityBillCircleStyles({
        color,
      });

    const valueDefaultVariant: Variant = useMemo(() => {
      if (value < 1000) {
        return 'h4';
      }

      if (value < 1000000) {
        return 'h5';
      }

      return 'body1';
    }, [value]);

    const valueCurrencyVariant: Variant = useMemo(() => {
      if (value < 1000) {
        return 'h5';
      }

      if (value < 1000000) {
        return 'body1';
      }

      return 'body2';
    }, [value]);

    const circle = useMemo(
      () => (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          className={classes.circle}
        >
          <ProposalCurrencyNumberFormat
            value={value}
            defaultProps={{
              className: classes.value,
              variant: valueDefaultVariant,
            }}
            partTypesProps={{
              currency: {
                variant: valueCurrencyVariant,
              },
            }}
          />
        </Grid>
      ),
      [
        classes.circle,
        classes.value,
        value,
        valueCurrencyVariant,
        valueDefaultVariant,
      ],
    );

    const caption = useMemo(
      () => (
        <Typography className={classes.label} variant="caption">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Trans t={t} i18nKey={i18nKey} count={i18nCount}>
            <Typography
              className={classes.colorLabel}
              component="span"
              variant="caption"
            >
              Color text
            </Typography>
            <br />
            Normal text
          </Trans>
        </Typography>
      ),
      [classes.colorLabel, classes.label, i18nCount, i18nKey, t],
    );

    return (
      <Grid item container direction="column" alignItems="center">
        {circle}
        {caption}
      </Grid>
    );
  };
