import { useTheme } from '@material-ui/core';

import {
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName,
} from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema';

import { ProposalPreviewSection } from '../../../../Section';
import { ProposalPreviewDefaultSectionPage } from '../../Shared/SectionPage';
import { ProposalPreviewDefaultCoverPageContent } from './Content';

export const ProposalPreviewDefaultCoverPage: ProposalPreviewSection<
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName.CoverPage
> = ({ generalSettings, sectionSettings }) => {
  const theme = useTheme();

  return (
    <ProposalPreviewDefaultSectionPage
      generalSettings={generalSettings}
      backgroundImageURL={sectionSettings.backgroundImage}
      showLogo={sectionSettings.isLogoVisible}
      logoCSSProperties={{
        height: '20%',
        opacity: 1,
      }}
      justifyContent="flex-end"
      footer={{
        left: {
          toggleableText: generalSettings.coverPageFooter.left,
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightBold,
        },
        right: {
          toggleableText: generalSettings.coverPageFooter.right,
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightBold,
        },
      }}
    >
      <ProposalPreviewDefaultCoverPageContent
        generalSettings={generalSettings}
        sectionSettings={sectionSettings}
      />
    </ProposalPreviewDefaultSectionPage>
  );
};
