import { Popper, PopperProps } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { useShadowRootContainer } from '@easysolar/proposals/infrastructure/hooks/useShadowRootContainer';

export const ShadowRootPopper: FunctionComponent<PopperProps> = ({
  container,
  children,
  ...props
}) => {
  const shadowRootContainerRef = useShadowRootContainer();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Popper {...props} container={container || shadowRootContainerRef}>
      {children}
    </Popper>
  );
};
