import { Box, Grid, GridJustification } from '@material-ui/core';
import { CSSProperties, FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';
import { ProposalLayoutGroupOfSettingsData } from '@easysolar/proposals/modules/Common/models/LayoutData/GroupOfSettings';
import {
  ProposalLayoutDefaultAlignment,
  ProposalLayoutDefaultGeneralSettingsSchema,
} from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema/GeneralSettings';
import { ToggleableText } from '@easysolar/proposals/shared/components/ToggleableTextField';

import { ProposalPreviewDefaultPage } from '../Page';
import { ProposalPreviewDefaultSectionTitle } from '../SectionTitle';
import {
  ProposalPreviewDefaultSectionPageFooterText,
  ProposalPreviewDefaultSectionPageFooterTextProps,
} from './FooterText';
import { getProposalPreviewDefaultSectionPageStyles } from './SectionPage.styles';

export interface ProposalPreviewDefaultSectionPageProps {
  generalSettings: ProposalLayoutGroupOfSettingsData<ProposalLayoutDefaultGeneralSettingsSchema>;
  backgroundImageURL?: string;
  showLogo?: boolean;
  logoCSSProperties?: {
    height?: CSSProperties['height'];
    opacity?: CSSProperties['opacity'];
  };
  title?: string;
  subtitle?: string;
  justifyContent?: GridJustification;
  footer?: {
    left?: Omit<ProposalPreviewDefaultSectionPageFooterTextProps, 'align'>;
    right?: Omit<ProposalPreviewDefaultSectionPageFooterTextProps, 'align'>;
  };
}

export const ProposalPreviewDefaultSectionPage: FunctionComponent<ProposalPreviewDefaultSectionPageProps> =
  ({
    children,
    generalSettings,
    backgroundImageURL,
    showLogo = true,
    logoCSSProperties,
    title,
    subtitle,
    justifyContent: overrideJustifyContent,
    footer,
  }) => {
    const classes = getProposalPreviewDefaultSectionPageStyles({
      logoCSSProperties,
    });

    const { t } = useTranslation();

    const {
      company: { logo: companyLogo },
    } = useProposalContext();

    const justifyContent = useMemo(
      () => overrideJustifyContent || 'flex-start',
      [overrideJustifyContent],
    );

    const logoContainer = useMemo(
      () => (
        <Grid
          className={classes.logoContainer}
          item
          container
          justifyContent="flex-end"
        >
          {companyLogo && generalSettings.isLogoVisible && showLogo && (
            <img
              className={classes.logo}
              src={companyLogo}
              alt={t('Layouts:Default.Sections.CoverPage.LogoAlt')}
            />
          )}
        </Grid>
      ),
      [
        classes.logo,
        classes.logoContainer,
        companyLogo,
        generalSettings.isLogoVisible,
        showLogo,
        t,
      ],
    );

    const content = useMemo(
      () => (
        <Grid
          className={classes.content}
          item
          container
          direction="column"
          justifyContent={justifyContent}
          wrap="nowrap"
        >
          {(title || subtitle) && (
            <Box paddingBottom="3.5%">
              <ProposalPreviewDefaultSectionTitle
                title={title}
                subtitle={subtitle}
              />
            </Box>
          )}
          <Grid
            className={classes.content}
            item
            container
            direction="column"
            justifyContent={justifyContent}
            wrap="nowrap"
          >
            {children}
          </Grid>
        </Grid>
      ),
      [children, classes.content, justifyContent, subtitle, title],
    );

    const defaultFooterToggleableText = useMemo(
      () => ({
        enabled: true,
        text: t('Layouts:Default.GeneralSettings.Settings.Footer.BrandingText'),
      }),
      [t],
    );

    const getFooterSideToggleableText = useCallback(
      (
        side: ProposalLayoutDefaultAlignment,
        overridenFooter?: Omit<
          ProposalPreviewDefaultSectionPageFooterTextProps,
          'align'
        >,
      ): ToggleableText => {
        if (overridenFooter) {
          return overridenFooter?.toggleableText;
        }

        if (
          generalSettings?.footer?.alignment === side &&
          generalSettings?.footer?.text
        ) {
          return generalSettings.footer.text;
        }

        return defaultFooterToggleableText;
      },
      [
        defaultFooterToggleableText,
        generalSettings?.footer?.alignment,
        generalSettings?.footer?.text,
      ],
    );

    const leftFooterToggleableText = useMemo(
      () =>
        getFooterSideToggleableText(
          ProposalLayoutDefaultAlignment.Left,
          footer?.left,
        ),
      [footer?.left, getFooterSideToggleableText],
    );

    const leftFooter = useMemo(
      () =>
        leftFooterToggleableText && (
          <ProposalPreviewDefaultSectionPageFooterText
            toggleableText={leftFooterToggleableText}
            align="left"
            color={footer?.left?.color}
            fontWeight={footer?.left?.fontWeight}
          />
        ),
      [footer?.left?.color, footer?.left?.fontWeight, leftFooterToggleableText],
    );

    const rightFooterToggleableText = useMemo(
      () =>
        getFooterSideToggleableText(
          ProposalLayoutDefaultAlignment.Right,
          footer?.right,
        ),
      [footer?.right, getFooterSideToggleableText],
    );

    const rightFooter = useMemo(
      () =>
        rightFooterToggleableText && (
          <ProposalPreviewDefaultSectionPageFooterText
            toggleableText={rightFooterToggleableText}
            align="right"
            color={footer?.right?.color}
            fontWeight={footer?.right?.fontWeight}
          />
        ),
      [
        footer?.right?.color,
        footer?.right?.fontWeight,
        rightFooterToggleableText,
      ],
    );

    const footerComponent = useMemo(
      () => (
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          wrap="nowrap"
        >
          {leftFooter}
          {rightFooter}
        </Grid>
      ),
      [leftFooter, rightFooter],
    );

    const page = useMemo(
      () => (
        <ProposalPreviewDefaultPage backgroundImageURL={backgroundImageURL}>
          <Grid
            className={classes.grid}
            container
            direction="column"
            justifyContent="space-between"
            wrap="nowrap"
          >
            {logoContainer}
            {content}
            {footerComponent}
          </Grid>
        </ProposalPreviewDefaultPage>
      ),
      [
        backgroundImageURL,
        classes.grid,
        content,
        footerComponent,
        logoContainer,
      ],
    );

    return page;
  };
