import { Grid, Typography, TypographyProps } from '@material-ui/core';
import { FunctionComponent, useCallback, useMemo } from 'react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ProposalCurrencyNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalCurrencyNumberFormat';
import { ProposalUnitNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalUnitNumberFormat';
import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';

import { ProposalPreviewDefaultSubsection } from '../../../Shared/Subsection';
import { getProposalPreviewDefaultProposedSystemElectricityBillStyles } from './ElectricityBill.styles';
import { ProposalPreviewDefaultProposedSystemElectricityBillFact } from './Fact';

export interface ProposalPreviewDefaultProposedSystemElectricityBillProps {
  title: string;
  showFacts: boolean;
}

export const ProposalPreviewDefaultProposedSystemElectricityBill: FunctionComponent<ProposalPreviewDefaultProposedSystemElectricityBillProps> =
  ({ title, showFacts }) => {
    const classes =
      getProposalPreviewDefaultProposedSystemElectricityBillStyles();

    const { t } = useTranslation();

    const { project } = useProposalContext();

    const { analysisPeriod, financialAnalysis, environmentalImpact } = project;

    const annualElectricityBill = useMemo(
      () => (
        <ProposalCurrencyNumberFormat
          className={classes.annualElectricityBill}
          value={financialAnalysis.annualElectricityBill}
          defaultProps={{
            variant: 'h2',
            color: 'primary',
          }}
          partTypesProps={{
            currency: {
              variant: 'body1',
            },
            unit: {
              variant: 'body1',
            },
          }}
          additionalUnit={t('Shared:Units.Year')}
        />
      ),
      [
        classes.annualElectricityBill,
        financialAnalysis.annualElectricityBill,
        t,
      ],
    );

    const monthlyElectricityBill = useMemo(
      () => (
        <ProposalCurrencyNumberFormat
          value={financialAnalysis.monthlyElectricityBill}
          defaultProps={{
            variant: 'h4',
          }}
          partTypesProps={{
            currency: {
              variant: 'body2',
            },
            unit: {
              variant: 'body2',
            },
          }}
          additionalUnit={t('Shared:Units.MonthAbbreviation')}
        />
      ),
      [financialAnalysis.monthlyElectricityBill, t],
    );

    const priceOfElectricity = useMemo(
      () => (
        <Grid className={classes.priceOfElectricity} item>
          <Typography
            className={classes.priceOfElectricityText}
            component="span"
            variant="caption"
          >
            {t(
              'Layouts:Default.Sections.ProposedSystem.Subsections.YourCurrentElectricityBill.PriceOfElectricity',
            )}
            &nbsp;=&nbsp;
          </Typography>
          <ProposalCurrencyNumberFormat
            value={financialAnalysis.priceOfElectricity}
            defaultProps={{
              className: classes.priceOfElectricityText,
              variant: 'caption',
            }}
          />
        </Grid>
      ),
      [
        classes.priceOfElectricity,
        classes.priceOfElectricityText,
        financialAnalysis.priceOfElectricity,
        t,
      ],
    );

    const leftColumn = useMemo(
      () => (
        <Grid
          className={classes.leftColumn}
          item
          container
          direction="column"
          justifyContent="center"
        >
          {annualElectricityBill}
          <Grid item container direction="column">
            {monthlyElectricityBill}
            {priceOfElectricity}
          </Grid>
        </Grid>
      ),
      [
        annualElectricityBill,
        classes.leftColumn,
        priceOfElectricity,
        monthlyElectricityBill,
      ],
    );

    const onAnnualPriceIncreaseOfElectricityValueRender = useCallback(
      (defaultProps: Partial<TypographyProps>): React.ReactElement => (
        <ProposalUnitNumberFormat
          options={{
            style: 'percent',
            minimumFractionDigits: 1,
          }}
          defaultProps={defaultProps}
          value={financialAnalysis.annualPriceIncreaseOfElectricity}
        />
      ),
      [financialAnalysis.annualPriceIncreaseOfElectricity],
    );

    const annualPriceIncreaseOfElectricity = useMemo(
      () => (
        <ProposalPreviewDefaultProposedSystemElectricityBillFact
          first
          label={
            <Trans
              t={t}
              i18nKey="Layouts:Default.Sections.ProposedSystem.Subsections.YourCurrentElectricityBill.AnnualPriceIncreaseOfElectricity"
            >
              Normal text<strong>Bold text</strong>Normal text
            </Trans>
          }
          onValueRender={onAnnualPriceIncreaseOfElectricityValueRender}
        />
      ),
      [onAnnualPriceIncreaseOfElectricityValueRender, t],
    );

    const onPriceOfElectricityAfterAnalysisPeriodValueRender = useCallback(
      (defaultProps: Partial<TypographyProps>): React.ReactElement => (
        <ProposalCurrencyNumberFormat
          defaultProps={defaultProps}
          value={
            financialAnalysis.forecast.priceOfElectricityAfterAnalysisPeriod
          }
        />
      ),
      [financialAnalysis.forecast.priceOfElectricityAfterAnalysisPeriod],
    );

    const priceOfElectricityAfterAnalysisPeriod = useMemo(
      () => (
        <ProposalPreviewDefaultProposedSystemElectricityBillFact
          label={
            <Trans
              t={t}
              i18nKey="Layouts:Default.Sections.ProposedSystem.Subsections.YourCurrentElectricityBill.PriceOfElectricityAfterAnalysisPeriod"
              values={{ analysisPeriod }}
            >
              Normal text<strong>Bold text</strong>
            </Trans>
          }
          onValueRender={onPriceOfElectricityAfterAnalysisPeriodValueRender}
        />
      ),
      [analysisPeriod, onPriceOfElectricityAfterAnalysisPeriodValueRender, t],
    );

    const onAnnualCO2EmissionValueRender = useCallback(
      (defaultProps: Partial<TypographyProps>): React.ReactElement => (
        <ProposalUnitNumberFormat
          defaultProps={defaultProps}
          value={environmentalImpact.annualCO2Emission}
          unit={`kg/${t('Shared:Units.Year')}`}
        />
      ),
      [environmentalImpact.annualCO2Emission, t],
    );

    const annualCO2Emission = useMemo(
      () => (
        <ProposalPreviewDefaultProposedSystemElectricityBillFact
          label={
            <Trans
              t={t}
              i18nKey="Layouts:Default.Sections.ProposedSystem.Subsections.YourCurrentElectricityBill.AnnualCO2Emission"
            >
              Normal text<strong>Bold text</strong>
            </Trans>
          }
          onValueRender={onAnnualCO2EmissionValueRender}
        />
      ),
      [onAnnualCO2EmissionValueRender, t],
    );

    const facts = useMemo(
      () => (
        <Grid
          className={classes.rightColumn}
          item
          container
          direction="column"
          justifyContent="center"
        >
          {annualPriceIncreaseOfElectricity}
          {priceOfElectricityAfterAnalysisPeriod}
          {annualCO2Emission}
        </Grid>
      ),
      [
        annualCO2Emission,
        annualPriceIncreaseOfElectricity,
        classes.rightColumn,
        priceOfElectricityAfterAnalysisPeriod,
      ],
    );

    const rightColumn = useMemo(() => showFacts && facts, [facts, showFacts]);

    const subsection = useMemo(
      () => (
        <ProposalPreviewDefaultSubsection title={title}>
          <Grid container direction="row" wrap="nowrap">
            {leftColumn}
            {rightColumn}
          </Grid>
        </ProposalPreviewDefaultSubsection>
      ),
      [leftColumn, rightColumn, title],
    );

    return subsection;
  };
