import { createContext, useContext } from 'react';

import { PrintingContextData } from '../models/PrintingContextData';

export interface UsePrintingContextResult {
  printing: boolean;
}

export const PrintingContext = createContext<PrintingContextData>({
  printing: false,
});

export const usePrintingContext = (): UsePrintingContextResult => {
  return useContext(PrintingContext);
};
