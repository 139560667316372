import { useMemo } from 'react';

import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';
import {
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName,
} from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema';

import { ProposalPreviewSection } from '../../../../Section';
import { ProposalPreviewDefaultSectionPage } from '../../Shared/SectionPage';
import { ProposalPreviewDefaultSystemDesignVisualization } from './Visualization';

export const ProposalPreviewDefaultSystemDesign: ProposalPreviewSection<
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName.SystemDesign
> = ({ generalSettings, sectionSettings }) => {
  const proposalContext = useProposalContext();

  const pages = useMemo(
    () =>
      proposalContext.project.designs.map((design, index) => (
        <ProposalPreviewDefaultSectionPage
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          generalSettings={generalSettings}
          title={sectionSettings.title}
          subtitle={sectionSettings.subtitle}
        >
          <ProposalPreviewDefaultSystemDesignVisualization design={design} />
        </ProposalPreviewDefaultSectionPage>
      )),
    [
      generalSettings,
      proposalContext.project.designs,
      sectionSettings.subtitle,
      sectionSettings.title,
    ],
  );

  return <>{pages}</>;
};
