import {
  createContext,
  FunctionComponent,
  useLayoutEffect,
  useRef,
} from 'react';

import { useForceUpdate } from '../hooks/useForceUpdate';
import { getShadowRootContainerStyles } from './ShadowRootContainer.styles';

export const ShadowRootContainerRefContext = createContext<HTMLElement | null>(
  null,
);

export const ShadowRootContainer: FunctionComponent = ({ children }) => {
  const classes = getShadowRootContainerStyles();

  const shadowRootDOMRef = useRef<HTMLDivElement | null>(null);

  const forceUpdate = useForceUpdate();
  useLayoutEffect(() => forceUpdate(), [forceUpdate]);

  return (
    <ShadowRootContainerRefContext.Provider value={shadowRootDOMRef.current}>
      {/* 
        Material UI portal requires its root container to have parent element as well.
        That's why the content is wrapped in two containers and the inner one's ref is exposed.
      */}
      <div className={classes.container}>
        <div ref={shadowRootDOMRef} className={classes.container}>
          {children}
        </div>
      </div>
    </ShadowRootContainerRefContext.Provider>
  );
};
