import { Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { getProposalPreviewDefaultEnergyProductionCaptionStyles } from './Caption.styles';

export const ProposalPreviewDefaultEnergyProductionCaption: FunctionComponent =
  ({ children }) => {
    const classes = getProposalPreviewDefaultEnergyProductionCaptionStyles();

    return (
      <Typography variant="caption" className={classes.root}>
        {children}
      </Typography>
    );
  };
