import { makeStyles } from '@material-ui/core';

export const useProposalEditorStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  layoutEditor: {
    flex: 1,
    minHeight: 0,
  },
}));
