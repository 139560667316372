import { Divider, Grid, Typography, useTheme } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProposalUnitNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalUnitNumberFormat';
import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';

import { ProposalPreviewDefaultSubsection } from '../../../Shared/Subsection';
import { ProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystemProgressBar } from './ProgressBar';
import { getProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystemStyles } from './RecommendedSizeOfPVSystem.styles';

export interface ProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystemProps {
  title: string;
  showEnergyProductionAndConsumptionComparison: boolean;
}

export const ProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystem: FunctionComponent<ProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystemProps> =
  ({ title, showEnergyProductionAndConsumptionComparison }) => {
    const classes =
      getProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystemStyles();

    const { t } = useTranslation();

    const theme = useTheme();

    const proposalContext = useProposalContext();

    const {
      nominalPower,
      surfaceArea,
      financialAnalysis: {
        forecast: { annualEnergyProduction, annualEnergyConsumption },
      },
    } = proposalContext.project;

    const renderedNominalPower = useMemo(
      () => (
        <ProposalUnitNumberFormat
          className={classes.nominalPower}
          value={nominalPower / 1000}
          unit="kWp"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          defaultProps={{
            variant: 'h2',
            color: 'primary',
          }}
          partTypesProps={{
            currency: {
              variant: 'body1',
            },
            unit: {
              variant: 'body1',
            },
          }}
        />
      ),
      [classes.nominalPower, nominalPower],
    );

    const surfaceAreaLabel = useMemo(
      () => (
        <Typography className={classes.surfaceAreaLabel} variant="caption">
          {t(
            'Layouts:Default.Sections.ProposedSystem.Subsections.RecommendedSizeOfPVSystem.OccupiedAreaLabel',
          )}
        </Typography>
      ),
      [classes.surfaceAreaLabel, t],
    );

    const renderedSurfaceArea = useMemo(
      () => (
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
        >
          {surfaceAreaLabel}
          <ProposalUnitNumberFormat
            value={surfaceArea}
            unit="m²"
            options={{
              maximumFractionDigits: 0,
            }}
            defaultProps={{
              variant: 'h4',
            }}
            partTypesProps={{
              currency: {
                variant: 'body2',
              },
              unit: {
                variant: 'body2',
              },
            }}
          />
        </Grid>
      ),
      [surfaceArea, surfaceAreaLabel],
    );

    const leftColumn = useMemo(
      () => (
        <Grid
          className={classes.leftColumn}
          item
          container
          direction="column"
          justifyContent="center"
        >
          {renderedNominalPower}
          {renderedSurfaceArea}
        </Grid>
      ),
      [classes.leftColumn, renderedNominalPower, renderedSurfaceArea],
    );

    const annualEnergyProductionBar = useMemo(
      () => (
        <ProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystemProgressBar
          className={classes.annualEnergyProduction}
          label={t(
            'Layouts:Default.Sections.ProposedSystem.Subsections.RecommendedSizeOfPVSystem.AnnualEnergyProductionRatioLabel',
          )}
          value={annualEnergyProduction}
          progress={annualEnergyProduction / annualEnergyConsumption}
          color={theme.palette.secondary.main}
        />
      ),
      [
        classes.annualEnergyProduction,
        annualEnergyConsumption,
        annualEnergyProduction,
        t,
        theme.palette.secondary.main,
      ],
    );

    const annualEnergyConsumptionBar = useMemo(
      () => (
        <ProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystemProgressBar
          label={t(
            'Layouts:Default.Sections.ProposedSystem.Subsections.RecommendedSizeOfPVSystem.AnnualEnergyConsumptionRatioLabel',
          )}
          value={annualEnergyConsumption}
          progress={annualEnergyConsumption / annualEnergyProduction}
          color={theme.palette.text.primary}
        />
      ),
      [
        annualEnergyConsumption,
        annualEnergyProduction,
        t,
        theme.palette.text.primary,
      ],
    );

    const energyProductionAndConsumptionComparison = useMemo(
      () => (
        <>
          <Divider orientation="vertical" flexItem />

          <Grid
            className={classes.rightColumn}
            container
            item
            direction="column"
          >
            {annualEnergyProductionBar}
            {annualEnergyConsumptionBar}
          </Grid>
        </>
      ),
      [
        annualEnergyConsumptionBar,
        annualEnergyProductionBar,
        classes.rightColumn,
      ],
    );

    const rightColumn = useMemo(
      () =>
        showEnergyProductionAndConsumptionComparison &&
        energyProductionAndConsumptionComparison,
      [
        energyProductionAndConsumptionComparison,
        showEnergyProductionAndConsumptionComparison,
      ],
    );

    const subsection = useMemo(
      () => (
        <ProposalPreviewDefaultSubsection title={title}>
          <Grid container direction="row" wrap="nowrap">
            {leftColumn}

            {rightColumn}
          </Grid>
        </ProposalPreviewDefaultSubsection>
      ),
      [title, leftColumn, rightColumn],
    );

    return subsection;
  };
