import { getFixedCacheKeyMutation } from '../hooks/getFixedCacheKeyMutation';
import {
  APICreateProposalTemplateData,
  CreateProposalTemplateData,
} from '../models/CreateProposalTemplate';
import { GetProposalTemplatesParams } from '../models/GetProposalTemplate';
import {
  APIProposalTemplate,
  ProposalTemplate,
} from '../models/ProposalTemplate';
import {
  APIUpdateProposalTemplateData,
  UpdateProposalTemplateData,
} from '../models/UpdateProposalTemplate';
import { easysolarAPI } from './easysolarAPI';

const PROPOSAL_TEMPLATES_PATH = '/proposal-templates';

function transformAPIProposalTemplateToProposalTemplate(
  template: APIProposalTemplate,
): ProposalTemplate {
  return {
    id: template.id,
    name: template.name,
    layoutName: template.layout_name,
    layoutVersion: template.layout_version,
    layoutData: template.layout_data,
  };
}

export const proposalTemplatesAPI = easysolarAPI.injectEndpoints({
  endpoints: (builder) => ({
    getProposalTemplates: builder.query<
      ProposalTemplate[],
      GetProposalTemplatesParams
    >({
      query: ({ layoutName }) =>
        `${PROPOSAL_TEMPLATES_PATH}?layout_name=${layoutName}`,
      transformResponse: (
        templates: APIProposalTemplate[],
      ): ProposalTemplate[] => {
        return templates.map((template) =>
          transformAPIProposalTemplateToProposalTemplate(template),
        );
      },
    }),
    createProposalTemplate: builder.mutation<
      ProposalTemplate,
      CreateProposalTemplateData
    >({
      query: ({ name, layoutName, layoutVersion, layoutData }) => {
        const body: APICreateProposalTemplateData = {
          name,
          layout_name: layoutName,
          layout_version: layoutVersion,
          layout_data: layoutData,
        };

        return {
          url: PROPOSAL_TEMPLATES_PATH,
          method: 'POST',
          body,
        };
      },
      transformResponse: transformAPIProposalTemplateToProposalTemplate,
    }),
    updateProposalTemplate: builder.mutation<
      ProposalTemplate,
      UpdateProposalTemplateData
    >({
      query: ({ id, name, layoutName, layoutVersion, layoutData }) => {
        const body: APIUpdateProposalTemplateData = {
          id,
          name,
          layout_name: layoutName,
          layout_version: layoutVersion,
          layout_data: layoutData,
        };

        return {
          url: `${PROPOSAL_TEMPLATES_PATH}/${id}`,
          method: 'PATCH',
          body,
        };
      },
      transformResponse: transformAPIProposalTemplateToProposalTemplate,
    }),
    deleteProposalTemplate: builder.mutation<string, string>({
      query: (proposalTemplateId) => {
        return {
          url: `${PROPOSAL_TEMPLATES_PATH}/${proposalTemplateId}`,
          method: 'DELETE',
        };
      },
      transformResponse: (
        _response: null,
        _meta,
        proposalTemplateId: string,
      ) => {
        return proposalTemplateId;
      },
    }),
  }),
});

export const {
  useGetProposalTemplatesQuery,
  useCreateProposalTemplateMutation,
  useUpdateProposalTemplateMutation,
  useDeleteProposalTemplateMutation,
} = proposalTemplatesAPI;

export const CREATE_PROPOSAL_TEMPLATE_MUTATION_FIXED_CACHE_KEY =
  'createProposalTemplate';
export const useCreateProposalTemplateSharedMutation = getFixedCacheKeyMutation(
  useCreateProposalTemplateMutation,
  CREATE_PROPOSAL_TEMPLATE_MUTATION_FIXED_CACHE_KEY,
);

export const UPDATE_PROPOSAL_TEMPLATE_MUTATION_FIXED_CACHE_KEY =
  'updateProposalTemplate';
export const useUpdateProposalTemplateSharedMutation = getFixedCacheKeyMutation(
  useUpdateProposalTemplateMutation,
  UPDATE_PROPOSAL_TEMPLATE_MUTATION_FIXED_CACHE_KEY,
);

export const useDeleteProposalTemplateSharedMutation = getFixedCacheKeyMutation(
  useDeleteProposalTemplateMutation,
  'deleteProposalTemplate',
);
