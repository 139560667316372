import { getFixedCacheKeyMutation } from '../hooks/getFixedCacheKeyMutation';
import {
  APICreateProposalData,
  CreateProposalData,
} from '../models/CreateProposal';
import { APIProposal, Proposal } from '../models/Proposal';
import { easysolarAPI } from './easysolarAPI';

export const proposalAPI = easysolarAPI.injectEndpoints({
  endpoints: (builder) => ({
    createProposal: builder.mutation<Proposal, CreateProposalData>({
      query: ({
        projectId,
        layoutName,
        layoutVersion,
        proposalTemplateId,
        layoutData,
      }) => {
        const body: APICreateProposalData = {
          project: projectId,
          layout_name: layoutName,
          layout_version: layoutVersion,
          proposal_template: proposalTemplateId,
          layout_data: layoutData,
        };

        return {
          url: '/proposals',
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: APIProposal): Proposal => ({
        id: response.id,
        project: response.project,
        pdfFile: response.pdf_file,
      }),
    }),
  }),
});

const { useCreateProposalMutation } = proposalAPI;

export const useCreateProposalSharedMutation = getFixedCacheKeyMutation(
  useCreateProposalMutation,
  'createProposal',
);
