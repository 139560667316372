import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FunctionComponent, useMemo } from 'react';

import { getProposalEditorSideBarAccordionStyles } from './Accordion.styles';

export interface ProposalEditorSideBarAccordionProps {
  title: string;
  icon?: JSX.Element;
  expanded?: boolean;
  onExpand?: AccordionProps['onChange'];
  disabled?: boolean;
  error?: boolean;
}

export const ProposalEditorSideBarAccordion: FunctionComponent<ProposalEditorSideBarAccordionProps> =
  ({ title, icon, expanded, onExpand, children, disabled, error }) => {
    const classes = getProposalEditorSideBarAccordionStyles({ error });

    const iconContainer = useMemo(
      () => icon && <Box className={classes.iconContainer}>{icon}</Box>,
      [classes.iconContainer, icon],
    );

    const summary = useMemo(
      () => (
        <AccordionSummary
          classes={{
            content: error ? classes.summaryContentError : undefined,
            expandIcon: error ? classes.summaryContentError : undefined,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          {iconContainer}
          <Typography>{title}</Typography>
          {error && <ErrorIcon className={classes.errorIcon} />}
        </AccordionSummary>
      ),
      [
        classes.errorIcon,
        classes.summaryContentError,
        error,
        iconContainer,
        title,
      ],
    );

    const details = useMemo(
      () => <AccordionDetails>{children}</AccordionDetails>,
      [children],
    );

    const accordion = useMemo(
      () => (
        <Accordion
          classes={{
            root: error ? classes.accordionError : undefined,
          }}
          expanded={expanded}
          onChange={onExpand}
          disabled={disabled}
        >
          {summary}
          {details}
        </Accordion>
      ),
      [
        classes.accordionError,
        details,
        disabled,
        error,
        expanded,
        onExpand,
        summary,
      ],
    );

    return accordion;
  };
