import { FunctionComponent, useMemo } from 'react';

import { useFormControl } from '../../forms/hooks/useFormControl';
import { FieldWrapperProps } from '../ControlledField';
import { ToggleableText, ToggleableTextField } from '../ToggleableTextField';
import { TextFieldErrorType } from '../TypedErrorTextField';

export interface ToggleableTextFieldWrapperProps
  extends FieldWrapperProps<
    ToggleableText,
    Record<TextFieldErrorType, unknown>
  > {
  label: string;
  maxLength?: number;
}

export const ToggleableTextFieldWrapper: FunctionComponent<ToggleableTextFieldWrapperProps> =
  ({ value, onChange, label, maxLength, formControl }) => {
    const { errors } = useFormControl(formControl);

    const textErrorType = useMemo(
      () => Object.keys(errors)[0] as TextFieldErrorType,
      [errors],
    );

    return (
      <ToggleableTextField
        value={value}
        onChange={onChange}
        label={label}
        maxLength={maxLength}
        textErrorType={textErrorType}
      />
    );
  };
