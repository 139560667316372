import { PropTypes, Typography } from '@material-ui/core';
import { CSSProperties, FunctionComponent } from 'react';

import { ToggleableText } from '@easysolar/proposals/shared/components/ToggleableTextField';

import { getProposalPreviewDefaultSectionPageFooterTextStyles } from './FooterText.styles';

export interface ProposalPreviewDefaultSectionPageFooterTextProps {
  toggleableText: ToggleableText;
  align?: PropTypes.Alignment;
  color?: CSSProperties['color'];
  fontWeight?: CSSProperties['fontWeight'];
}

export const ProposalPreviewDefaultSectionPageFooterText: FunctionComponent<ProposalPreviewDefaultSectionPageFooterTextProps> =
  ({ toggleableText, align = 'left', color, fontWeight = 'normal' }) => {
    const classes = getProposalPreviewDefaultSectionPageFooterTextStyles({
      color,
      fontWeight,
    });

    return (
      <Typography className={classes.root} variant="caption" align={align}>
        {toggleableText.enabled ? toggleableText.text : null}
      </Typography>
    );
  };
