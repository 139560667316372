import { Popover, PopoverProps } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { useShadowRootContainer } from '@easysolar/proposals/infrastructure/hooks/useShadowRootContainer';

export const ShadowRootPopover: FunctionComponent<PopoverProps> = ({
  children,
  container,
  ...props
}) => {
  const shadowRootContainerRef = useShadowRootContainer();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Popover {...props} container={container || shadowRootContainerRef}>
      {children}
    </Popover>
  );
};
