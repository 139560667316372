import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';

import {
  ProposalPreviewDefaultSectionPage,
  ProposalPreviewDefaultSectionPageProps,
} from '../SectionPage';
import { getProposalPreviewDefaultSectionMultiPageStyles } from './SectionMultiPage.styles';

export type ProposalPreviewDefaultSectionMultiPageProps =
  ProposalPreviewDefaultSectionPageProps;

interface Page {
  children: React.ReactElement[];
}

export const ProposalPreviewDefaultSectionMultiPage: FunctionComponent<ProposalPreviewDefaultSectionMultiPageProps> =
  ({ children, ...props }) => {
    const classes = getProposalPreviewDefaultSectionMultiPageStyles();

    const contentContainerRef = useRef<HTMLDivElement | null>(null);

    const childrenRefs = useRef<Array<HTMLDivElement | null>>([]);

    useEffect(() => {
      const count = React.Children.count(children);
      childrenRefs.current = childrenRefs.current.slice(0, count);
    }, [children]);

    const [pages, setPages] = useState<Page[]>([]);

    const [isMeasuring, setIsMeasuring] = useState(true);

    useEffect(() => {
      // start measuring whenever the children change
      setIsMeasuring(true);
    }, [children]);

    useEffect(() => {
      if (isMeasuring) {
        const contentContainerHeight =
          contentContainerRef.current?.getBoundingClientRect()?.height || 0;

        const [newPages] = React.Children.toArray(children).reduce<
          [Page[], number]
        >(
          ([result, currentHeight], child, index) => {
            const childContainer = childrenRefs.current[index];

            if (child && childContainer) {
              const childHeight =
                childContainer?.getBoundingClientRect()?.height || 0;

              let newCurrentHeight = currentHeight + childHeight;
              if (newCurrentHeight > contentContainerHeight) {
                result.push({ children: [] });
                newCurrentHeight = childHeight;
              }

              const currentPage = result[result.length - 1];
              currentPage.children.push(child as React.ReactElement);

              return [result, newCurrentHeight];
            }

            return [result, currentHeight];
          },
          [[{ children: [] }], 0],
        );

        setPages(newPages);
        setIsMeasuring(false);
      }
    }, [children, isMeasuring]);

    const measurementPage = useMemo(
      () =>
        isMeasuring && (
          <div ref={contentContainerRef} className={classes.contentContainer}>
            <div className={classes.content}>
              {React.Children.map(children, (child, index) => (
                <div
                  ref={(element): void => {
                    childrenRefs.current[index] = element;
                  }}
                  className={classes.childContainer}
                >
                  {child}
                </div>
              ))}
            </div>
          </div>
        ),
      [
        children,
        classes.childContainer,
        classes.content,
        classes.contentContainer,
        isMeasuring,
      ],
    );

    const calculatedFirstPage = useMemo(
      () => !isMeasuring && pages[0] && pages[0].children,
      [isMeasuring, pages],
    );

    const firstPage = useMemo(
      () => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ProposalPreviewDefaultSectionPage {...props}>
          {measurementPage}
          {calculatedFirstPage}
        </ProposalPreviewDefaultSectionPage>
      ),
      [calculatedFirstPage, measurementPage, props],
    );

    const followingPages = useMemo(
      () =>
        pages.slice(1).map((page, index) => (
          // eslint-disable-next-line react/no-array-index-key, react/jsx-props-no-spreading
          <ProposalPreviewDefaultSectionPage key={index + 1} {...props}>
            {React.Children.map(page.children, (child) => child)}
          </ProposalPreviewDefaultSectionPage>
        )),
      [pages, props],
    );

    return (
      <>
        {firstPage}
        {followingPages}
      </>
    );
  };
