import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from 'react';

interface Props {
  isNewTemplateSelected: boolean;
}

export const getProposalEditorToolbarTemplatesSectionStyles = makeStyles<
  Theme,
  Props
>((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  select: {
    width: 300,
    color: ({ isNewTemplateSelected }): CSSProperties['color'] => {
      const { primary, secondary } = theme.palette.text;

      return isNewTemplateSelected ? secondary : primary;
    },
  },
  newTemplateMenuItem: {
    color: theme.palette.text.secondary,
  },
}));
