import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AbstractFormControl,
  AbstractFormControlResult,
  AbstractFormControlState,
  AbstractFormControlUpdateValueOptions,
} from '../models';

function getAbstractFormControlState<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
>(
  abstractFormControl: AbstractFormControl<T, E>,
): AbstractFormControlState<T, E> {
  return {
    value: abstractFormControl.value,
    errors: abstractFormControl.errors,
    valid: abstractFormControl.valid,
    dirty: abstractFormControl.dirty,
  };
}

export function useAbstractFormControl<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
>(
  abstractFormControl: AbstractFormControl<T, E>,
): AbstractFormControlResult<T, E> {
  const [state, setState] = useState<AbstractFormControlState<T, E>>(
    getAbstractFormControlState(abstractFormControl),
  );

  useEffect(() => {
    const subscription = abstractFormControl.stateChanges.subscribe(() => {
      setState(getAbstractFormControlState(abstractFormControl));
    });

    return (): void => subscription.unsubscribe();
  }, [abstractFormControl]);

  const setValue = useCallback(
    (newValue?: T, options?: AbstractFormControlUpdateValueOptions) =>
      abstractFormControl.setValue(newValue, options),
    [abstractFormControl],
  );

  const patchValue = useCallback(
    (newValue?: Partial<T>, options?: AbstractFormControlUpdateValueOptions) =>
      abstractFormControl.patchValue(newValue, options),
    [abstractFormControl],
  );

  const reset = useCallback(
    (newValue?: Partial<T>, options?: AbstractFormControlUpdateValueOptions) =>
      abstractFormControl.reset(newValue, options),
    [abstractFormControl],
  );

  const result = useMemo<AbstractFormControlResult<T, E>>(
    () => ({
      ...state,
      setValue,
      patchValue,
      reset,
    }),
    [state, setValue, patchValue, reset],
  );

  return result;
}
