import { SvgIcon } from '@material-ui/core';
import { ReactElement, useCallback } from 'react';

import { ProposalEditorToolbarTemplatesSectionConfirmDialog } from './ConfirmDialog';
import {
  useProposalTemplateAction,
  UseProposalTemplateActionProps,
} from './useProposalTemplateAction';

interface UseProposalTemplateConfirmActionProps {
  button: {
    tooltip: string;
    IconComponent: typeof SvgIcon;
  };
  dialog: {
    title: string;
    confirmButtonLabel: string;
  };
  disabled: boolean;
  loading: boolean;
  message: ReactElement;
  onConfirm: () => void;
}

interface UseProposalTemplateNameActionResult {
  button: ReactElement;
}

export function useProposalTemplateConfirmAction({
  message,
  ...props
}: UseProposalTemplateConfirmActionProps): UseProposalTemplateNameActionResult {
  const renderDialog: UseProposalTemplateActionProps<string>['dialog']['render'] =
    useCallback(
      (dialogProps) => (
        <ProposalEditorToolbarTemplatesSectionConfirmDialog
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dialogProps}
          message={message}
        />
      ),
      [message],
    );

  return useProposalTemplateAction({
    ...props,
    dialog: {
      ...props.dialog,
      render: renderDialog,
    },
  });
}
