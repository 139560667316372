import { useCallback, useMemo, useState } from 'react';

interface UseDownloadFileResult {
  downloadFile: (fileURL: string) => Promise<void>;
  loading: boolean;
  contentLength?: number;
  receivedLength?: number;
  progress?: number;
}

export function useDownloadFile(): UseDownloadFileResult {
  const [loading, setLoading] = useState(false);
  const [contentLength, setContentLength] = useState<number>();
  const [receivedLength, setReceivedLength] = useState<number>();

  const saveBlob = useCallback((blobURL: string, filename: string) => {
    const linkElement = document.createElement('a');
    linkElement.download = filename;
    linkElement.href = blobURL;

    document.body.append(linkElement);

    linkElement.click();
    linkElement.remove();
  }, []);

  const downloadFile = useCallback(
    async (fileURL) => {
      setLoading(true);

      const response = await fetch(fileURL);

      const reader = response.body?.getReader();
      const chunks = [];
      setContentLength(+(response.headers.get('Content-Length') || 0));

      if (reader) {
        let internalReceivedLength = 0;

        // eslint-disable-next-line no-constant-condition
        while (true) {
          // eslint-disable-next-line no-await-in-loop
          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          if (value) {
            chunks.push(value);

            if (value?.length) {
              internalReceivedLength += value.length;
              setReceivedLength(internalReceivedLength);
            }
          }
        }
      }

      const blob = new Blob(chunks);
      const blobURL = URL.createObjectURL(blob);

      setLoading(false);
      setContentLength(undefined);
      setReceivedLength(undefined);

      const filename = fileURL.split('/').pop();

      saveBlob(blobURL, filename);
    },
    [saveBlob],
  );

  const progress = useMemo(
    () =>
      contentLength && receivedLength
        ? receivedLength / contentLength
        : undefined,
    [contentLength, receivedLength],
  );

  return { downloadFile, loading, contentLength, receivedLength, progress };
}
