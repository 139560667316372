import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultCoverPageClientDetailsStyles = makeStyles(
  (theme) => ({
    root: {
      paddingBottom: '7mm',
    },
    label: {
      textTransform: 'uppercase',
    },
    value: {
      textAlign: 'end',
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
);
