import { Box, Grid } from '@material-ui/core';
import { FunctionComponent } from 'react';
import * as React from 'react';

import { getProposalPreviewDefaultProgressBarStyles } from './ProgressBar.styles';

export interface ProposalPreviewDefaultProgressBarProps {
  progress: number;
  color?: string;
  leftLabel?: React.ReactElement;
  rightLabel?: React.ReactElement;
  className?: string;
  barHeight?: number;
}

export const ProposalPreviewDefaultProgressBar: FunctionComponent<ProposalPreviewDefaultProgressBarProps> =
  ({ progress, color, leftLabel, rightLabel, className, barHeight }) => {
    const classes = getProposalPreviewDefaultProgressBarStyles({
      progress,
      color,
      barHeight,
    });

    return (
      <Grid container direction="column" className={className}>
        <Grid
          className={classes.labels}
          item
          container
          justifyContent="space-between"
          wrap="nowrap"
        >
          {leftLabel && (
            <Grid item container alignItems="flex-end">
              {leftLabel}
            </Grid>
          )}
          {rightLabel && (
            <Grid
              item
              container
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              {rightLabel}
            </Grid>
          )}
        </Grid>
        <Box className={classes.background}>
          <Box className={classes.progress} />
        </Box>
      </Grid>
    );
  };
