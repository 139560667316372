import { Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { getProposalPreviewDefaultCustomerProposalCaptionStyles } from './Caption.styles';

export interface ProposalPreviewDefaultCustomerProposalCaptionProps {
  caption: string;
}

export const ProposalPreviewDefaultCustomerProposalCaption: FunctionComponent<ProposalPreviewDefaultCustomerProposalCaptionProps> =
  ({ caption }) => {
    const classes = getProposalPreviewDefaultCustomerProposalCaptionStyles();

    return (
      <Typography component="div" variant="caption" className={classes.root}>
        {caption}
      </Typography>
    );
  };
