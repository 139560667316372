import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultSectionMultiPageStyles = makeStyles(
  () => ({
    contentContainer: {
      position: 'relative',
      height: '100%',
    },
    content: { position: 'absolute', visibility: 'hidden' },
    childContainer: { overflow: 'overlay' },
  }),
);
