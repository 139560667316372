import { Grid } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

import { ProposalLayoutSettingToggleableText } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Settings/ToggleableText';

import { ProposalPreviewDefaultSubsection } from '../../../Shared/Subsection';
import { ProposalPreviewDefaultEnergyProductionChart } from './Chart';
import { getProposalPreviewDefaultEnergyProductionStyles } from './EnergyProduction.styles';
import { ProposalPreviewDefaultEnergyProductionIrradiation } from './Irradiation';

export interface ProposalPreviewDefaultEnergyProductionProps {
  title: string;
  source: ProposalLayoutSettingToggleableText;
}

export const ProposalPreviewDefaultEnergyProduction: FunctionComponent<ProposalPreviewDefaultEnergyProductionProps> =
  ({ title, source }) => {
    const classes = getProposalPreviewDefaultEnergyProductionStyles();

    const irradiation = useMemo(
      () => (
        <Grid item className={classes.irradiation}>
          <ProposalPreviewDefaultEnergyProductionIrradiation source={source} />
        </Grid>
      ),
      [classes.irradiation, source],
    );

    const chart = useMemo(
      () => (
        <Grid item className={classes.chart}>
          <ProposalPreviewDefaultEnergyProductionChart />
        </Grid>
      ),
      [classes.chart],
    );

    const subsection = useMemo(
      () => (
        <ProposalPreviewDefaultSubsection title={title}>
          <Grid container direction="row" wrap="nowrap">
            {irradiation}
            {chart}
          </Grid>
        </ProposalPreviewDefaultSubsection>
      ),
      [chart, irradiation, title],
    );

    return subsection;
  };
