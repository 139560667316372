import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultCustomerProposalSummaryStyles =
  makeStyles((theme) => ({
    container: {
      marginTop: '4mm',
    },
    comment: {
      width: '45%',
      padding: '2mm 0',
    },
    items: {
      width: '45%',
    },
    itemContainer: {
      width: '100%',
    },
    item: {
      padding: '2mm 0',
    },
    totalPrice: {
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
  }));
