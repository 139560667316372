import { Typography } from '@material-ui/core';
import { FunctionComponent, ReactElement, useMemo } from 'react';

import { ProposalEditorToolbarTemplatesSectionDialog } from './Dialog';

interface ProposalEditorToolbarTemplatesSectionConfirmDialogProps {
  open: boolean;
  title: string;
  message: ReactElement;
  confirmButtonLabel: string;
  onClose?: () => void;
  onConfirm?: () => void;
}

export const ProposalEditorToolbarTemplatesSectionConfirmDialog: FunctionComponent<ProposalEditorToolbarTemplatesSectionConfirmDialogProps> =
  ({ open, title, message, confirmButtonLabel, onClose, onConfirm }) => {
    const text = useMemo(() => <Typography>{message}</Typography>, [message]);

    return (
      <ProposalEditorToolbarTemplatesSectionDialog
        open={open}
        title={title}
        content={text}
        confirmButtonLabel={confirmButtonLabel}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    );
  };
