import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultProposedSystemRecommendedSizeOfPVSystemStyles =
  makeStyles((theme) => ({
    leftColumn: {
      flex: '30% 0',
      padding: '3% 4% 3% 0',
    },
    rightColumn: {
      flex: '70%',
      padding: '3% 0 3% 4%',
    },
    nominalPower: {
      paddingBottom: '8mm',
    },
    surfaceAreaLabel: {
      flexBasis: '20%',
      flexGrow: 0,
      color: theme.palette.text.secondary,
    },
    annualEnergyProduction: {
      paddingBottom: '8mm',
    },
  }));
