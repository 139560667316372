import { FunctionComponent, useMemo } from 'react';

import { UnitNumberFormat, UnitNumberFormatProps } from '../UnitNumberFormat';

export interface CurrencyNumberFormatProps
  extends Omit<UnitNumberFormatProps, 'options' | 'unit'> {
  currency: string;
  additionalUnit?: string;
}

export const CurrencyNumberFormat: FunctionComponent<CurrencyNumberFormatProps> =
  ({ currency, additionalUnit, ...props }) => {
    const options: Intl.NumberFormatOptions = useMemo(
      () => (currency ? { style: 'currency', currency } : { style: 'decimal' }),
      [currency],
    );

    const unit = useMemo(
      () => (additionalUnit ? `/${additionalUnit}` : undefined),
      [additionalUnit],
    );

    return (
      <UnitNumberFormat
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        options={options}
        unit={unit}
        addWhitespace={false}
      />
    );
  };
