import {
  BaseQueryFn,
  BaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { CreateProposalImageResult } from '../models/CreateProposaImage';

export const createProposalImageMock = async (
  meta: BaseQueryMeta<BaseQueryFn>,
): Promise<CreateProposalImageResult> => {
  const { request } = meta as { request: Request };

  let body = '';
  const uint8Array = new Uint8Array(await request.arrayBuffer());
  for (let i = 0; i < uint8Array.byteLength; i += 1) {
    body += String.fromCharCode(uint8Array[i]);
  }

  const bodyParts = body.split('\r\n');

  const contentDispositionPart = bodyParts[1];
  const filename = contentDispositionPart.split('"').slice(-2)[0];

  const contentTypePart = bodyParts[2];
  const contentType = contentTypePart.split(': ').slice(-1)[0];

  const contentParts = bodyParts.slice(4, -2);
  const content = contentParts.join('\r\n');
  const encodedContent = Uint8Array.from(
    [...content].map((char) => char.charCodeAt(0)),
  ).buffer;
  const file = new File([encodedContent] as BlobPart[], filename, {
    type: contentType,
  });
  const fileURL = URL.createObjectURL(file);

  return {
    id: crypto.randomUUID(),
    file: fileURL,
    name: filename,
    created: new Date().toISOString(),
  };
};
