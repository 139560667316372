import { Box } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';

import { ProposalPreviewDefaultSubsection } from '../../../Shared/Subsection';
import { ProposalPreviewDefaultIcon } from '../../../Shared/SvgIcon';
import { getProposalPreviewDefaultEnvironmentalImpactStyles } from './EnvironmentalImpact.styles';
import {
  EnvironmentalImpactTableItem,
  ProposalPreviewDefaultEnvironmentalImpactTable,
} from './Table';

export interface ProposalPreviewDefaultEnvironmentalImpactProps {
  title: string;
}

export const ProposalPreviewDefaultEnvironmentalImpact: FunctionComponent<ProposalPreviewDefaultEnvironmentalImpactProps> =
  ({ title }) => {
    const { t } = useTranslation();

    const { project } = useProposalContext();

    const { environmentalImpact } = project;

    const classes = getProposalPreviewDefaultEnvironmentalImpactStyles();

    const benefitsItems: EnvironmentalImpactTableItem[] = useMemo(
      () =>
        [
          {
            label: {
              icon: ProposalPreviewDefaultIcon.SunPower,
              text: `${t(
                'Layouts:Default.Sections.EnergyProductionAndEnvironmentalImpact.Subsections.EnvironmentalImpact.EnergyProductionLabel',
              )} [kWh]`,
            },
            values: environmentalImpact.annualEnergyProduction,
          },
          {
            label: {
              icon: ProposalPreviewDefaultIcon.ElectricCar,
              text: `${t(
                'Layouts:Default.Sections.EnergyProductionAndEnvironmentalImpact.Subsections.EnvironmentalImpact.ElectricCarDistanceLabel',
              )} [km]`,
            },
            values:
              environmentalImpact.annualEnergyProductionAsElectricCarDistance,
          },
          {
            label: {
              icon: ProposalPreviewDefaultIcon.Fuel,
              text: `${t(
                'Layouts:Default.Sections.EnergyProductionAndEnvironmentalImpact.Subsections.EnvironmentalImpact.SavedFuelLabel',
              )} [l]`,
            },
            values: environmentalImpact.annualEnergyProductionAsSavedFuel,
          },
        ] as EnvironmentalImpactTableItem[],
      [
        t,
        environmentalImpact.annualEnergyProduction,
        environmentalImpact.annualEnergyProductionAsElectricCarDistance,
        environmentalImpact.annualEnergyProductionAsSavedFuel,
      ],
    );

    const negativeImpactItems: EnvironmentalImpactTableItem[] = useMemo(
      () =>
        [
          {
            label: {
              icon: ProposalPreviewDefaultIcon.AirPollution,
              text: 'CO² [kg]',
            },
            values:
              environmentalImpact.annualEnergyProductionAsAvoidedCO2Emission,
          },
          {
            label: {
              icon: ProposalPreviewDefaultIcon.Sewage,
              text: 'NOx, SOx [kg]',
            },
            values:
              environmentalImpact.annualEnergyProductionAsAvoidedNOxSOxEmission,
          },
          {
            label: {
              icon: ProposalPreviewDefaultIcon.Trees,
              text: t(
                'Layouts:Default.Sections.EnergyProductionAndEnvironmentalImpact.Subsections.EnvironmentalImpact.NumberOfTreesLabel',
              ),
            },
            values:
              environmentalImpact.annualEnergyProductionAsNumberOfTreesPlanted,
          },
        ] as EnvironmentalImpactTableItem[],
      [
        environmentalImpact.annualEnergyProductionAsAvoidedCO2Emission,
        environmentalImpact.annualEnergyProductionAsAvoidedNOxSOxEmission,
        environmentalImpact.annualEnergyProductionAsNumberOfTreesPlanted,
        t,
      ],
    );

    const benefitsTable = useMemo(
      () => (
        <ProposalPreviewDefaultEnvironmentalImpactTable
          labelsHeader={t(
            'Layouts:Default.Sections.EnergyProductionAndEnvironmentalImpact.Subsections.EnvironmentalImpact.BenefitsHeader',
          )}
          items={benefitsItems}
        />
      ),
      [benefitsItems, t],
    );

    const negativeImpactTable = useMemo(
      () => (
        <Box className={classes.negativeImpactTable}>
          <ProposalPreviewDefaultEnvironmentalImpactTable
            labelsHeader={t(
              'Layouts:Default.Sections.EnergyProductionAndEnvironmentalImpact.Subsections.EnvironmentalImpact.NegativeImpactHeader',
            )}
            items={negativeImpactItems}
          />
        </Box>
      ),
      [classes.negativeImpactTable, negativeImpactItems, t],
    );

    const subsection = useMemo(
      () => (
        <ProposalPreviewDefaultSubsection title={title}>
          {benefitsTable}
          {negativeImpactTable}
        </ProposalPreviewDefaultSubsection>
      ),
      [benefitsTable, negativeImpactTable, title],
    );

    return subsection;
  };
