import { Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { ProposalCurrencyNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalCurrencyNumberFormat';

import {
  ProposalPreviewDefaultProgressBar,
  ProposalPreviewDefaultProgressBarProps,
} from '../../../../Shared/ProgressBar';

export interface ProposalPreviewDefaultInvestmentCostsEnergyCostsProgressBarProps
  extends Omit<
    ProposalPreviewDefaultProgressBarProps,
    'leftLabel' | 'rightLabel' | 'barHeight'
  > {
  label: string;
  value: number;
}

export const ProposalPreviewDefaultInvestmentCostsEnergyCostsProgressBar: FunctionComponent<ProposalPreviewDefaultInvestmentCostsEnergyCostsProgressBarProps> =
  ({ label, value, ...props }) => {
    return (
      <ProposalPreviewDefaultProgressBar
        leftLabel={
          <Typography component="span" variant="body2" color="textSecondary">
            {label}
          </Typography>
        }
        rightLabel={
          <ProposalCurrencyNumberFormat
            value={value}
            defaultProps={{
              variant: 'h5',
              color: 'textSecondary',
            }}
            partTypesProps={{
              currency: {
                variant: 'body2',
              },
            }}
          />
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  };
