import { ProposalLayoutSettingSchemaBoolean } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Settings/Boolean';
import { ControlledSwitchField } from '@easysolar/proposals/shared/components/ControlledSwitchField';

import { ProposalEditorSettingBase } from './Base';

export const ProposalEditorSettingBoolean: ProposalEditorSettingBase<ProposalLayoutSettingSchemaBoolean> =
  ({ formControl, settingSchema }) => {
    return (
      <ControlledSwitchField
        formControl={formControl}
        label={settingSchema.title}
      />
    );
  };
