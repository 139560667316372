import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultCustomerProposalRowStyles = makeStyles(
  (theme) => ({
    root: {
      marginBottom: '8mm',
    },
    header: {
      color: theme.palette.primary.contrastText,
      textTransform: 'uppercase',
    },
    valueCell: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    commentContainer: {
      paddingTop: '2mm',
    },
  }),
);
