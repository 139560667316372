import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from 'react';

interface ProposalEditorSideBarAccordionStylesProps {
  error?: boolean;
}

export const getProposalEditorSideBarAccordionStyles = makeStyles<
  Theme,
  ProposalEditorSideBarAccordionStylesProps
>((theme) => ({
  accordionError: {
    boxShadow: (): CSSProperties['boxShadow'] => {
      const warningColor = theme.palette.error.main;
      const width = '1px';

      return `inset ${width} ${width} 0px 0px ${warningColor}, inset -${width} -${width} 0px 0px ${warningColor}`;
    },
  },
  summaryContentError: {
    color: theme.palette.error.main,
  },
  errorIcon: {
    paddingLeft: theme.spacing(1),
  },
  iconContainer: {
    display: 'flex',
    paddingRight: theme.spacing(1),
  },
}));
