import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultCashFlowTableStyles = makeStyles(
  (theme) => ({
    valueCellContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0.8mm',
    },
    valueCell: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.secondary,
    },
    electricityBillWithPVCell: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
  }),
);
