import { Button, IconButton, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ShadowRootPopper } from '@easysolar/proposals/shared/components/ShadowRootMaterialUI/Popper';

interface GetLinkPopperProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  link: string;
}

export const GetLinkPopper: FunctionComponent<GetLinkPopperProps> = ({
  anchorEl,
  open,
  onClose,
  link,
}) => {
  const { t } = useTranslation();

  const [isCopied, setIsCopied] = useState(false);

  const message = useMemo(() => {
    return isCopied ? t('Editor:Toolbar.Popper.LinkCopiedMessage') : link;
  }, [isCopied, link, t]);

  const onCopyButtonClick = useCallback(() => {
    navigator.clipboard.writeText(link).then(() => {
      setIsCopied(true);
    });
  }, [link]);

  const copyButton = useMemo(
    () => (
      <Button color="primary" size="small" onClick={onCopyButtonClick}>
        {t('Editor:Toolbar.Popper.CopyLinkLabel')}
      </Button>
    ),
    [onCopyButtonClick, t],
  );

  const closeButton = useMemo(
    () => (
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    ),
    [onClose],
  );

  return (
    <ShadowRootPopper anchorEl={anchorEl} placement="bottom-end" open={open}>
      <SnackbarContent
        message={message}
        action={
          <>
            {copyButton}
            {closeButton}
          </>
        }
      />
    </ShadowRootPopper>
  );
};
