import { Box, Paper } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

import { usePrintingContext } from '../../hooks/usePrintingContext';
import { getProposalPreviewPageStyles } from './Page.styles';

export interface ProposalPreviewPageProps {
  backgroundImageURL?: string;
  // in millimetres
  margins?: ProposalPreviewPageMargins;
}

type ProposalPreviewPageMargins =
  | number
  | [number]
  | [number, number]
  | [number, number, number]
  | [number, number, number, number];

export const ProposalPreviewPage: FunctionComponent<ProposalPreviewPageProps> =
  ({ children, backgroundImageURL, margins = 5 }) => {
    const { printing } = usePrintingContext();

    const normalizedMargins: [number, number, number, number] = useMemo(() => {
      const marginsArray =
        typeof margins === 'number'
          ? [margins]
          : (margins as
              | [number]
              | [number, number]
              | [number, number, number]
              | [number, number, number, number]);

      switch (marginsArray.length) {
        case 1: {
          const marginAll = marginsArray[0];
          return [marginAll, marginAll, marginAll, marginAll];
        }

        case 2: {
          const [marginTopBottom, marginLeftRight] = marginsArray;
          return [
            marginTopBottom,
            marginLeftRight,
            marginTopBottom,
            marginLeftRight,
          ];
        }

        case 3: {
          const [marginTop, marginLeftRight, marginBottom] = marginsArray;
          return [marginTop, marginLeftRight, marginBottom, marginLeftRight];
        }

        case 4:
        default: {
          return [
            marginsArray[0],
            marginsArray[1],
            marginsArray[2],
            marginsArray[3],
          ];
        }
      }
    }, [margins]);

    const classes = getProposalPreviewPageStyles({
      printing,
      margins: normalizedMargins,
      backgroundImageURL,
    });

    const page = useMemo(
      () => (
        <Paper elevation={4} className={classes.paper}>
          <Box className={classes.background}>
            <Box className={classes.container}>{children}</Box>
          </Box>
        </Paper>
      ),
      [children, classes.background, classes.container, classes.paper],
    );

    return page;
  };
