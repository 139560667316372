import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PROPOSAL_TEMPLATE_NAME_MAX_LENGTH } from '@easysolar/proposals/modules/ProposalEditor/models/ProposalTemplate';
import { ControlledTextField } from '@easysolar/proposals/shared/components/ControlledTextField';
import { getTextFieldValidators } from '@easysolar/proposals/shared/components/TypedErrorTextField/TextFieldErrorType';
import { useFormControl } from '@easysolar/proposals/shared/forms/hooks/useFormControl';
import { FormControl } from '@easysolar/proposals/shared/forms/models';

import { ProposalEditorToolbarTemplatesSectionDialog } from '../Dialog';
import { getProposalEditorToolbarTemplatesSectionNameDialogStyles } from './NameDialog.styles';

interface ProposalEditorToolbarTemplatesSectionNameDialogProps {
  open: boolean;
  title: string;
  confirmButtonLabel: string;
  initialTemplateName: string;
  onClose?: () => void;
  onConfirm?: (templateName: string) => void;
}

export const ProposalEditorToolbarTemplatesSectionNameDialog: FunctionComponent<ProposalEditorToolbarTemplatesSectionNameDialogProps> =
  ({
    open,
    title,
    confirmButtonLabel,
    initialTemplateName,
    onClose,
    onConfirm,
  }) => {
    const { t } = useTranslation();

    const classNames =
      getProposalEditorToolbarTemplatesSectionNameDialogStyles();

    const formControl = useMemo(
      () =>
        new FormControl({
          initialValue: '',
          validators: [
            getTextFieldValidators({
              required: true,
              maxLength: PROPOSAL_TEMPLATE_NAME_MAX_LENGTH,
            }),
          ],
        }),
      [],
    );

    const {
      value: templateName,
      setValue: setTemplateName,
      valid,
    } = useFormControl(formControl);

    useEffect(() => {
      setTemplateName(initialTemplateName);
    }, [initialTemplateName, setTemplateName]);

    const onSaveButtonClick = useCallback(() => {
      onConfirm?.(templateName);
    }, [onConfirm, templateName]);

    const textField = useMemo(
      () => (
        <ControlledTextField
          classes={{
            root: classNames.textField,
          }}
          label={t('Editor:Toolbar.Templates.NameDialog.NameLabel')}
          formControl={formControl}
          maxLength={PROPOSAL_TEMPLATE_NAME_MAX_LENGTH}
        />
      ),
      [classNames.textField, formControl, t],
    );

    return (
      <ProposalEditorToolbarTemplatesSectionDialog
        open={open}
        title={title}
        content={textField}
        confirmButtonLabel={confirmButtonLabel}
        confirmButtonDisabled={!valid}
        onClose={onClose}
        onConfirm={onSaveButtonClick}
      />
    );
  };
