import { OutlinedTextFieldProps } from '@material-ui/core';
import { ChangeEvent, FunctionComponent, useCallback, useMemo } from 'react';

import { useFormControl } from '../../forms/hooks/useFormControl';
import { HTMLInputType } from '../../models/Fields';
import { FieldWrapperProps } from '../ControlledField';
import {
  TextFieldErrorType,
  TypedErrorTextField,
} from '../TypedErrorTextField';

export interface TextFieldWrapperProps
  extends FieldWrapperProps<string, Record<TextFieldErrorType, unknown>> {
  label?: string;
  type?: HTMLInputType;
  maxLength?: number;
  classes?: OutlinedTextFieldProps['classes'];
}

export const TextFieldWrapper: FunctionComponent<TextFieldWrapperProps> = ({
  label,
  type,
  maxLength,
  value,
  onChange,
  formControl,
  classes,
}) => {
  const { errors } = useFormControl(formControl);

  const errorType = useMemo(
    () => Object.keys(errors)[0] as TextFieldErrorType,
    [errors],
  );

  const onTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <TypedErrorTextField
      label={label}
      type={type}
      maxLength={maxLength}
      value={value}
      onChange={onTextFieldChange}
      errorType={errorType}
      classes={classes}
    />
  );
};
