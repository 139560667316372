import { FormControl, FormControlLabel, Typography } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

import { FieldWrapperProps } from '../ControlledField';
import { SwitchField } from '../SwitchField';

export interface SwitchFieldWrapperProps extends FieldWrapperProps<boolean> {
  label: string;
}

export const SwitchFieldWrapper: FunctionComponent<SwitchFieldWrapperProps> = ({
  value,
  onChange,
  label,
}) => {
  const switchField = useMemo(
    () => <SwitchField value={value} onChange={onChange} />,
    [onChange, value],
  );

  const labelComponent = useMemo(
    () => <Typography color="textSecondary">{label}</Typography>,
    [label],
  );

  return (
    <FormControl margin="normal">
      <FormControlLabel
        labelPlacement="end"
        control={switchField}
        label={labelComponent}
      />
    </FormControl>
  );
};
