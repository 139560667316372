import {
  APIProposalContext,
  APIProposalContextValuationRow,
} from '../models/APIProposalContextData';
import {
  ProposalContextData,
  ProposalContextDataValuationRow,
} from '../models/ProposalContextData';

export class ProposalContextService {
  public static apiProposalContextToProposalContext(
    apiProposalContext: APIProposalContext,
  ): ProposalContextData {
    const { company, employee, client, project } = apiProposalContext;

    const { valuation, financial_analysis, environmental_impact } = project;

    const { forecast } = financial_analysis;

    return {
      company: {
        name: company.name,
        address: company.address,
        phoneNumber: company.phone_number,
        emailAddress: company.email_address,
        websiteAddress: company.website_address,
        logo: company.logo,
        color: company.color,
      },
      employee: {
        name: employee.name,
        emailAddress: employee.email_address,
        phoneNumber: employee.phone_number,
      },
      client: {
        name: client.name,
      },
      project: {
        address: project.address,
        location: {
          latitude: project.location.latitude,
          longitude: project.location.longitude,
        },
        currencyCode: project.currency_code,
        analysisPeriod: project.analysis_period,
        nominalPower: project.nominal_power,
        surfaceArea: project.surface_area,
        averageAnnualIrradiation: project.average_annual_irradiation,
        energyProductionByMonth: project.energy_production_by_month,
        totalPrice: project.total_price,
        designs: project.designs.map((design) => ({
          name: design.name,
          numberOfPanels: design.number_of_panels,
          nominalPower: design.nominal_power,
          visualization: design.visualization,
        })),
        valuation: {
          complex: valuation.complex,
          taxed: valuation.taxed,
          discounted: valuation.discounted,
          sections: {
            panels: ProposalContextService.apiValuationRowsToValuationRows(
              valuation.sections.panels,
            ),
            inverters: ProposalContextService.apiValuationRowsToValuationRows(
              valuation.sections.inverters,
            ),
            others: ProposalContextService.apiValuationRowsToValuationRows(
              valuation.sections.others,
            ),
          },
          summary: {
            netPrice: valuation.summary.net_price,
            taxSum: valuation.summary.tax_sum,
            grossPrice: valuation.summary.gross_price,
            discountSum: valuation.summary.discount_sum,
            totalPrice: valuation.summary.total_price,
            comment: valuation.summary.comment,
          },
        },
        financialAnalysis: {
          monthlyElectricityBill: financial_analysis.monthly_electricity_bill,
          annualElectricityBill: financial_analysis.annual_electricity_bill,
          priceOfElectricity: financial_analysis.price_of_electricity,
          annualPriceIncreaseOfElectricity:
            financial_analysis.annual_price_increase_of_electricity,
          ownContributionRatio: financial_analysis.own_contribution_ratio,
          loanTerm: financial_analysis.loan_term,

          forecast: {
            annualEnergyProduction: forecast.annual_energy_production,
            annualEnergyConsumption: forecast.annual_energy_consumption,
            annualEnergyFedIntoGrid: forecast.annual_energy_fed_into_grid,
            annualGridEnergyConsumption:
              forecast.annual_grid_energy_consumption,

            priceOfElectricityAfterAnalysisPeriod:
              forecast.price_of_electricity_after_analysis_period,
            upfrontCostsAfterIncentives:
              forecast.upfront_costs_after_incentives,
            savings: forecast.savings,
            receivedSubsidies: forecast.received_subsidies,
            averageAnnualSavings: forecast.average_annual_savings,
            electricityCostsWithoutPVSystem:
              forecast.electricity_costs_without_pv_system,
            electricityCostsWithPVSystem:
              forecast.electricity_costs_with_pv_system,
            savingsIncludingPVSystemCosts:
              forecast.savings_including_pv_system_costs,
            monthlyLoanPayment: forecast.monthly_loan_payment,

            energyProductionByYear: forecast.energy_production_by_year,
            savingsByYear: forecast.savings_by_year,
            receivedSubsidiesByYear: forecast.received_subsidies_by_year,
            loanPaymentsByYear: forecast.loan_payments_by_year,
            cumulativeCashFlow: forecast.cumulative_cash_flow,
            electricityBillsWithoutPVByYear:
              forecast.electricity_bills_without_pv_by_year,
            electricityBillsWithPVByYear:
              forecast.electricity_bills_with_pv_by_year,
          },
        },
        environmentalImpact: {
          annualCO2Emission: environmental_impact.annual_co2_emission,
          annualEnergyProduction: environmental_impact.annual_energy_production,
          annualEnergyProductionAsElectricCarDistance:
            environmental_impact.annual_energy_production_as_electric_car_distance,
          annualEnergyProductionAsSavedFuel:
            environmental_impact.annual_energy_production_as_saved_fuel,
          annualEnergyProductionAsAvoidedCO2Emission:
            environmental_impact.annual_energy_production_as_avoided_co2_emission,
          annualEnergyProductionAsAvoidedNOxSOxEmission:
            environmental_impact.annual_energy_production_as_avoided_nox_sox_emission,
          annualEnergyProductionAsNumberOfTreesPlanted:
            environmental_impact.annual_energy_production_as_number_of_trees_planted,
        },
      },
    };
  }

  private static apiValuationRowsToValuationRows(
    rows: APIProposalContextValuationRow[],
  ): ProposalContextDataValuationRow[] {
    return rows.map((row) => ({
      name: row.name,
      amount: row.amount,
      unit: row.unit,
      netUnitPrice: row.net_unit_price,
      tax: row.tax,
      discount: row.discount,
      netPrice: row.net_price,
      totalPrice: row.total_price,
      comment: row.comment,
    }));
  }
}
