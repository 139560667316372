import { useMemo } from 'react';

import { FormGroup } from '@easysolar/proposals/shared/forms/models';

import { ProposalLayoutAnyData } from '../../Common/models/LayoutData/Layout';
import { ProposalLayoutSchema } from '../../Common/models/LayoutSchema/Layout';
import { ProposalFormBuilder } from '../services/ProposalFormBuilder';

export function useProposalForm(
  layoutSchema: ProposalLayoutSchema,
): FormGroup<ProposalLayoutAnyData> {
  const proposalFormBuilder = useMemo(() => new ProposalFormBuilder(), []);

  const formGroup = useMemo(() => {
    return proposalFormBuilder.getProposalForm(layoutSchema);
  }, [layoutSchema, proposalFormBuilder]);

  return formGroup;
}
