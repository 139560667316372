import {
  Box,
  CircularProgress,
  Fade,
  Grid,
  InputBaseComponentProps,
  Typography,
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as React from 'react';
import { DropEvent, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { getImageFieldDropzoneStyles } from './ImageFieldDropzone.styles';

export interface ImageFieldDropzoneProps extends InputBaseComponentProps {
  value?: string;
  isLoading?: boolean;
  onChange?: React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
}

export const ImageFieldDropzone: FunctionComponent<ImageFieldDropzoneProps> = ({
  value,
  isLoading = false,
  onChange: onInputChange,
  onFocus: onInputFocus,
  onBlur: onInputBlur,
}) => {
  const classes = getImageFieldDropzoneStyles({ isLoading });

  const { t } = useTranslation();

  const [showOverlay, setShowOverlay] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const inputFileRef = useRef<HTMLInputElement>(null);
  const focus = useCallback(() => {
    if (onInputFocus) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onInputFocus(null as any);
    }
  }, [onInputFocus]);

  const blur = useCallback(() => {
    if (onInputBlur) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onInputBlur(null as any);
    }
  }, [onInputBlur]);

  const onDrop = useCallback(() => {
    setShowOverlay(false);
    blur();
  }, [blur]);

  const onDropAccepted = useCallback(
    (files: File[], event: DropEvent) => {
      const maxSize = 2 * 1024 * 1024;
      const file = files[0];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (file.size > maxSize) {
        setError(t('Shared:ImageField.OversizeError'));
        return;
      }

      if (
        fileExtension !== 'jpg' &&
        fileExtension !== 'jpeg' &&
        fileExtension !== 'png'
      ) {
        setError(t('Shared:ImageField.InvalidTypeError'));
        return;
      }

      setError(null);
      if (onInputChange) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onInputChange({ target: { ...event.target, files } } as any);
      }
    },
    [onInputChange, t],
  );

  const {
    getRootProps: getDropzoneRootProps,
    getInputProps: getDropzoneInputProps,
  } = useDropzone({
    accept: ['image/*'],
    multiple: false,
    onDragEnter: focus,
    onDragLeave: blur,
    onDrop,
    onDropAccepted,
    disabled: isLoading,
  });

  const dropzoneRootProps = useMemo(
    () => getDropzoneRootProps(),
    [getDropzoneRootProps],
  );

  const dropzoneInputProps = useMemo(
    () => getDropzoneInputProps(),
    [getDropzoneInputProps],
  );

  const onFocus = useCallback(
    (event) => {
      setShowOverlay(true);

      if (onInputFocus) {
        onInputFocus(event);
      }
    },
    [onInputFocus],
  );

  const onBlur = useCallback(
    (event) => {
      setShowOverlay(false);

      if (onInputBlur) {
        onInputBlur(event);
      }
    },
    [onInputBlur],
  );

  const onMouseEnter = useCallback(() => {
    setShowOverlay(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setShowOverlay(false);
  }, []);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];
      const maxSize = 2 * 1024 * 1024;

      if (file && file.size > maxSize) {
        setError(t('Shared:ImageField.OversizeError'));

        if (inputFileRef.current) {
          inputFileRef.current.value = '';
        }

        return;
      }

      setError(null);
      if (onInputChange) {
        onInputChange(event);
        setShowOverlay(false);
      }
    },
    [onInputChange, t],
  );

  const overlay = useMemo(
    () => (
      <Grid
        className={classes.overlay}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.dropText} align="center">
          {t('Shared:ImageField.OverlayText')}
        </Typography>
        <BackupIcon fontSize="large" />
      </Grid>
    ),
    [classes.dropText, classes.overlay, t],
  );

  return (
    <Box
      className={classes.root}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...dropzoneRootProps}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Grid
        className={classes.container}
        container
        justifyContent="center"
        alignItems="center"
      >
        <input
          ref={inputFileRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dropzoneInputProps}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onChange={onChange}
        />
        <img className={classes.image} src={value} alt="" />
        {isLoading ? (
          <CircularProgress className={classes.loader} />
        ) : (
          <>
            <Fade in={showOverlay}>{overlay}</Fade>
          </>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </Grid>
    </Box>
  );
};
