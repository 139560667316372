import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultSystemDesignVisualizationCharacteristicStyles =
  makeStyles((theme) => ({
    root: {
      flex: 1,
    },
    icon: {
      fontSize: '8mm',
      color: theme.palette.text.secondary,
    },
    label: {
      flex: '30% 0',
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightLight,
      lineHeight: 1,
    },
  }));
