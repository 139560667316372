import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultEnvironmentalImpactTableStyles =
  makeStyles((theme) => ({
    labelIcon: {
      width: '6mm',
      height: '6mm',
      color: theme.palette.text.primary,
    },
    labelText: {
      paddingLeft: '4mm',
      paddingRight: '12mm',
      lineHeight: 1,
      textAlign: 'start',
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.secondary,
    },
    valueCell: {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1,
    },
  }));
