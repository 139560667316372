import { Grid, Typography, TypographyProps } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { FunctionComponent, useMemo } from 'react';
import * as React from 'react';

import { getProposalPreviewDefaultProposedSystemElectricityBillFactStyles } from './Fact.styles';

export interface ProposalPreviewDefaultProposedSystemElectricityBillFactProps {
  first?: boolean;
  label: React.ReactElement;
  onValueRender: (defaultProps: Partial<TypographyProps>) => React.ReactElement;
}

export const ProposalPreviewDefaultProposedSystemElectricityBillFact: FunctionComponent<ProposalPreviewDefaultProposedSystemElectricityBillFactProps> =
  ({ first = false, label, onValueRender }) => {
    const classes =
      getProposalPreviewDefaultProposedSystemElectricityBillFactStyles({
        first,
      });

    const icon = useMemo(
      () => <ArrowRightIcon className={classes.icon} color="primary" />,
      [classes.icon],
    );

    const renderedLabel = useMemo(
      () => (
        <Typography component="span" variant="body2">
          {label}
          &nbsp;=&nbsp;
        </Typography>
      ),
      [label],
    );

    const value = useMemo(
      () =>
        onValueRender({
          className: classes.factValue,
          variant: 'body2',
        }),
      [classes.factValue, onValueRender],
    );

    const fact = useMemo(
      () => (
        <Grid
          className={classes.fact}
          item
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
        >
          {icon}
          <Typography component="div">
            {renderedLabel}
            {value}
          </Typography>
        </Grid>
      ),
      [classes.fact, icon, renderedLabel, value],
    );

    return fact;
  };
