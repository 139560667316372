import { APIProposalContext } from '../models/APIProposalContextData';

export const TEST_API_PROPOSAL_CONTEXT_DATA: APIProposalContext = {
  company: {
    name: 'EasySolar',
    address: 'Maraton Business Center, ul. Królowej Jadwigi 43, 61-871 Poznań',
    phone_number: '+48 570 620 200',
    email_address: 'support@easysolar-app.com',
    website_address: 'easysolar-app.com',
    logo: 'https://easysolar-api-development.s3.amazonaws.com/media/f58066a8-3a87-47b6-9dc6-74bb0a08cc8e/logos/logo.png',
    color: '#0099a1',
  },
  employee: {
    name: 'John Smith',
    email_address: 'john.smith@easysolar.app',
    phone_number: '+441224586868',
  },
  client: {
    name: 'Jan Kowalski',
  },
  project: {
    address: 'Wał Miedzeszyński 608, 03-994 Warszawa',
    location: {
      latitude: 52.545785,
      longitude: 19.707197,
    },
    currency_code: 'PLN',
    analysis_period: 20,
    nominal_power: 136240,
    surface_area: 573.5134,
    average_annual_irradiation: 1005.66,
    energy_production_by_month: [
      4578.84560355823, 6754.14391800771, 11245.8755913521, 13529.34324461,
      17799.6484136352, 16411.4053585383, 16729.1599044786, 15590.9249988859,
      10877.7284325442, 7074.21890922393, 3995.55119352751, 3825.81286496583,
    ],
    total_price: 371765.58,

    designs: [
      {
        name: 'Domek z autem',
        number_of_panels: 96,
        nominal_power: 48000.0,
        visualization:
          'https://easysolar-api-development.s3.amazonaws.com/media/f58066a8-3a87-47b6-9dc6-74bb0a08cc8e/visualisations/2ee10fcb-63bf-4549-9716-0850b8cf5b31/Domek-z-autem-Visualisation-v14.jpg',
      },
      {
        name: 'Domek jednorodzinny',
        number_of_panels: 57,
        nominal_power: 14470.0,
        visualization:
          'https://easysolar-api-development.s3.amazonaws.com/media/f58066a8-3a87-47b6-9dc6-74bb0a08cc8e/visualisations/b01d7d31-2381-4ec5-836a-708136bc0887/Domek-jednorodzinny-Visualisation-v13.jpg',
      },
      {
        name: 'Stacja paliw',
        number_of_panels: 252,
        nominal_power: 65520.0,
        visualization:
          'https://easysolar-api-development.s3.amazonaws.com/media/f58066a8-3a87-47b6-9dc6-74bb0a08cc8e/visualisations/26c7cff3-709f-4215-a342-2a3ebb53108a/Stacja-paliw-Visualisation-v6.jpg',
      },
      {
        name: 'Prototyp',
        number_of_panels: 33,
        nominal_power: 8250.0,
        visualization: null,
      },
    ],

    valuation: {
      complex: true,
      taxed: true,
      discounted: true,
      sections: {
        panels: [
          {
            name: 'Jinko Solar Co., Ltd., JKM260PP-60 (Eagle I)',
            amount: 274,
            unit: 'pcs',
            net_unit_price: 700.0,
            tax: 0.22,
            discount: 0.1,
            net_price: 191800.0,
            total_price: 210596.4,
            comment: 'Very efficient and one of the best cost-wise modules.',
          },
          {
            name: 'Masdar PV GmbH, MPV500-MXL',
            amount: 96,
            unit: 'pcs',
            net_unit_price: 700.0,
            tax: 0.22,
            discount: 0.1,
            net_price: 67200.0,
            total_price: 73785.6,
            comment: '',
          },
          {
            name: 'AEET Energy Group GmbH, AEET-ZX-250 (48)M',
            amount: 35,
            unit: 'pcs',
            net_unit_price: 700.0,
            tax: 0.22,
            discount: 0.1,
            net_price: 24500.0,
            total_price: 26901.0,
            comment: '',
          },
          {
            name: 'Samsung Electronics Co., Ltd., LPC250SM',
            amount: 33,
            unit: 'pcs',
            net_unit_price: 700.0,
            tax: 0.22,
            discount: 0.1,
            net_price: 23100.0,
            total_price: 25363.8,
            comment: '',
          },
          {
            name: 'Schott Solar AG, ASE-250-DG-FR/MC 15, 250 W',
            amount: 6,
            unit: 'pcs',
            net_unit_price: 1100.0,
            tax: 0.23,
            discount: 0.0,
            net_price: 6600.0,
            total_price: 8118,
            comment: '',
          },
        ],
        inverters: [
          {
            name: 'Elettronica Santerno, Sunway M PLUS 6400',
            amount: 1,
            unit: 'szt.',
            net_unit_price: 2000.0,
            tax: 0.2,
            discount: 0.05,
            net_price: 2000.0,
            total_price: 2280.0,
            comment: '',
          },
          {
            name: 'Optelecom-NKF B.V., OK5E-MV',
            amount: 1,
            unit: 'szt.',
            net_unit_price: 4500.0,
            tax: 0.2,
            discount: 0.03,
            net_price: 4500.0,
            total_price: 5238.0,
            comment: '',
          },
        ],
        others: [
          {
            name: 'Robocizna',
            amount: 15.0,
            unit: 'r-g',
            net_unit_price: 70.0,
            tax: 0.23,
            discount: 0.3,
            net_price: 1050.0,
            total_price: 904.05,
            comment: '',
          },
          {
            name: 'Pierwszy serwis',
            amount: 2,
            unit: 'r-g',
            net_unit_price: 300,
            tax: 0.23,
            discount: 0.9,
            net_price: 600,
            total_price: 73.8,
            comment: '90% rabatu na pierwszy serwis',
          },
        ],
      },
      summary: {
        net_price: 335831.6,
        tax_sum: 77241.27,
        gross_price: 413072.87,
        discount_sum: 41307.29,
        total_price: 371765.58,
        comment: 'Rabat dla klienta!',
      },
    },

    financial_analysis: {
      monthly_electricity_bill: 5147.0,
      annual_electricity_bill: 61764.0,
      price_of_electricity: 0.57,
      annual_price_increase_of_electricity: 0.03,
      own_contribution_ratio: 0.1,
      loan_term: 5,

      forecast: {
        annual_energy_production: 128412.7,
        annual_energy_consumption: 108357.89,
        annual_energy_fed_into_grid: 52562.2,
        annual_grid_energy_consumption: 32507.4,

        price_of_electricity_after_analysis_period: 1.0,
        upfront_costs_after_incentives: 371765.47,
        savings: 1161735.25,
        average_annual_savings: 58086.7625,
        received_subsidies: 223880.29,
        electricity_costs_without_pv_system: 1659621.83,
        electricity_costs_with_pv_system: 497886.55,
        savings_including_pv_system_costs: 789969.67,
        monthly_loan_payment: 8024.32,

        energy_production_by_year: [
          128412.7, 127770.6, 127131.8, 126496.1, 125863.6, 125234.3, 124608.2,
          123985.1, 123365.2, 122748.4, 122134.6, 121523.9, 120916.3, 120311.7,
          119710.2, 119111.6, 118516.1, 117923.5, 117333.9, 116747.2,
        ],
        savings_by_year: [
          43234.8, 44531.84, 45867.8, 47243.83, 48661.15, 50120.98, 51624.61,
          53173.35, 54768.55, 56411.61, 58103.96, 59847.07, 61642.49, 63491.76,
          65396.51, 67358.41, 69379.16, 71460.54, 73604.35, 75812.48,
        ],
        received_subsidies_by_year: [
          12614.92, 12460.83, 12307.5, 12154.94, 12003.15, 11852.11, 11701.83,
          11552.3, 11403.52, 11255.48, 11108.18, 10961.62, 10815.79, 10670.69,
          10526.32, 10382.67, 10239.73, 10097.51, 9956, 9815.2,
        ],
        loan_payments_by_year: [
          96291.74, 96291.74, 96291.74, 96291.74, 96291.74, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0,
        ],
        cumulative_cash_flow: [
          -77618.58, -116917.65, -155034.09, -191927.05, -227554.5, -165581.4,
          -102254.96, -37529.31, 28642.76, 96309.85, 165521.99, 236330.68,
          308788.96, 382951.42, 458874.25, 536615.32, 616234.22, 697792.27,
          781352.63, 866980.31,
        ],
        electricity_bills_without_pv_by_year: [
          61764, 63616.92, 65525.43, 67491.19, 69515.93, 71601.4, 73749.45,
          75961.93, 78240.79, 80588.01, 83005.65, 85495.82, 88060.7, 90702.52,
          93423.59, 96226.3, 99113.09, 102086.48, 105149.08, 108303.55,
        ],
        electricity_bills_with_pv_by_year: [
          18529.2, 19085.08, 19657.63, 20247.36, 20854.78, 21480.42, 22124.83,
          22788.58, 23472.24, 24176.4, 24901.7, 25648.75, 26418.21, 27210.75,
          28027.08, 28867.89, 29733.93, 30625.94, 31544.72, 32491.06,
        ],
      },
    },

    environmental_impact: {
      annual_co2_emission: 40201,
      annual_energy_production: [
        128413, 256440, 384084, 511344, 638223, 764721, 890839, 1016579,
        1141942, 1266929, 1391541, 1515779, 1639645, 1763138, 1886262, 2009016,
        2131401, 2253420, 2375072, 2496360,
      ],
      annual_energy_production_as_electric_car_distance: [
        713404, 1424668, 2133798, 2840800, 3545682, 4248449, 4949108, 5647664,
        6344125, 7038497, 7730785, 8420997, 9109138, 9795215, 10479233,
        11161199, 11841120, 12519000, 13194847, 13868667,
      ],
      annual_energy_production_as_saved_fuel: [
        57072, 113973, 170703, 227263, 283653, 339874, 395926, 451811, 507527,
        563077, 618459, 673676, 728727, 783613, 838334, 892891, 947284, 1001514,
        1055582, 1109487,
      ],
      annual_energy_production_as_avoided_co2_emission: [
        47641, 95139, 142495, 189708, 236780, 283711, 330501, 377150, 423660,
        470030, 516261, 562353, 608307, 654123, 699801, 745343, 790748, 836017,
        881150, 926147,
      ],
      annual_energy_production_as_avoided_nox_sox_emission: [
        457, 913, 1367, 1820, 2272, 2722, 3171, 3619, 4065, 4510, 4953, 5396,
        5837, 6276, 6714, 7151, 7587, 8021, 8454, 8886,
      ],
      annual_energy_production_as_number_of_trees_planted: [
        6228, 12437, 18628, 24800, 30954, 37089, 43206, 49304, 55384, 61446,
        67490, 73515, 79523, 85512, 91483, 97437, 103373, 109291, 115191,
        121073,
      ],
    },
  },
};
