import { CircularProgress, Grid } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { getFillLoaderStyles } from './FillLoader.styles';

export const FillLoader: FunctionComponent = () => {
  const classNames = getFillLoaderStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classNames.container}
    >
      <CircularProgress size="10vh" />
    </Grid>
  );
};
