import { Switch, SwitchProps } from '@material-ui/core';
import { FunctionComponent, useCallback, useMemo } from 'react';
import * as React from 'react';

interface SwitchFieldProps {
  value: boolean;
  onChange: (newValue: boolean) => void;
  size?: SwitchProps['size'];
  disabled?: boolean;
}

export const SwitchField: FunctionComponent<SwitchFieldProps> = ({
  value,
  onChange,
  size,
  disabled,
}) => {
  const onSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange(event.target.checked),
    [onChange],
  );

  const switchControl = useMemo(
    () => (
      <Switch
        checked={value}
        onChange={onSwitchChange}
        size={size}
        color="primary"
        disabled={disabled}
      />
    ),
    [disabled, onSwitchChange, size, value],
  );

  return switchControl;
};
