import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from 'react';

interface ProposalEditorSideBarSectionsListItemStylesProps {
  isDragging: boolean;
  disabled: boolean;
}

export const getProposalEditorSideBarSectionsListItemStyles = makeStyles<
  Theme,
  ProposalEditorSideBarSectionsListItemStylesProps
>((theme) => ({
  paper: {
    padding: theme.spacing(1),
    backgroundColor: ({ disabled }): CSSProperties['backgroundColor'] =>
      disabled
        ? theme.palette.action.disabledBackground
        : theme.palette.background.paper,
  },
  dragHandle: {
    width: 'auto',
    paddingRight: theme.spacing(1),
    cursor: ({ isDragging, disabled }): CSSProperties['cursor'] => {
      if (disabled) {
        return 'not-allowed';
      }

      if (isDragging) {
        return 'cursor';
      }

      return undefined;
    },
    color: ({ disabled }): CSSProperties['color'] =>
      disabled ? theme.palette.text.disabled : theme.palette.text.primary,
  },
  title: {
    flex: 1,
    color: ({ disabled }): CSSProperties['color'] =>
      disabled ? theme.palette.text.disabled : theme.palette.text.primary,
  },
}));
