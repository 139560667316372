import { Select, SelectProps } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { useShadowRootContainer } from '@easysolar/proposals/infrastructure/hooks/useShadowRootContainer';

export const ShadowRootSelect: FunctionComponent<SelectProps> = ({
  children,
  MenuProps,
  ...props
}) => {
  const shadowRootContainerRef = useShadowRootContainer();

  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{
        ...props,
        MenuProps: {
          ...MenuProps,
          container: shadowRootContainerRef,
        },
      }}
    >
      {children}
    </Select>
  );
};
