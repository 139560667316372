import { useTheme } from '@material-ui/core';
import { FunctionComponent, useCallback, useMemo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ProposalCurrencyNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalCurrencyNumberFormat';
import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';
import { ProposalLayoutSectionData } from '@easysolar/proposals/modules/Common/models/LayoutData/Section';
import { ProposalLayoutDefaultSectionCashFlowSchema } from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema/Sections/CashFlow';

import {
  ProposalPreviewDefaultTable,
  ProposalPreviewDefaultTableColumn,
  ProposalPreviewDefaultTableColumnOnRenderCell,
} from '../../../Shared/Table';
import { getProposalPreviewDefaultCashFlowTableStyles } from './Table.styles';

export interface ProposalPreviewDefaultCashFlowTableProps {
  tableSettings: ProposalLayoutSectionData<ProposalLayoutDefaultSectionCashFlowSchema>['settings']['table'];
}

enum Column {
  Year = 'year',
  EnergyProduction = 'energyProduction',
  Savings = 'savings',
  ReceivedSubsidies = 'receivedSubsidies',
  Installments = 'installments',
  CumulativeCashFlow = 'cumulativeCashFlow',
  ElectricityBillWithoutPV = 'electricityBillWithoutPV',
  ElectricityBillWithPV = 'electricityBillWithPV',
}

const COLUMNS_TO_SETTINGS_MAPPING: Record<
  Exclude<Column, Column.Year>,
  keyof Exclude<
    ProposalPreviewDefaultCashFlowTableProps['tableSettings'],
    undefined
  >
> = {
  [Column.EnergyProduction]: 'showEnergyProduction',
  [Column.Savings]: 'showSavings',
  [Column.ReceivedSubsidies]: 'showReceivedSubsidies',
  [Column.Installments]: 'showInstallments',
  [Column.CumulativeCashFlow]: 'showCumulativeCashFlow',
  [Column.ElectricityBillWithoutPV]: 'showElectricityBillWithoutPV',
  [Column.ElectricityBillWithPV]: 'showElectricityBillWithPV',
};

type ProposalPreviewDefaultCashFlowTableItem = Record<Column, number>;

export const ProposalPreviewDefaultCashFlowTable: FunctionComponent<ProposalPreviewDefaultCashFlowTableProps> =
  ({ tableSettings }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { project } = useProposalContext();

    const classes = getProposalPreviewDefaultCashFlowTableStyles();

    const activeColumnKeys = useMemo(
      () =>
        new Set(
          Object.keys(COLUMNS_TO_SETTINGS_MAPPING).filter(
            (key) =>
              tableSettings?.[
                COLUMNS_TO_SETTINGS_MAPPING[key as Exclude<Column, Column.Year>]
              ],
          ) as Exclude<Column, Column.Year>[],
        ),
      [tableSettings],
    );

    const columnWidthUnit = useMemo(
      () => 100 / (2 * activeColumnKeys.size + 1),
      [activeColumnKeys.size],
    );

    const yearColumn: ProposalPreviewDefaultTableColumn<ProposalPreviewDefaultCashFlowTableItem> =
      useMemo(
        () => ({
          key: Column.Year,
          name: t(
            'Layouts:Default.Sections.CashFlow.Subsections.Table.YearHeader',
          ),
          fieldName: Column.Year,
          width: `${columnWidthUnit}%`,
        }),
        [columnWidthUnit, t],
      );

    const getNameWithUnit = useCallback(
      (name: string, unit: string) => `${name} [${unit}]`,
      [],
    );

    const getNameWithCurrency = useCallback(
      (name: string) => getNameWithUnit(name, project.currencyCode),
      [getNameWithUnit, project.currencyCode],
    );

    const onRenderValueCell = useCallback(
      (
          className: string,
        ): ProposalPreviewDefaultTableColumnOnRenderCell<ProposalPreviewDefaultCashFlowTableItem> =>
        // eslint-disable-next-line react/display-name
        ({ column, item }): React.ReactElement | null =>
          column?.fieldName ? (
            <ProposalCurrencyNumberFormat
              value={item[column.fieldName]}
              omitParts={['currency', 'literal']}
              display="block"
              className={classes.valueCellContainer}
              defaultProps={{
                variant: 'caption',
                className,
              }}
            />
          ) : null,
      [classes.valueCellContainer],
    );

    const onRenderNormalValueCell = useMemo(
      () => onRenderValueCell(classes.valueCell),
      [classes.valueCell, onRenderValueCell],
    );

    const onRenderElectricityBillWithPVCell = useMemo(
      () => onRenderValueCell(classes.electricityBillWithPVCell),
      [classes.electricityBillWithPVCell, onRenderValueCell],
    );

    const isCashFlowColumnActive = useMemo(
      () => activeColumnKeys.has(Column.CumulativeCashFlow),
      [activeColumnKeys],
    );

    const cashFlowBorderWidth = '0.5mm';

    const cashFlowBorderRadius = '3mm';

    const cashFlowCellStyle = useCallback(
      ({ index }: { index: number }): React.CSSProperties => {
        const last = index === project.analysisPeriod - 1;

        return {
          borderStyle: 'solid',
          borderColor: theme.palette.primary.main,
          borderTopWidth: 0,
          borderRightWidth: cashFlowBorderWidth,
          borderBottomWidth: last ? cashFlowBorderWidth : 0,
          borderLeftWidth: cashFlowBorderWidth,
          borderBottomRightRadius: last ? cashFlowBorderRadius : 0,
          borderBottomLeftRadius: last ? cashFlowBorderRadius : 0,
        };
      },
      [
        cashFlowBorderRadius,
        cashFlowBorderWidth,
        project.analysisPeriod,
        theme.palette.primary.main,
      ],
    );

    const partialValueColumns = useMemo(
      () => [
        {
          key: Column.EnergyProduction,
          name: getNameWithUnit(
            t(
              'Layouts:Default.Sections.CashFlow.Subsections.Table.ProductionHeader',
            ),
            'kWh',
          ),
        },
        {
          key: Column.Savings,
          name: getNameWithCurrency(
            t(
              'Layouts:Default.Sections.CashFlow.Subsections.Table.SavingsHeader',
            ),
          ),
        },
        {
          key: Column.ReceivedSubsidies,
          name: getNameWithCurrency(
            t(
              'Layouts:Default.Sections.CashFlow.Subsections.Table.ReceivedSubsidiesHeader',
            ),
          ),
        },
        {
          key: Column.Installments,
          name: getNameWithCurrency(
            t(
              'Layouts:Default.Sections.CashFlow.Subsections.Table.InstallmentsHeader',
            ),
          ),
        },
        {
          key: Column.CumulativeCashFlow,
          name: getNameWithCurrency(
            t(
              'Layouts:Default.Sections.CashFlow.Subsections.Table.CashFlowHeader',
            ),
          ),
          cellStyle: cashFlowCellStyle,
          onRenderCell: onRenderNormalValueCell,
        },
        {
          key: Column.ElectricityBillWithoutPV,
          name: getNameWithCurrency(
            t(
              'Layouts:Default.Sections.CashFlow.Subsections.Table.ElectricityBillWithoutPVHeader',
            ),
          ),
        },
        {
          key: Column.ElectricityBillWithPV,
          name: getNameWithCurrency(
            t(
              'Layouts:Default.Sections.CashFlow.Subsections.Table.ElectricityBillWithPVHeader',
            ),
          ),
          onRenderCell: onRenderElectricityBillWithPVCell,
        },
      ],
      [
        cashFlowCellStyle,
        getNameWithCurrency,
        getNameWithUnit,
        onRenderElectricityBillWithPVCell,
        onRenderNormalValueCell,
        t,
      ],
    );

    const valueColumnWidth = useMemo(
      () => `${2 * columnWidthUnit}%`,
      [columnWidthUnit],
    );

    const valueColumns = useMemo(() => {
      return partialValueColumns.map((partialValueColumn) => ({
        fieldName: partialValueColumn.key,
        width: valueColumnWidth,
        onRenderCell: onRenderNormalValueCell,
        ...partialValueColumn,
      }));
    }, [onRenderNormalValueCell, partialValueColumns, valueColumnWidth]);

    const allColumns: ProposalPreviewDefaultTableColumn<ProposalPreviewDefaultCashFlowTableItem>[] =
      useMemo(() => {
        return [yearColumn, ...valueColumns];
      }, [valueColumns, yearColumn]);

    const columns = useMemo(
      () =>
        allColumns.filter((column) => {
          const settingName =
            COLUMNS_TO_SETTINGS_MAPPING[
              column.key as Exclude<Column, Column.Year>
            ];

          return settingName ? tableSettings?.[settingName] : true;
        }),
      [allColumns, tableSettings],
    );

    const beforeCashFlowCellStyle = useCallback(
      () =>
        isCashFlowColumnActive
          ? {
              borderRightWidth: 0,
            }
          : undefined,
      [isCashFlowColumnActive],
    );

    const adjustedColumns = useMemo(() => {
      const cashFlowColumnIndex = columns.findIndex(
        (column) => column.key === Column.CumulativeCashFlow,
      );

      return columns.map((column, index) =>
        index === cashFlowColumnIndex - 1
          ? { ...column, cellStyle: beforeCashFlowCellStyle }
          : column,
      );
    }, [beforeCashFlowCellStyle, columns]);

    const items: ProposalPreviewDefaultCashFlowTableItem[] = useMemo(() => {
      const {
        financialAnalysis: {
          forecast: {
            energyProductionByYear,
            savingsByYear,
            receivedSubsidiesByYear,
            loanPaymentsByYear,
            cumulativeCashFlow,
            electricityBillsWithoutPVByYear,
            electricityBillsWithPVByYear,
          },
        },
      } = project;

      return new Array(project.analysisPeriod).fill(null).map((_, index) => ({
        [Column.Year]: index + 1,
        [Column.EnergyProduction]: energyProductionByYear[index],
        [Column.Savings]: savingsByYear[index],
        [Column.ReceivedSubsidies]: receivedSubsidiesByYear[index],
        [Column.Installments]: loanPaymentsByYear[index],
        [Column.CumulativeCashFlow]: cumulativeCashFlow[index],
        [Column.ElectricityBillWithoutPV]:
          electricityBillsWithoutPVByYear[index],
        [Column.ElectricityBillWithPV]: electricityBillsWithPVByYear[index],
      }));
    }, [project]);

    const table = useMemo(
      () => (
        <ProposalPreviewDefaultTable columns={adjustedColumns} items={items} />
      ),
      [adjustedColumns, items],
    );

    return table;
  };
