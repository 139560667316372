import { FunctionComponent, useMemo } from 'react';

import {
  ProposalLayoutSettingSchema,
  ProposalLayoutSettingTypeName,
} from '@easysolar/proposals/modules/Common/models/LayoutSchema/Settings';
import { FormControl } from '@easysolar/proposals/shared/forms/models';

import { ProposalEditorSettingBase } from './SettingTypes/Base';
import { ProposalEditorSettingBoolean } from './SettingTypes/Boolean';
import { ProposalEditorSettingChoice } from './SettingTypes/Choice';
import { ProposalEditorSettingColor } from './SettingTypes/Color';
import { ProposalEditorSettingImage } from './SettingTypes/Image';
import { ProposalEditorSettingText } from './SettingTypes/Text';
import { ProposalEditorSettingToggleableText } from './SettingTypes/ToggleableText';

export interface ProposalEditorSettingProps {
  formControl: FormControl;
  settingSchema: ProposalLayoutSettingSchema;
}

const SETTING_COMPONENTS_MAPPING = {
  [ProposalLayoutSettingTypeName.Text]: ProposalEditorSettingText,
  [ProposalLayoutSettingTypeName.Color]: ProposalEditorSettingColor,
  [ProposalLayoutSettingTypeName.Boolean]: ProposalEditorSettingBoolean,
  [ProposalLayoutSettingTypeName.Image]: ProposalEditorSettingImage,
  [ProposalLayoutSettingTypeName.ToggleableText]:
    ProposalEditorSettingToggleableText,
  [ProposalLayoutSettingTypeName.Choice]: ProposalEditorSettingChoice,
};

export const ProposalEditorSetting: FunctionComponent<ProposalEditorSettingProps> =
  ({ formControl, settingSchema }) => {
    const SettingTypeComponent = useMemo(
      () =>
        SETTING_COMPONENTS_MAPPING[
          settingSchema.settingType
        ] as ProposalEditorSettingBase<ProposalLayoutSettingSchema>,
      [settingSchema.settingType],
    );

    return SettingTypeComponent ? (
      <SettingTypeComponent
        formControl={formControl}
        settingSchema={settingSchema}
      />
    ) : null;
  };
