import { useCallback } from 'react';

import {
  AbstractFormControl,
  AbstractFormControlResult,
  FormArray,
  FormControlParentControls,
} from '../models';
import { useAbstractFormControl } from './useAbstractFormControl';

interface UseFormArrayResult<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Array<any> = Array<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> extends AbstractFormControlResult<T, E> {
  insert: (
    index: number,
    control: FormControlParentControls<T>[number],
  ) => void;
  remove: (index: number) => AbstractFormControl<T[number]>;
}

export function useFormArray<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Array<any> = Array<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
>(formArray: FormArray<T, E>): UseFormArrayResult<T, E> {
  const result = useAbstractFormControl(formArray) as AbstractFormControlResult<
    T,
    E
  >;

  const insert = useCallback(
    (index: number, control: FormControlParentControls<T>[number]) => {
      formArray.insert(index, control);
    },
    [formArray],
  );

  const remove = useCallback(
    (index: number) => formArray.remove(index),
    [formArray],
  );

  return { ...result, insert, remove };
}
