import { Box, Container, Grid } from '@material-ui/core';
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Language } from '@easysolar/proposals/infrastructure/services/InternationalizationService';
import {
  ProposalLayoutAnyData,
  ProposalLayoutData,
} from '@easysolar/proposals/modules/Common/models/LayoutData/Layout';
import { ProposalLayoutName } from '@easysolar/proposals/modules/Common/models/Layouts/Base';
import { ProposalLayoutDefaultSchema } from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema';
import { ProposalLayoutVersionNumber } from '@easysolar/proposals/modules/Common/models/Layouts/Version';
import { ProposalContextData } from '@easysolar/proposals/modules/Common/models/ProposalContextData';
import { ProposalLayoutVersion } from '@easysolar/proposals/modules/Common/models/ProposalLayoutVersion';
import { FormGroup } from '@easysolar/proposals/shared/forms/models';

import { ProposalTemplatesPermission } from '../../models/ProposalTemplatesPermission';
import { selectEmployeeProposalTemplatesPermission } from '../../store/webComponentProps';
import { ProposalEditorPreviewWebComponentWrapper } from '../PreviewWebComponentWrapper';
import { ProposalEditorSideBar } from '../SideBar';
import { useProposalLayoutEditorStyles } from './LayoutEditor.styles';

interface ProposalLayoutEditorProps {
  contextData: ProposalContextData;
  layoutVersion: ProposalLayoutVersion;
  proposalForm: FormGroup<ProposalLayoutAnyData>;
  lastValidFormData: ProposalLayoutAnyData;
  disabled: boolean;
}

export const ProposalLayoutEditor: FunctionComponent<ProposalLayoutEditorProps> =
  ({
    contextData,
    layoutVersion,
    proposalForm,
    lastValidFormData,
    disabled,
  }) => {
    const {
      i18n: { language },
    } = useTranslation();

    const classes = useProposalLayoutEditorStyles();

    const permission = useSelector(selectEmployeeProposalTemplatesPermission);
    const canEmployeeEditLayout = useMemo(
      () =>
        permission === ProposalTemplatesPermission.FullAccess ||
        permission === ProposalTemplatesPermission.ContentEditing,
      [permission],
    );

    const [activeSectionName, setActiveSectionName] = useState<string | null>(
      null,
    );

    const sideBar = useMemo(
      () => (
        <Grid item className={classes.sideBar}>
          <ProposalEditorSideBar
            layoutFormGroup={proposalForm}
            layoutSchema={layoutVersion.schema}
            activeSectionName={activeSectionName}
            onActiveSectionNameChange={setActiveSectionName}
            disabled={disabled}
          />
        </Grid>
      ),
      [
        activeSectionName,
        classes.sideBar,
        disabled,
        layoutVersion.schema,
        proposalForm,
      ],
    );

    const preview = useMemo(
      () => (
        <ProposalEditorPreviewWebComponentWrapper
          language={language as Language}
          printing={false}
          contextData={contextData}
          layoutName={layoutVersion.name as ProposalLayoutName}
          layoutVersion={layoutVersion.version as ProposalLayoutVersionNumber}
          layoutData={
            lastValidFormData as ProposalLayoutData<ProposalLayoutDefaultSchema>
          }
          activeSectionName={activeSectionName}
        />
      ),
      [
        activeSectionName,
        contextData,
        language,
        lastValidFormData,
        layoutVersion.name,
        layoutVersion.version,
      ],
    );

    const previewContainer = useMemo(
      () => (
        <Grid item className={classes.previewColumn}>
          <Container className={classes.previewContainer}>
            <Box className={classes.preview}>{preview}</Box>
          </Container>
        </Grid>
      ),
      [
        classes.preview,
        classes.previewColumn,
        classes.previewContainer,
        preview,
      ],
    );

    return (
      <Grid item container wrap="nowrap">
        {canEmployeeEditLayout && sideBar}
        {previewContainer}
      </Grid>
    );
  };
