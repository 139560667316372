import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from 'react';

interface ProposalPreviewDefaultInvestmentCostsElectricityBillStylesProps {
  showCircles: boolean;
}

export const getProposalPreviewDefaultInvestmentCostsElectricityBillStyles =
  makeStyles<
    Theme,
    ProposalPreviewDefaultInvestmentCostsElectricityBillStylesProps
  >(() => ({
    table: {
      marginTop: ({ showCircles }): CSSProperties['marginTop'] =>
        showCircles ? '10mm' : undefined,
    },
  }));
