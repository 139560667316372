import { Grid, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { ProposalUnitNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalUnitNumberFormat';

import {
  ProposalPreviewDefaultIcon,
  ProposalPreviewDefaultSvgIcon,
} from '../../../../Shared/SvgIcon';
import { getProposalPreviewDefaultSystemDesignVisualizationCharacteristicStyles } from './Characteristic.styles';

export interface ProposalPreviewDefaultSystemDesignVisualizationCharacteristicProps {
  icon: ProposalPreviewDefaultIcon;
  label: string;
  value: number;
  unit: string;
  fractionDigits?: number;
}

export const ProposalPreviewDefaultSystemDesignVisualizationCharacteristic: FunctionComponent<ProposalPreviewDefaultSystemDesignVisualizationCharacteristicProps> =
  ({ icon, label, value, unit, fractionDigits = 0 }) => {
    const classes =
      getProposalPreviewDefaultSystemDesignVisualizationCharacteristicStyles();

    return (
      <Grid
        className={classes.root}
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <ProposalPreviewDefaultSvgIcon icon={icon} className={classes.icon} />
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.label}
        >
          {label}
        </Typography>
        <ProposalUnitNumberFormat
          value={value}
          unit={unit}
          options={{
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
          }}
          defaultProps={{
            variant: 'h4',
            color: 'primary',
          }}
          partTypesProps={{
            unit: {
              variant: 'h5',
            },
          }}
        />
      </Grid>
    );
  };
