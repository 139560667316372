import { ProposalLayoutName } from '../models/Layouts/Base';
import { ProposalLayoutDefaultSchema } from '../models/Layouts/Default/Schema';
import { ProposalLayoutDefaultAlignment } from '../models/Layouts/Default/Schema/GeneralSettings';
import { ProposalLayoutDefaultSectionName } from '../models/Layouts/Default/Schema/Sections/Common';
import { ProposalLayoutSettingTypeName } from '../models/LayoutSchema/Settings';

export const TEST_PROPOSAL_LAYOUT_SCHEMA: ProposalLayoutDefaultSchema = {
  type: 'object',
  name: ProposalLayoutName.Default,
  properties: {
    generalSettings: {
      title: 'Branding settings',
      type: 'object',
      settings: [
        'primaryColor',
        'textColor',
        'secondaryColor',
        'isLogoVisible',
        'footer',
        'coverPageFooter',
      ],
      properties: {
        primaryColor: {
          title: 'Primary color',
          default: '#0099a1',
          pattern: '^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$',
          predefinedColors: [
            '#f44336',
            '#9c27b0',
            '#3f51b5',
            '#03a9f4',
            '#0099a1',
            '#8bc34a',
            '#ffeb3b',
            '#ff9800',
            '#795548',
            '#607d8b',
          ],
          settingType: ProposalLayoutSettingTypeName.Color,
          type: 'string',
        },
        textColor: {
          title: 'Text color',
          default: '#646263',
          pattern: '^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$',
          predefinedColors: [
            '#888888',
            '#646263',
            '#444444',
            '#222222',
            '#000000',
            '#f44336',
            '#9c27b0',
            '#3f51b5',
            '#03a9f4',
            '#0099a1',
            '#8bc34a',
            '#ffeb3b',
            '#ff9800',
            '#795548',
            '#607d8b',
          ],
          settingType: ProposalLayoutSettingTypeName.Color,
          type: 'string',
        },
        secondaryColor: {
          title: 'Secondary color',
          default: '#80cc28',
          pattern: '^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$',
          predefinedColors: [
            '#f44336',
            '#9c27b0',
            '#3f51b5',
            '#03a9f4',
            '#00bcd4',
            '#80cc28',
            '#ffeb3b',
            '#ff9800',
            '#795548',
            '#607d8b',
          ],
          settingType: ProposalLayoutSettingTypeName.Color,
          type: 'string',
        },
        isLogoVisible: {
          title: 'Show company logo',
          default: true,
          type: 'boolean',
          settingType: ProposalLayoutSettingTypeName.Boolean,
        },
        footer: {
          title: 'Footer',
          type: 'object',
          settings: ['alignment', 'text'],
          properties: {
            alignment: {
              title: 'Alignment',
              type: 'string',
              settingType: ProposalLayoutSettingTypeName.Choice,
              enum: ['left', 'right'],
              default: ProposalLayoutDefaultAlignment.Left,
              choices: [
                {
                  value: ProposalLayoutDefaultAlignment.Left,
                  label: 'Left',
                },
                {
                  value: ProposalLayoutDefaultAlignment.Right,
                  label: 'Right',
                },
              ],
            },
            text: {
              title: 'Text',
              type: 'object',
              settingType: ProposalLayoutSettingTypeName.ToggleableText,
              properties: {
                enabled: {
                  type: 'boolean',
                },
                text: {
                  type: 'string',
                  maxLength: 500,
                },
              },
              required: ['enabled', 'text'],
              default: {
                enabled: true,
                text: 'EasySolar, ul. Królowej Jadwigi 43, 61-871 Poznań',
              },
            },
          },
        },
        coverPageFooter: {
          title: 'Cover page footer',
          type: 'object',
          settings: ['left', 'right'],
          properties: {
            left: {
              title: 'Left',
              type: 'object',
              settingType: ProposalLayoutSettingTypeName.ToggleableText,
              properties: {
                enabled: {
                  type: 'boolean',
                },
                text: {
                  type: 'string',
                  maxLength: 120,
                },
              },
              required: ['enabled', 'text'],
              default: {
                enabled: false,
                text: '',
              },
            },
            right: {
              title: 'Right',
              type: 'object',
              settingType: ProposalLayoutSettingTypeName.ToggleableText,
              properties: {
                enabled: {
                  type: 'boolean',
                },
                text: {
                  type: 'string',
                  maxLength: 450,
                },
              },
              required: ['enabled', 'text'],
              default: {
                enabled: true,
                text: 'easysolar.app',
              },
            },
          },
        },
      },
    },
    sections: {
      type: 'array',
      items: {
        oneOf: [
          {
            type: 'object',
            name: ProposalLayoutDefaultSectionName.CoverPage,
            properties: {
              name: {
                type: 'string',
                enum: [ProposalLayoutDefaultSectionName.CoverPage],
              },
              enabled: {
                type: 'boolean',
                default: true,
              },
              settings: {
                title: 'Cover page',
                type: 'object',
                settings: [
                  'backgroundImage',
                  'isLogoVisible',
                  'title',
                  'subtitle',
                  'contact',
                ],
                properties: {
                  backgroundImage: {
                    title: 'Background image',
                    default:
                      'https://easysolar-api-development.s3.amazonaws.com/static/proposals/images/default-cover-page-background-image.jpg',
                    minLength: 1,
                    maxLength: 65536,
                    format: 'uri',
                    settingType: ProposalLayoutSettingTypeName.Image,
                    type: 'string',
                  },
                  isLogoVisible: {
                    title: 'Show company logo',
                    default: true,
                    type: 'boolean',
                    settingType: ProposalLayoutSettingTypeName.Boolean,
                  },
                  title: {
                    title: 'Title',
                    default: 'Solar system',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  subtitle: {
                    title: 'Subtitle',
                    default: 'customer proposal',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  contact: {
                    title: 'Contact',
                    type: 'object',
                    settings: [
                      'title',
                      'showCompanyName',
                      'showEmployeeName',
                      'showEmployeeEmailAddress',
                      'showEmployeePhoneNumber',
                    ],
                    properties: {
                      title: {
                        title: 'Title',
                        default: 'Contact',
                        maxLength: 30,
                        settingType: ProposalLayoutSettingTypeName.Text,
                        type: 'string',
                      },
                      showCompanyName: {
                        title: 'Show company name',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showEmployeeName: {
                        title: 'Show employee name',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showEmployeeEmailAddress: {
                        title: 'Show employee email address',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showEmployeePhoneNumber: {
                        title: 'Show employee phone number',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                    },
                  },
                },
              },
            },
          },
          {
            type: 'object',
            name: ProposalLayoutDefaultSectionName.ProposedSystem,
            properties: {
              name: {
                type: 'string',
                enum: [ProposalLayoutDefaultSectionName.ProposedSystem],
              },
              enabled: {
                type: 'boolean',
                default: true,
              },
              settings: {
                title: 'Proposed PV system',
                type: 'object',
                settings: [
                  'title',
                  'subtitle',
                  'yourCurrentElectricityBill',
                  'recommendedSizeOfPVSystem',
                  'energyBalance',
                ],
                properties: {
                  title: {
                    title: 'Title',
                    default: 'Proposed',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  subtitle: {
                    title: 'Subtitle',
                    default: 'PV system',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  yourCurrentElectricityBill: {
                    title: 'Your current electricity bill',
                    type: 'object',
                    settings: ['showSubsection', 'title', 'showFacts'],
                    properties: {
                      showSubsection: {
                        title: 'Show subsection',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      title: {
                        title: 'Title',
                        default: 'Your current electricity bill',
                        maxLength: 60,
                        settingType: ProposalLayoutSettingTypeName.Text,
                        type: 'string',
                      },
                      showFacts: {
                        title: 'Show facts',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                    },
                  },
                  recommendedSizeOfPVSystem: {
                    title: 'Recommended size of PV system',
                    type: 'object',
                    settings: [
                      'showSubsection',
                      'title',
                      'showEnergyProductionAndConsumptionComparison',
                    ],
                    properties: {
                      showSubsection: {
                        title: 'Show subsection',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      title: {
                        title: 'Title',
                        default: 'Recommended size of PV system',
                        maxLength: 80,
                        settingType: ProposalLayoutSettingTypeName.Text,
                        type: 'string',
                      },
                      showEnergyProductionAndConsumptionComparison: {
                        title:
                          'Show energy production and consumption comparison',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                    },
                  },
                  energyBalance: {
                    title: 'Energy balance',
                    type: 'object',
                    settings: ['showSubsection', 'title'],
                    properties: {
                      showSubsection: {
                        title: 'Show subsection',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      title: {
                        title: 'Title',
                        default: 'Energy balance',
                        maxLength: 80,
                        settingType: ProposalLayoutSettingTypeName.Text,
                        type: 'string',
                      },
                    },
                  },
                },
              },
            },
          },
          {
            type: 'object',
            name: ProposalLayoutDefaultSectionName.SystemDesign,
            properties: {
              name: {
                type: 'string',
                enum: [ProposalLayoutDefaultSectionName.SystemDesign],
              },
              enabled: {
                type: 'boolean',
                default: true,
              },
              settings: {
                title: 'PV system design',
                type: 'object',
                settings: ['title', 'subtitle'],
                properties: {
                  title: {
                    title: 'Title',
                    default: 'PV system',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  subtitle: {
                    title: 'Subtitle',
                    default: 'design',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                },
              },
            },
          },
          {
            type: 'object',
            name: ProposalLayoutDefaultSectionName.CustomerProposal,
            properties: {
              name: {
                type: 'string',
                enum: [ProposalLayoutDefaultSectionName.CustomerProposal],
              },
              enabled: {
                type: 'boolean',
                default: true,
              },
              settings: {
                title: 'Customer proposal',
                type: 'object',
                settings: ['title', 'subtitle', 'showValuation', 'valuation'],
                properties: {
                  title: {
                    title: 'Title',
                    default: 'Customer',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  subtitle: {
                    title: 'Subtitle',
                    default: 'proposal',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  showValuation: {
                    title: 'Show valuation',
                    default: true,
                    type: 'boolean',
                    settingType: ProposalLayoutSettingTypeName.Boolean,
                  },
                  valuation: {
                    title: 'Valuation',
                    type: 'object',
                    settings: [
                      'showNetUnitPrice',
                      'showTax',
                      'showDiscount',
                      'showNetPrice',
                      'showTotalPrice',
                    ],
                    properties: {
                      showNetUnitPrice: {
                        title: 'Show unit price',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showTax: {
                        title: 'Show tax',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showDiscount: {
                        title: 'Show discount',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showNetPrice: {
                        title: 'Show net price',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showTotalPrice: {
                        title: 'Show total price',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                    },
                  },
                },
              },
            },
          },
          {
            type: 'object',
            name: ProposalLayoutDefaultSectionName.EnergyProductionAndEnvironmentalImpact,
            properties: {
              name: {
                type: 'string',
                enum: [
                  ProposalLayoutDefaultSectionName.EnergyProductionAndEnvironmentalImpact,
                ],
              },
              enabled: {
                type: 'boolean',
                default: true,
              },
              settings: {
                title: 'Energy production',
                type: 'object',
                settings: [
                  'title',
                  'subtitle',
                  'energyProduction',
                  'environmentalImpact',
                ],
                properties: {
                  title: {
                    title: 'Title',
                    default: 'Energy production',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  subtitle: {
                    title: 'Subtitle',
                    default: 'and environmental impact',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  energyProduction: {
                    title: 'Energy production',
                    type: 'object',
                    settings: ['showSubsection', 'title', 'source'],
                    properties: {
                      showSubsection: {
                        title: 'Show subsection',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      title: {
                        title: 'Title',
                        default: 'Energy production',
                        maxLength: 80,
                        settingType: ProposalLayoutSettingTypeName.Text,
                        type: 'string',
                      },
                      source: {
                        title: 'Source',
                        default: 'NASA',
                        maxLength: 80,
                        settingType: ProposalLayoutSettingTypeName.Text,
                        type: 'string',
                      },
                    },
                  },
                  environmentalImpact: {
                    title: 'Environmental impact',
                    type: 'object',
                    settings: ['showSubsection', 'title'],
                    properties: {
                      showSubsection: {
                        title: 'Show subsection',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      title: {
                        title: 'Title',
                        default: 'Environmental impact',
                        maxLength: 80,
                        settingType: ProposalLayoutSettingTypeName.Text,
                        type: 'string',
                      },
                    },
                  },
                },
              },
            },
          },
          {
            type: 'object',
            name: ProposalLayoutDefaultSectionName.InvestmentCosts,
            properties: {
              name: {
                type: 'string',
                enum: [ProposalLayoutDefaultSectionName.InvestmentCosts],
              },
              enabled: {
                type: 'boolean',
                default: true,
              },
              settings: {
                title: 'Investment costs',
                type: 'object',
                settings: [
                  'title',
                  'subtitle',
                  'electricityBill',
                  'energyCosts',
                ],
                properties: {
                  title: {
                    title: 'Title',
                    default: 'Investment costs',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  subtitle: {
                    title: 'Subtitle',
                    default: 'over 20 years',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  electricityBill: {
                    title: 'Electricity bill',
                    type: 'object',
                    settings: [
                      'showSubsection',
                      'title',
                      'showUpfrontCostsAfterIncentives',
                      'showAverageAnnualSavings',
                      'showSavings',
                      'showSubsidies',
                    ],
                    properties: {
                      showSubsection: {
                        title: 'Show subsection',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      title: {
                        title: 'Title',
                        default: 'Your new electricity bill',
                        maxLength: 80,
                        settingType: ProposalLayoutSettingTypeName.Text,
                        type: 'string',
                      },
                      showUpfrontCostsAfterIncentives: {
                        title: 'Show actual initial costs',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showAverageAnnualSavings: {
                        title: 'Show average annual savings',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showSavings: {
                        title: 'Show the sum of savings',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showSubsidies: {
                        title: 'Show subsidies',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                    },
                  },
                  energyCosts: {
                    title: 'Energy costs',
                    type: 'object',
                    settings: ['showSubsection', 'title'],
                    properties: {
                      showSubsection: {
                        title: 'Show subsection',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      title: {
                        title: 'Title',
                        default: 'Compare energy costs over the next 20 years',
                        maxLength: 80,
                        settingType: ProposalLayoutSettingTypeName.Text,
                        type: 'string',
                      },
                    },
                  },
                },
              },
            },
          },
          {
            type: 'object',
            name: ProposalLayoutDefaultSectionName.CashFlow,
            properties: {
              name: {
                type: 'string',
                enum: [ProposalLayoutDefaultSectionName.CashFlow],
              },
              enabled: {
                type: 'boolean',
                default: true,
              },
              settings: {
                title: 'Accumulated cash flow',
                type: 'object',
                settings: ['title', 'subtitle', 'table', 'contact'],
                properties: {
                  title: {
                    title: 'Title',
                    default: 'Accumulated',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  subtitle: {
                    title: 'Subtitle',
                    default: 'cash flow',
                    maxLength: 30,
                    settingType: ProposalLayoutSettingTypeName.Text,
                    type: 'string',
                  },
                  table: {
                    title: 'Cash flow',
                    type: 'object',
                    settings: [
                      'showEnergyProduction',
                      'showSavings',
                      'showReceivedSubsidies',
                      'showInstallments',
                      'showCumulativeCashFlow',
                      'showElectricityBillWithoutPV',
                      'showElectricityBillWithPV',
                    ],
                    properties: {
                      showEnergyProduction: {
                        title: 'Show energy production',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showSavings: {
                        title: 'Show savings',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showReceivedSubsidies: {
                        title: 'Show received subsidies',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showInstallments: {
                        title: 'Show installments',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showCumulativeCashFlow: {
                        title: 'Show cumulative cash flow',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showElectricityBillWithoutPV: {
                        title: 'Show electricity bill without PV',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showElectricityBillWithPV: {
                        title: 'Show electricity bill with PV',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                    },
                  },
                  contact: {
                    title: 'Contact',
                    type: 'object',
                    settings: [
                      'title',
                      'showCompanyName',
                      'showEmployeeName',
                      'showEmployeeEmailAddress',
                      'showEmployeePhoneNumber',
                    ],
                    properties: {
                      title: {
                        title: 'Title',
                        default: 'Contact',
                        maxLength: 30,
                        settingType: ProposalLayoutSettingTypeName.Text,
                        type: 'string',
                      },
                      showCompanyName: {
                        title: 'Show company name',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showEmployeeName: {
                        title: 'Show employee name',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showEmployeeEmailAddress: {
                        title: 'Show employee email address',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                      showEmployeePhoneNumber: {
                        title: 'Show employee phone number',
                        default: true,
                        type: 'boolean',
                        settingType: ProposalLayoutSettingTypeName.Boolean,
                      },
                    },
                  },
                },
              },
            },
          },
        ],
      },
    },
  },
};
