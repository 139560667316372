import { createRef, RefObject, useEffect, useMemo, useRef } from 'react';

import { ProposalLayoutData } from '@easysolar/proposals/modules/Common/models/LayoutData/Layout';
import { ProposalLayoutSchema } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Layout';
import { ProposalLayoutSectionSchema } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Section';
import { UnionToIntersection } from '@easysolar/proposals/shared/models/Types';

import { ProposalPreviewSection } from '../Section';

export type ProposalPreviewSectionsProps<LayoutSchema> =
  LayoutSchema extends ProposalLayoutSchema<
    infer _LayoutName,
    infer _GeneralSettings,
    infer Sections
  >
    ? {
        layoutData: ProposalLayoutData<LayoutSchema>;
        sectionsComponents: Sections extends Array<infer SectionSchema>
          ? SectionSchema extends ProposalLayoutSectionSchema<
              infer SectionName,
              infer _Settings
            >
            ? UnionToIntersection<{
                [Key in SectionName]: ProposalPreviewSection<LayoutSchema, Key>;
              }>
            : never
          : never;
        activeSectionName: string | null;
      }
    : never;

export const ProposalPreviewSections = <Layout extends ProposalLayoutSchema>({
  layoutData,
  sectionsComponents,
  activeSectionName,
}: ProposalPreviewSectionsProps<Layout>): JSX.Element => {
  const sectionsRefs = useRef<Record<string, RefObject<HTMLDivElement>>>({});

  const { sections } = layoutData;

  useEffect(() => {
    sectionsRefs.current = sections.reduce((result, section) => {
      // eslint-disable-next-line no-param-reassign
      result[section.name] = sectionsRefs.current[section.name] || createRef();

      return result;
    }, {} as Record<string, RefObject<HTMLDivElement>>);
  }, [sections]);

  useEffect(() => {
    if (activeSectionName) {
      const sectionRef = sectionsRefs.current[activeSectionName]?.current;

      if (sectionRef) {
        sectionRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [activeSectionName]);

  const renderedSectionsComponents = useMemo(
    () =>
      sections
        .filter((section) => section.enabled)
        .map((section) => {
          const sectionName = section.name;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const SectionComponent = (sectionsComponents as Record<string, any>)[
            sectionName
          ];
          const ref = sectionsRefs.current[sectionName];
          const sectionSettings = section.settings;

          return (
            SectionComponent && (
              <div key={sectionName} ref={ref}>
                <SectionComponent
                  generalSettings={layoutData.generalSettings}
                  sectionSettings={sectionSettings}
                />
              </div>
            )
          );
        }),
    [layoutData.generalSettings, sections, sectionsComponents],
  );

  return <>{renderedSectionsComponents}</>;
};
