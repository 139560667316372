import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';
import {
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName,
} from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema';
import { ProposalContextDataValuationRow } from '@easysolar/proposals/modules/Common/models/ProposalContextData';

import { ProposalPreviewSection } from '../../../../Section';
import { ProposalPreviewDefaultSectionMultiPage } from '../../Shared/SectionMultiPage';
import {
  ProposalPreviewDefaultCustomerProposalRow,
  ProposalPreviewDefaultCustomerProposalRowShowColumns,
} from './Row';
import { ProposalPreviewDefaultCustomerProposalSummary } from './Summary';

export const ProposalPreviewDefaultCustomerProposal: ProposalPreviewSection<
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName.CoverPage
> = ({
  generalSettings,
  sectionSettings: {
    title,
    subtitle,
    showValuation,
    valuation: {
      showNetUnitPrice,
      showTax,
      showDiscount,
      showNetPrice,
      showTotalPrice,
    },
  },
}) => {
  const { t } = useTranslation();

  const {
    project: { valuation },
  } = useProposalContext();

  const showColumns =
    useMemo<ProposalPreviewDefaultCustomerProposalRowShowColumns>(
      () => ({
        name: true,
        amount: true,
        unit: true,
        netUnitPrice: valuation.complex && showNetUnitPrice,
        tax: valuation.complex && valuation.taxed && showTax,
        discount: valuation.complex && valuation.discounted && showDiscount,
        netPrice: valuation.complex && showNetPrice,
        totalPrice: valuation.complex && showTotalPrice,
      }),
      [
        showDiscount,
        showNetPrice,
        showNetUnitPrice,
        showTax,
        showTotalPrice,
        valuation.complex,
        valuation.discounted,
        valuation.taxed,
      ],
    );

  const translateRowsPiecesUnit = useCallback(
    (rows: ProposalContextDataValuationRow[]) =>
      rows.map((panelRow) => ({
        ...panelRow,
        unit: t('Shared:Units.Pieces'),
      })),
    [t],
  );

  const panelRows = useMemo(
    () => translateRowsPiecesUnit(valuation.sections.panels),
    [translateRowsPiecesUnit, valuation.sections.panels],
  );

  const inverterRows = useMemo(
    () => translateRowsPiecesUnit(valuation.sections.inverters),
    [translateRowsPiecesUnit, valuation.sections.inverters],
  );

  const renderRows = useCallback(
    (key: string, header: string, rows: ProposalContextDataValuationRow[]) =>
      rows.map((row, index) => (
        <ProposalPreviewDefaultCustomerProposalRow
          // eslint-disable-next-line react/no-array-index-key
          key={`${key}-${index}`}
          header={index === 0 ? header : undefined}
          row={row}
          showColumns={showColumns}
        />
      )),
    [showColumns],
  );

  const renderedPanelRows = useMemo(
    () =>
      renderRows(
        'panels',
        t('Layouts:Default.Sections.CustomerProposal.Sections.PanelsLabel'),
        panelRows,
      ),
    [panelRows, renderRows, t],
  );

  const renderedInverterRows = useMemo(
    () =>
      renderRows(
        'inverters',
        t('Layouts:Default.Sections.CustomerProposal.Sections.InvertersLabel'),
        inverterRows,
      ),
    [inverterRows, renderRows, t],
  );

  const renderedOtherRows = useMemo(
    () =>
      renderRows(
        'others',
        t('Layouts:Default.Sections.CustomerProposal.Sections.OthersLabel'),
        valuation.sections.others,
      ),
    [renderRows, t, valuation.sections.others],
  );

  const summary = useMemo(
    () => (
      <ProposalPreviewDefaultCustomerProposalSummary
        key="summary"
        valuation={valuation}
      />
    ),
    [valuation],
  );

  const content = useMemo(
    () => [
      ...(showValuation ? renderedPanelRows : []),
      ...(showValuation ? renderedInverterRows : []),
      ...(showValuation ? renderedOtherRows : []),
      summary,
    ],
    [
      renderedInverterRows,
      renderedOtherRows,
      renderedPanelRows,
      showValuation,
      summary,
    ],
  );

  const multiPage = useMemo(
    () => (
      <ProposalPreviewDefaultSectionMultiPage
        generalSettings={generalSettings}
        title={title}
        subtitle={subtitle}
      >
        {content}
      </ProposalPreviewDefaultSectionMultiPage>
    ),
    [content, generalSettings, subtitle, title],
  );

  return multiPage;
};
