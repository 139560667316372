import { ProposalLayoutSettingSchemaColor } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Settings/Color';
import { ControlledColorField } from '@easysolar/proposals/shared/components/ControlledColorField';

import { ProposalEditorSettingBase } from './Base';

export const ProposalEditorSettingColor: ProposalEditorSettingBase<ProposalLayoutSettingSchemaColor> =
  ({ formControl, settingSchema }) => {
    return (
      <ControlledColorField
        formControl={formControl}
        label={settingSchema.title}
        predefinedColors={settingSchema.predefinedColors}
      />
    );
  };
