import { createContext, useContext } from 'react';

import { ProposalContextData } from '../models/ProposalContextData';

const ANNUAL_ZEROS = new Array<number>(20).fill(0);
const MONTHLY_ZEROS = new Array<number>(12).fill(0);

export const ProposalContext = createContext<ProposalContextData>({
  company: {
    name: '',
    address: '',
    phoneNumber: '',
    emailAddress: '',
    websiteAddress: '',
    logo: '',
    color: '',
  },
  employee: {
    name: '',
    emailAddress: '',
    phoneNumber: '',
  },
  client: {
    name: '',
  },
  project: {
    address: '',
    location: {
      latitude: 0,
      longitude: 0,
    },
    currencyCode: '',
    analysisPeriod: 20,
    nominalPower: 0,
    surfaceArea: 0,
    averageAnnualIrradiation: 0,
    energyProductionByMonth: MONTHLY_ZEROS,
    totalPrice: 0,

    designs: [
      {
        name: '',
        numberOfPanels: 0,
        nominalPower: 0,
        visualization: '',
      },
    ],

    valuation: {
      complex: false,
      taxed: false,
      discounted: false,
      sections: {
        panels: [],
        inverters: [],
        others: [],
      },
      summary: {
        netPrice: 0,
        taxSum: 0,
        grossPrice: 0,
        discountSum: 0,
        totalPrice: 0,
        comment: '',
      },
    },

    financialAnalysis: {
      monthlyElectricityBill: 0,
      annualElectricityBill: 0,
      priceOfElectricity: 0,
      annualPriceIncreaseOfElectricity: 0,
      ownContributionRatio: 1,
      loanTerm: 0,

      forecast: {
        annualEnergyProduction: 0,
        annualEnergyConsumption: 0,
        annualEnergyFedIntoGrid: 0,
        annualGridEnergyConsumption: 0,

        priceOfElectricityAfterAnalysisPeriod: 0,
        upfrontCostsAfterIncentives: 0,
        savings: 0,
        averageAnnualSavings: 0,
        receivedSubsidies: 0,
        electricityCostsWithoutPVSystem: 0,
        electricityCostsWithPVSystem: 0,
        savingsIncludingPVSystemCosts: 0,
        monthlyLoanPayment: 0,

        energyProductionByYear: ANNUAL_ZEROS,
        savingsByYear: ANNUAL_ZEROS,
        receivedSubsidiesByYear: ANNUAL_ZEROS,
        loanPaymentsByYear: ANNUAL_ZEROS,
        cumulativeCashFlow: ANNUAL_ZEROS,
        electricityBillsWithoutPVByYear: ANNUAL_ZEROS,
        electricityBillsWithPVByYear: ANNUAL_ZEROS,
      },
    },

    environmentalImpact: {
      annualCO2Emission: 0,
      annualEnergyProduction: ANNUAL_ZEROS,
      annualEnergyProductionAsElectricCarDistance: ANNUAL_ZEROS,
      annualEnergyProductionAsSavedFuel: ANNUAL_ZEROS,
      annualEnergyProductionAsAvoidedCO2Emission: ANNUAL_ZEROS,
      annualEnergyProductionAsAvoidedNOxSOxEmission: ANNUAL_ZEROS,
      annualEnergyProductionAsNumberOfTreesPlanted: ANNUAL_ZEROS,
    },
  },
});

export const useProposalContext = (): ProposalContextData => {
  return useContext(ProposalContext);
};
