import { Box } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

import { ProposalPreviewDefaultSubsectionTitle } from './Title';

export interface ProposalPreviewDefaultSubsectionProps {
  title: string;
}

export const ProposalPreviewDefaultSubsection: FunctionComponent<ProposalPreviewDefaultSubsectionProps> =
  ({ title, children }) => {
    const renderedTitle = useMemo(
      () => <ProposalPreviewDefaultSubsectionTitle title={title} />,
      [title],
    );

    const wrappedChildren = useMemo(
      () => (
        <Box width="100%" paddingTop="3%">
          {children}
        </Box>
      ),
      [children],
    );

    const subsection = useMemo(
      () => (
        <Box width="100%">
          {renderedTitle}
          {wrappedChildren}
        </Box>
      ),
      [renderedTitle, wrappedChildren],
    );

    return subsection;
  };
