import { APIProposalContext } from '../../Common/models/APIProposalContextData';
import { ProposalContextData } from '../../Common/models/ProposalContextData';
import { ProposalContextService } from '../../Common/services/ProposalContextService';
import { easysolarAPI } from './easysolarAPI';

export const proposalContextAPI = easysolarAPI.injectEndpoints({
  endpoints: (builder) => ({
    getProposalContext: builder.query<ProposalContextData, string>({
      query: (projectId: string) => `/projects/${projectId}/proposal-context`,
      transformResponse: (response: APIProposalContext): ProposalContextData =>
        ProposalContextService.apiProposalContextToProposalContext(response),
    }),
  }),
});

export const { useGetProposalContextQuery } = proposalContextAPI;
