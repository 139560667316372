import { Grid } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ProposalLayoutName } from '@easysolar/proposals/modules/Common/models/Layouts/Base';
import { FillLoader } from '@easysolar/proposals/shared/components/FillLoader';

import { useNewestSupportedLayoutVersion } from '../../hooks/useNewestSupportedLayoutVersion';
import { useGetProposalContextQuery } from '../../services/proposalContextAPI';
import { selectProjectId } from '../../store/webComponentProps';
import { ProposalEditor } from '../Editor';
import { getProposalEditorWrapperStyles } from './EditorWrapper.styles';

export const ProposalEditorWrapper: FunctionComponent = () => {
  const classNames = getProposalEditorWrapperStyles();

  const projectId = useSelector(selectProjectId) as string;

  const {
    data: proposalContextData,
    isLoading: isLoadingProposalContext,
    isFetching: isFetchingProposalContext,
  } = useGetProposalContextQuery(projectId, {
    skip: !projectId,
  });

  const layoutName = ProposalLayoutName.Default;

  const {
    layoutVersion,
    isLoading: isLoadingLayoutVersion,
    isFetching: isFetchingLayoutVersion,
  } = useNewestSupportedLayoutVersion(layoutName);

  const isLoading = useMemo(
    () => isLoadingProposalContext || isLoadingLayoutVersion,
    [isLoadingLayoutVersion, isLoadingProposalContext],
  );

  const isFetching = useMemo(
    () => isFetchingProposalContext || isFetchingLayoutVersion,
    [isFetchingLayoutVersion, isFetchingProposalContext],
  );

  return (
    <>
      {isLoading && (
        <Grid
          container
          className={classNames.loaderContainer}
          alignContent="center"
          justifyContent="center"
        >
          <FillLoader />
        </Grid>
      )}
      {proposalContextData && layoutVersion && (
        <ProposalEditor
          contextData={proposalContextData}
          layoutName={layoutName}
          layoutVersion={layoutVersion}
          isFetching={isFetching}
        />
      )}
    </>
  );
};
