import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from 'react';

interface ProposalPreviewDefaultSectionPageStylesProps {
  logoCSSProperties?: {
    height?: CSSProperties['height'];
    opacity?: CSSProperties['opacity'];
  };
}

export const getProposalPreviewDefaultSectionPageStyles = makeStyles<
  Theme,
  ProposalPreviewDefaultSectionPageStylesProps
>(() => ({
  grid: {
    width: '100%',
    height: '100%',
  },
  logoContainer: {
    height: ({ logoCSSProperties }): CSSProperties['height'] =>
      logoCSSProperties?.height || '7%',
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'right',
    opacity: ({ logoCSSProperties }): CSSProperties['opacity'] =>
      logoCSSProperties?.opacity || 0.6,
  },
  content: {
    flex: 1,
    overflow: 'hidden',
  },
}));
