import {
  GridJustification,
  SvgIconTypeMap,
  Typography,
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { FunctionComponent } from 'react';

import { ProposalPreviewDefaultLabelWithIcon } from '../../LabelWithIcon';

interface ProposalPreviewDefaultContactSubsectionLabelProps {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  justifyContent?: GridJustification;
  text: string;
}

export const ProposalPreviewDefaultContactSubsectionLabel: FunctionComponent<ProposalPreviewDefaultContactSubsectionLabelProps> =
  ({ Icon, justifyContent, text }) => {
    return (
      <ProposalPreviewDefaultLabelWithIcon
        Icon={Icon}
        justifyContent={justifyContent}
      >
        <Typography color="textSecondary" variant="body2">
          {text}
        </Typography>
      </ProposalPreviewDefaultLabelWithIcon>
    );
  };
