import { Divider, Grid } from '@material-ui/core';
import { Fragment, FunctionComponent, useMemo } from 'react';

import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';
import { ProposalPreviewDefaultIcon } from '@easysolar/proposals/modules/ProposalPreview/components/Layouts/Default/Shared/SvgIcon';

import { ProposalPreviewDefaultSubsection } from '../../../Shared/Subsection';
import {
  ProposalPreviewDefaultProposedSystemEnergyBalanceFlow,
  ProposalPreviewDefaultProposedSystemEnergyBalanceFlowDirection,
  ProposalPreviewDefaultProposedSystemEnergyBalanceFlowProps,
} from './Flow';

export interface ProposalPreviewDefaultProposedSystemEnergyBalanceProps {
  title: string;
}

export const ProposalPreviewDefaultProposedSystemEnergyBalance: FunctionComponent<ProposalPreviewDefaultProposedSystemEnergyBalanceProps> =
  ({ title }) => {
    const {
      project: {
        financialAnalysis: { forecast },
      },
    } = useProposalContext();

    const flowsProps: ProposalPreviewDefaultProposedSystemEnergyBalanceFlowProps[] =
      useMemo(
        () => [
          {
            icon: ProposalPreviewDefaultIcon.Sun,
            direction:
              ProposalPreviewDefaultProposedSystemEnergyBalanceFlowDirection.Down,
            value: forecast.annualEnergyProduction,
          },
          {
            icon: ProposalPreviewDefaultIcon.Grid,
            direction:
              ProposalPreviewDefaultProposedSystemEnergyBalanceFlowDirection.Up,
            value: Math.max(0, forecast.annualEnergyFedIntoGrid),
          },
          {
            icon: ProposalPreviewDefaultIcon.Grid,
            direction:
              ProposalPreviewDefaultProposedSystemEnergyBalanceFlowDirection.Down,
            value: Math.max(0, forecast.annualGridEnergyConsumption),
          },
        ],
        [
          forecast.annualEnergyFedIntoGrid,
          forecast.annualEnergyProduction,
          forecast.annualGridEnergyConsumption,
        ],
      );

    const renderedFlows = useMemo(
      () =>
        flowsProps.map((flowProps, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <Divider orientation="vertical" flexItem />
            <ProposalPreviewDefaultProposedSystemEnergyBalanceFlow
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...flowProps}
            />
          </Fragment>
        )),
      [flowsProps],
    );

    const subsection = useMemo(
      () => (
        <ProposalPreviewDefaultSubsection title={title}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            wrap="nowrap"
          >
            {renderedFlows}
            <Divider orientation="vertical" flexItem />
          </Grid>
        </ProposalPreviewDefaultSubsection>
      ),
      [renderedFlows, title],
    );

    return subsection;
  };
