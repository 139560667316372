import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultInvestmentCostsEnergyCostsTableStyles =
  makeStyles((theme) => ({
    root: {
      paddingTop: '10mm',
    },
    labelContainer: {
      minHeight: '9mm',
    },
    label: {
      lineHeight: 1,
    },
    value: {
      fontWeight: theme.typography.fontWeightBold,
    },
    valueCurrency: {
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));
