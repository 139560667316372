export class ConfigurationService {
  public static getAppConfig(): AppConfig {
    return {
      publicUrl: process.env.PUBLIC_URL,
      debug: process.env.REACT_APP_DEBUG === 'true',
      apiUrl:
        process.env.REACT_APP_API_URL ||
        'https://api-development.easysolar-app.com',
      useMocks: process.env.REACT_APP_USE_MOCKS === 'true',
    };
  }

  public static APP_CONFIG = ConfigurationService.getAppConfig();
}

export interface AppConfig {
  publicUrl: string;
  debug: boolean;
  apiUrl: string;
  useMocks: boolean;
}
