import { ProposalLayoutVersion } from '../../Common/models/ProposalLayoutVersion';
import { GetProposalLayoutVersionParams } from '../models/GetProposalLayoutVersion';
import { easysolarAPI } from './easysolarAPI';

export const proposalLayoutVersionAPI = easysolarAPI.injectEndpoints({
  endpoints: (builder) => ({
    getProposalLayoutVersion: builder.query<
      ProposalLayoutVersion,
      GetProposalLayoutVersionParams
    >({
      query: ({
        projectId,
        layoutName,
        layoutVersion,
      }: GetProposalLayoutVersionParams) =>
        `/projects/${projectId}/proposal-layouts/${layoutName}/versions/${layoutVersion}`,
    }),
  }),
});

export const { useGetProposalLayoutVersionQuery } = proposalLayoutVersionAPI;
