import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { FunctionComponent, SVGProps, useMemo } from 'react';

import { ReactComponent as AirPollutionIcon } from '@easysolar/proposals/assets/layouts/default/icons/air-pollution.svg';
import { ReactComponent as ChevronDownIcon } from '@easysolar/proposals/assets/layouts/default/icons/chevron-down.svg';
import { ReactComponent as ChevronRightIcon } from '@easysolar/proposals/assets/layouts/default/icons/chevron-right.svg';
import { ReactComponent as ChevronUpIcon } from '@easysolar/proposals/assets/layouts/default/icons/chevron-up.svg';
import { ReactComponent as ElectricCarIcon } from '@easysolar/proposals/assets/layouts/default/icons/electric-car.svg';
import { ReactComponent as FuelIcon } from '@easysolar/proposals/assets/layouts/default/icons/fuel.svg';
import { ReactComponent as GridIcon } from '@easysolar/proposals/assets/layouts/default/icons/grid.svg';
import { ReactComponent as HomeIcon } from '@easysolar/proposals/assets/layouts/default/icons/home.svg';
import { ReactComponent as HomeWithPanelsIcon } from '@easysolar/proposals/assets/layouts/default/icons/home-with-panels.svg';
import { ReactComponent as LocationIcon } from '@easysolar/proposals/assets/layouts/default/icons/location.svg';
import { ReactComponent as ModuleIcon } from '@easysolar/proposals/assets/layouts/default/icons/module.svg';
import { ReactComponent as PersonIcon } from '@easysolar/proposals/assets/layouts/default/icons/person.svg';
import { ReactComponent as PhoneIcon } from '@easysolar/proposals/assets/layouts/default/icons/phone.svg';
import { ReactComponent as SewageIcon } from '@easysolar/proposals/assets/layouts/default/icons/sewage.svg';
import { ReactComponent as SunIcon } from '@easysolar/proposals/assets/layouts/default/icons/sun.svg';
import { ReactComponent as SunPowerIcon } from '@easysolar/proposals/assets/layouts/default/icons/sun-power.svg';
import { ReactComponent as TreesIcon } from '@easysolar/proposals/assets/layouts/default/icons/trees.svg';

export enum ProposalPreviewDefaultIcon {
  AirPollution = 'air-pollution',
  ChevronDown = 'chevron-down',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  ElectricCar = 'electric-car',
  Fuel = 'fuel',
  Grid = 'grid',
  Home = 'home',
  HomeWithPanels = 'home-with-panels',
  Location = 'location',
  Module = 'module',
  Person = 'person',
  Phone = 'phone',
  Sewage = 'sewage',
  Sun = 'sun',
  SunPower = 'sun-power',
  Trees = 'trees',
}

const ProposalPreviewDefaultSvgIconMapping: Record<
  ProposalPreviewDefaultIcon,
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  [ProposalPreviewDefaultIcon.AirPollution]: AirPollutionIcon,
  [ProposalPreviewDefaultIcon.ChevronDown]: ChevronDownIcon,
  [ProposalPreviewDefaultIcon.ChevronRight]: ChevronRightIcon,
  [ProposalPreviewDefaultIcon.ChevronUp]: ChevronUpIcon,
  [ProposalPreviewDefaultIcon.ElectricCar]: ElectricCarIcon,
  [ProposalPreviewDefaultIcon.Fuel]: FuelIcon,
  [ProposalPreviewDefaultIcon.Grid]: GridIcon,
  [ProposalPreviewDefaultIcon.Home]: HomeIcon,
  [ProposalPreviewDefaultIcon.HomeWithPanels]: HomeWithPanelsIcon,
  [ProposalPreviewDefaultIcon.Location]: LocationIcon,
  [ProposalPreviewDefaultIcon.Module]: ModuleIcon,
  [ProposalPreviewDefaultIcon.Person]: PersonIcon,
  [ProposalPreviewDefaultIcon.Phone]: PhoneIcon,
  [ProposalPreviewDefaultIcon.Sewage]: SewageIcon,
  [ProposalPreviewDefaultIcon.Sun]: SunIcon,
  [ProposalPreviewDefaultIcon.SunPower]: SunPowerIcon,
  [ProposalPreviewDefaultIcon.Trees]: TreesIcon,
};

export interface ProposalPreviewDefaultSvgIconProps extends SvgIconProps {
  icon: ProposalPreviewDefaultIcon;
}

export const ProposalPreviewDefaultSvgIcon: FunctionComponent<ProposalPreviewDefaultSvgIconProps> =
  ({ icon, ...props }) => {
    const Icon = useMemo(
      () => ProposalPreviewDefaultSvgIconMapping[icon],
      [icon],
    );

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <SvgIcon {...props}>
        <Icon />
      </SvgIcon>
    );
  };
