import { Grid } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { getProposalPreviewDefaultContactSubsectionRowStyles } from './Row.styles';

export const ProposalPreviewDefaultContactSubsectionRow: FunctionComponent = ({
  children,
}) => {
  const classes = getProposalPreviewDefaultContactSubsectionRowStyles();

  return (
    <Grid
      item
      container
      className={classes.root}
      direction="row"
      wrap="nowrap"
      justifyContent="space-between"
    >
      {children}
    </Grid>
  );
};
