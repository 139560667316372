import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultProposedSystemEnergyBalanceFlowStyles =
  makeStyles((theme) => ({
    icon: {
      fontSize: '9mm',
    },
    flowWithValue: {
      padding: '1.5mm 0',
    },
    flow: {
      fontSize: '5mm',
    },
    value: {
      padding: '2mm 0',
    },
    nonUnit: {
      fontWeight: theme.typography.fontWeightBold,
    },
    unit: {
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));
