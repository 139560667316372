import { ChangeEvent, FunctionComponent, useCallback, useMemo } from 'react';

import { ProposalLayoutSectionData } from '@easysolar/proposals/modules/Common/models/LayoutData/Section';
import { ProposalLayoutSectionSchema } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Section';
import { useFormControl } from '@easysolar/proposals/shared/forms/hooks/useFormControl';
import { FormGroup } from '@easysolar/proposals/shared/forms/models';

import { ProposalEditorSideBarSettings } from '../Settings';

interface ProposalEditorSideBarSectionProps {
  sectionSchema: ProposalLayoutSectionSchema;
  formGroup: FormGroup<ProposalLayoutSectionData>;
  disabled: boolean;
  expanded: boolean;
  onExpand: (isExpanded: boolean) => void;
}

export const ProposalEditorSideBarSection: FunctionComponent<ProposalEditorSideBarSectionProps> =
  ({ sectionSchema, formGroup, disabled, expanded, onExpand }) => {
    const onSettingsExpand = useCallback(
      (
        _event: ChangeEvent<Record<string, unknown>>,
        isExpanded: boolean,
      ): void => {
        onExpand(isExpanded);
      },
      [onExpand],
    );

    const { value: enabled } = useFormControl(formGroup.controls.enabled);

    const renderedSettings = useMemo(
      () => (
        <ProposalEditorSideBarSettings
          expanded={expanded}
          onExpand={onSettingsExpand}
          groupOfSettingsSchema={sectionSchema.properties.settings}
          formGroup={formGroup.controls.settings as FormGroup}
          disabled={disabled || !enabled}
        />
      ),
      [
        disabled,
        enabled,
        expanded,
        formGroup.controls.settings,
        onSettingsExpand,
        sectionSchema.properties.settings,
      ],
    );

    return renderedSettings;
  };
