import { JSONSchemaType } from 'ajv';

export enum ProposalLayoutSettingTypeName {
  Text = 'text',
  Color = 'color',
  Boolean = 'boolean',
  ToggleableText = 'toggleableText',
  Image = 'image',
  Choice = 'choice',
}

export type ProposalLayoutSettingSchemaBase<
  SettingTypeName extends ProposalLayoutSettingTypeName,
  SettingType,
  AdditionalProperties = {},
> = JSONSchemaType<SettingType> & {
  title: string;
  settingType: SettingTypeName;
  default: SettingType;
} & AdditionalProperties;
