import { DireflowComponent } from 'direflow-component';

import { ConfigurationService } from '@easysolar/proposals/infrastructure/services/ConfigurationService';

import { TEST_PROPOSAL_CONTEXT_DATA } from '../Common/data/ProposalContextData';
import { PROPOSAL_PREVIEW_WEB_COMPONENT_TAG_NAME } from './api';
import {
  ProposalPreviewWrapper,
  ProposalPreviewWrapperProps,
} from './components/PreviewWrapper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const properties: Record<keyof ProposalPreviewWrapperProps, any> = {
  language: undefined,
  printing: true,
  apiContextData: null,
  contextData: ConfigurationService.APP_CONFIG.useMocks
    ? TEST_PROPOSAL_CONTEXT_DATA
    : null,
  layoutName: null,
  layoutVersion: null,
  layoutData: null,
  activeSectionName: null,
  onRendered: null,
};

export const ProposalPreviewWebComponent = DireflowComponent.create({
  component: ProposalPreviewWrapper,
  configuration: {
    tagname: PROPOSAL_PREVIEW_WEB_COMPONENT_TAG_NAME,
  },
  properties,
  plugins: [
    {
      name: 'material-ui',
    },
  ],
});
