import {
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import { FunctionComponent, useCallback, useMemo } from 'react';
import * as React from 'react';

import {
  TextFieldErrorType,
  TypedErrorTextField,
} from '../TypedErrorTextField';
import { getToggleableTextFieldStyles } from './ToggleableTextField.styles';

export interface ToggleableTextFieldProps {
  value: ToggleableText;
  onChange?: (value: ToggleableText) => void;
  label: string;
  maxLength?: number;
  textErrorType?: TextFieldErrorType | null;
}

export const ToggleableTextField: FunctionComponent<ToggleableTextFieldProps> =
  ({ value, onChange, label, maxLength, textErrorType }) => {
    const classNames = getToggleableTextFieldStyles();

    const onPartialChange = useCallback(
      (changeValue: Partial<ToggleableText>) => {
        if (onChange) {
          onChange({
            ...value,
            ...changeValue,
          });
        }
      },
      [onChange, value],
    );

    const onSwitchChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onPartialChange({
          enabled: event.target.checked,
        });
      },
      [onPartialChange],
    );

    const switchControl = useMemo(
      () => (
        <Switch
          className={classNames.switch}
          color="primary"
          disabled={!!textErrorType}
          checked={value.enabled}
          onChange={onSwitchChange}
        />
      ),
      [classNames.switch, onSwitchChange, textErrorType, value.enabled],
    );

    const onTextFieldChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onPartialChange({
          text: event.target.value,
        });
      },
      [onPartialChange],
    );

    const textField = useMemo(
      () => (
        <TypedErrorTextField
          className={classNames.textField}
          disabled={!value.enabled}
          maxLength={maxLength}
          errorType={textErrorType}
          value={value.text}
          onChange={onTextFieldChange}
        />
      ),
      [
        classNames.textField,
        textErrorType,
        maxLength,
        onTextFieldChange,
        value.enabled,
        value.text,
      ],
    );

    return (
      <FormControl margin="normal" className={classNames.formControl}>
        <Typography color="textSecondary">{label}</Typography>
        <FormControlLabel
          className={classNames.formControlLabel}
          classes={{
            label: classNames.label,
          }}
          control={switchControl}
          labelPlacement="end"
          label={textField}
        />
      </FormControl>
    );
  };

export interface ToggleableText {
  enabled: boolean;
  text: string;
}
