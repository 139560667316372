import { Divider, Grid } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

import { ProposalLayoutName } from '@easysolar/proposals/modules/Common/models/Layouts/Base';
import { ProposalContextData } from '@easysolar/proposals/modules/Common/models/ProposalContextData';
import { ProposalLayoutVersion } from '@easysolar/proposals/modules/Common/models/ProposalLayoutVersion';

import { useLastValidFormData } from '../../hooks/useLastValidFormData';
import { useProposalForm } from '../../hooks/useProposalForm';
import { useCreateProposalSharedMutation } from '../../services/proposalsAPI';
import {
  useCreateProposalTemplateSharedMutation,
  useGetProposalTemplatesQuery,
  useUpdateProposalTemplateSharedMutation,
} from '../../services/proposalTemplatesAPI';
import { ProposalLayoutEditor } from '../LayoutEditor';
import { ProposalEditorToolbar } from '../Toolbar';
import { useProposalEditorStyles } from './Editor.styles';

export interface ProposalEditorProps {
  contextData: ProposalContextData;
  layoutName: ProposalLayoutName;
  layoutVersion: ProposalLayoutVersion;
  isFetching?: boolean;
}

export const ProposalEditor: FunctionComponent<ProposalEditorProps> = ({
  contextData,
  layoutName,
  layoutVersion,
  isFetching = false,
}) => {
  const classes = useProposalEditorStyles();

  const { isFetching: isFetchingProposalTemplates } =
    useGetProposalTemplatesQuery({ layoutName });

  const proposalForm = useProposalForm(layoutVersion.schema);

  const lastValidFormData = useLastValidFormData(proposalForm);

  const [, { isLoading: isCreatingProposalTemplate }] =
    useCreateProposalTemplateSharedMutation();

  const [, { isLoading: isUpdatingProposalTemplate }] =
    useUpdateProposalTemplateSharedMutation();

  const [, { isLoading: isCreatingProposal }] =
    useCreateProposalSharedMutation();

  const isLoading = useMemo(
    () =>
      isFetching ||
      isCreatingProposal ||
      isFetchingProposalTemplates ||
      isCreatingProposalTemplate ||
      isUpdatingProposalTemplate,
    [
      isCreatingProposal,
      isCreatingProposalTemplate,
      isFetching,
      isFetchingProposalTemplates,
      isUpdatingProposalTemplate,
    ],
  );

  const toolbar = useMemo(
    () => (
      <ProposalEditorToolbar
        layoutVersion={layoutVersion}
        proposalForm={proposalForm}
        disabled={isLoading}
      />
    ),
    [layoutVersion, proposalForm, isLoading],
  );

  const layoutEditor = useMemo(
    () => (
      <ProposalLayoutEditor
        contextData={contextData}
        layoutVersion={layoutVersion}
        proposalForm={proposalForm}
        lastValidFormData={lastValidFormData}
        disabled={isLoading}
      />
    ),
    [contextData, isLoading, lastValidFormData, layoutVersion, proposalForm],
  );

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>{toolbar}</Grid>
      <Divider />
      <Grid item container wrap="nowrap" className={classes.layoutEditor}>
        {layoutEditor}
      </Grid>
    </Grid>
  );
};
