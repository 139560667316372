import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultSubsectionTitleProps = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      paddingTop: '8mm',
    },
    title: {
      paddingBottom: '1%',
      color: theme.palette.text.secondary,
      textTransform: 'uppercase',
      lineHeight: 1,
      fontWeight: theme.typography.fontWeightLight,
    },
  }),
);
