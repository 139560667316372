import { ProposalLayoutGroupOfSettingsSchema } from '../../../../LayoutSchema/GroupOfSettings';
import { ProposalLayoutSettingSchemaBoolean } from '../../../../LayoutSchema/Settings/Boolean';
import { ProposalLayoutSettingSchemaText } from '../../../../LayoutSchema/Settings/Text';

export enum ProposalLayoutDefaultSectionName {
  CoverPage = 'coverPage',
  ProposedSystem = 'proposedSystem',
  SystemDesign = 'systemDesign',
  CustomerProposal = 'customerProposal',
  EnergyProductionAndEnvironmentalImpact = 'energyProductionAndEnvironmentalImpact',
  InvestmentCosts = 'investmentCosts',
  CashFlow = 'cashFlow',
}

export type ContactSubsection = ProposalLayoutGroupOfSettingsSchema<{
  title?: ProposalLayoutSettingSchemaText;
  showCompanyName?: ProposalLayoutSettingSchemaBoolean;
  showEmployeeName?: ProposalLayoutSettingSchemaBoolean;
  showEmployeeEmailAddress?: ProposalLayoutSettingSchemaBoolean;
  showEmployeePhoneNumber?: ProposalLayoutSettingSchemaBoolean;
}>;
