import { Divider, Grid, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { ProposalLayoutGroupOfSettingsSchema } from '@easysolar/proposals/modules/Common/models/LayoutSchema/GroupOfSettings';
import { FormGroup } from '@easysolar/proposals/shared/forms/models';

import { ProposalEditorSettings } from '../Settings';
import { getProposalEditorGroupOfSettingsStyles } from './GroupOfSettings.styles';

export interface ProposalEditorGroupOfSettingsProps {
  formGroup: FormGroup;
  groupOfSettingsSchema: ProposalLayoutGroupOfSettingsSchema;
}

export const ProposalEditorGroupOfSettings: FunctionComponent<ProposalEditorGroupOfSettingsProps> =
  ({ formGroup, groupOfSettingsSchema }) => {
    const classNames = getProposalEditorGroupOfSettingsStyles();

    return (
      <Grid container direction="column">
        <Divider />
        <Typography className={classNames.title} variant="body1">
          {groupOfSettingsSchema.title}
        </Typography>
        <ProposalEditorSettings
          formGroup={formGroup}
          groupOfSettingsSchema={groupOfSettingsSchema}
        />
      </Grid>
    );
  };
