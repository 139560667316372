import { ThemeProvider, useTheme } from '@material-ui/core';
import { FunctionComponent, ReactElement, useCallback, useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { ProposalLayoutArrayOfSectionsData } from '@easysolar/proposals/modules/Common/models/LayoutData/Layout';
import { ProposalLayoutSectionSchema } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Section';
import { useFormArray } from '@easysolar/proposals/shared/forms/hooks/useFormArray';
import { FormArray } from '@easysolar/proposals/shared/forms/models';

import { ProposalEditorSideBarSectionsListDraggableItem } from '../DraggableItem';
import { getProposalEditorSideBarSectionsListDroppableContainerStyles } from './DroppableContainer.styles';

interface ProposalEditorSideBarSectionsListDroppableContainerProps {
  sectionsForm: FormArray<ProposalLayoutArrayOfSectionsData>;
  sectionsSchemasByName: Record<string, ProposalLayoutSectionSchema>;
  disabled: boolean;
}

export const ProposalEditorSideBarSectionsListDroppableContainer: FunctionComponent<ProposalEditorSideBarSectionsListDroppableContainerProps> =
  ({ sectionsForm, sectionsSchemasByName, disabled }) => {
    const classes =
      getProposalEditorSideBarSectionsListDroppableContainerStyles();

    const { value: sectionsFormData } = useFormArray(sectionsForm);

    const items = useMemo(
      () =>
        sectionsFormData.map((sectionFormData, index) => (
          <ProposalEditorSideBarSectionsListDraggableItem
            key={sectionFormData.name}
            index={index}
            sectionForm={sectionsForm.controls[index]}
            sectionSchema={sectionsSchemasByName[sectionFormData.name]}
            disabled={disabled}
          />
        )),
      [
        disabled,
        sectionsForm.controls,
        sectionsFormData,
        sectionsSchemasByName,
      ],
    );

    const theme = useTheme();

    const renderProvidedContainer = useCallback(
      (provided): ReactElement<HTMLElement> => (
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={classes.container}
        >
          <ThemeProvider theme={theme}>
            {items}
            {provided.placeholder}
          </ThemeProvider>
        </div>
      ),
      [classes.container, items, theme],
    );

    const droppable = useMemo(
      () => (
        <Droppable droppableId="ProposalEditorSideBarSectionsListDroppableContainer">
          {renderProvidedContainer}
        </Droppable>
      ),
      [renderProvidedContainer],
    );

    return droppable;
  };
