import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultContactSubsectionRowStyles = makeStyles(
  () => ({
    root: {
      '&:not(:first-child)': {
        paddingTop: '4.5mm',
      },
    },
  }),
);
