import { useMemo } from 'react';

import {
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName,
} from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema';

import { ProposalPreviewSection } from '../../../../Section';
import { ProposalPreviewDefaultSectionPage } from '../../Shared/SectionPage';
import { ProposalPreviewDefaultEnergyProduction } from './EnergyProduction';
import { ProposalPreviewDefaultEnvironmentalImpact } from './EnvironmentalImpact';

export const ProposalPreviewDefaultEnergyProductionAndEnvironmentalImpact: ProposalPreviewSection<
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName.EnergyProductionAndEnvironmentalImpact
> = ({ generalSettings, sectionSettings }) => {
  const energyProduction = useMemo(
    () => (
      <ProposalPreviewDefaultEnergyProduction
        title={sectionSettings.energyProduction.title}
        source={sectionSettings.energyProduction.source}
      />
    ),
    [
      sectionSettings.energyProduction.source,
      sectionSettings.energyProduction.title,
    ],
  );

  const energyProductionElement = useMemo(
    () => sectionSettings.energyProduction.showSubsection && energyProduction,
    [energyProduction, sectionSettings.energyProduction.showSubsection],
  );

  const environmentalImpact = useMemo(
    () => (
      <ProposalPreviewDefaultEnvironmentalImpact
        title={sectionSettings.environmentalImpact.title}
      />
    ),
    [sectionSettings.environmentalImpact.title],
  );

  const environmentalImpactElement = useMemo(
    () =>
      sectionSettings.environmentalImpact.showSubsection && environmentalImpact,
    [environmentalImpact, sectionSettings.environmentalImpact.showSubsection],
  );

  const page = useMemo(
    () => (
      <ProposalPreviewDefaultSectionPage
        generalSettings={generalSettings}
        title={sectionSettings.title}
        subtitle={sectionSettings.subtitle}
      >
        {energyProductionElement}
        {environmentalImpactElement}
      </ProposalPreviewDefaultSectionPage>
    ),
    [
      energyProductionElement,
      environmentalImpactElement,
      generalSettings,
      sectionSettings.subtitle,
      sectionSettings.title,
    ],
  );

  return page;
};
