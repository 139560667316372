import { createTheme } from '@material-ui/core';

export const theme = createTheme({
  typography: {
    fontFamily: "'Roboto', Arial, sans-serif",
  },
  palette: {
    primary: {
      main: '#0099a1',
    },
    secondary: {
      main: '#454545',
    },
  },
});
