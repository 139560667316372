import { makeStyles } from '@material-ui/core';

export const getProposalEditorSideBarStyles = makeStyles((theme) => ({
  container: {
    width: 400,
    margin: `${theme.spacing(2)}px auto`,
  },
  sectionSettings: {
    paddingTop: theme.spacing(2),
  },
  sectionSettingsTitle: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
  },
}));
