import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';

import { CreateProposalTemplateData } from '../models/CreateProposalTemplate';
import { ProposalTemplate } from '../models/ProposalTemplate';
import { UpdateProposalTemplateData } from '../models/UpdateProposalTemplate';
import { easysolarAPI } from './easysolarAPI';
import {
  CREATE_PROPOSAL_TEMPLATE_MUTATION_FIXED_CACHE_KEY,
  proposalTemplatesAPI,
  UPDATE_PROPOSAL_TEMPLATE_MUTATION_FIXED_CACHE_KEY,
} from './proposalTemplatesAPI';

const { createProposalTemplate, updateProposalTemplate } =
  proposalTemplatesAPI.endpoints;

export const editorAPI = easysolarAPI.injectEndpoints({
  endpoints: (builder) => ({
    saveProposalTemplate: builder.mutation<
      ProposalTemplate,
      CreateProposalTemplateData | UpdateProposalTemplateData
    >({
      queryFn: async (saveProposalTemplateData, api) => {
        const { id } = saveProposalTemplateData as UpdateProposalTemplateData;

        if (id === undefined) {
          return api.dispatch(
            createProposalTemplate.initiate(
              saveProposalTemplateData as CreateProposalTemplateData,
              {
                fixedCacheKey:
                  CREATE_PROPOSAL_TEMPLATE_MUTATION_FIXED_CACHE_KEY,
              },
            ),
          ) as MaybePromise<
            QueryReturnValue<ProposalTemplate, FetchBaseQueryError>
          >;
        }

        return api.dispatch(
          updateProposalTemplate.initiate(
            saveProposalTemplateData as UpdateProposalTemplateData,
            {
              fixedCacheKey: UPDATE_PROPOSAL_TEMPLATE_MUTATION_FIXED_CACHE_KEY,
            },
          ),
        ) as MaybePromise<
          QueryReturnValue<ProposalTemplate, FetchBaseQueryError>
        >;
      },
    }),
    renameProposalTemplate: builder.mutation<
      ProposalTemplate,
      UpdateProposalTemplateData
    >({
      queryFn: async (updateProposalTemplateData, api) => {
        return api.dispatch(
          updateProposalTemplate.initiate(updateProposalTemplateData, {
            fixedCacheKey: UPDATE_PROPOSAL_TEMPLATE_MUTATION_FIXED_CACHE_KEY,
          }),
        ) as MaybePromise<
          QueryReturnValue<ProposalTemplate, FetchBaseQueryError>
        >;
      },
    }),
    cloneProposalTemplate: builder.mutation<
      ProposalTemplate,
      CreateProposalTemplateData
    >({
      queryFn: async (createProposalTemplateData, api) => {
        return api.dispatch(
          createProposalTemplate.initiate(createProposalTemplateData, {
            fixedCacheKey: CREATE_PROPOSAL_TEMPLATE_MUTATION_FIXED_CACHE_KEY,
          }),
        ) as MaybePromise<
          QueryReturnValue<ProposalTemplate, FetchBaseQueryError>
        >;
      },
    }),
  }),
});

export const {
  useSaveProposalTemplateMutation,
  useRenameProposalTemplateMutation,
  useCloneProposalTemplateMutation,
} = editorAPI;
