import { FunctionComponent } from 'react';

import { ColorField } from '../ColorField';
import { FieldWrapperProps } from '../ControlledField';

export interface ColorFieldWrapperProps extends FieldWrapperProps<string> {
  label: string;
  predefinedColors: string[];
}

export const ColorFieldWrapper: FunctionComponent<ColorFieldWrapperProps> = ({
  value,
  onChange,
  label,
  predefinedColors,
}) => {
  return (
    <ColorField
      value={value}
      onChange={onChange}
      label={label}
      colors={predefinedColors}
    />
  );
};
