import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from 'react';

interface ProposalPreviewDefaultSectionPageFooterTextStylesProps {
  color?: CSSProperties['color'];
  fontWeight?: CSSProperties['fontWeight'];
}

export const getProposalPreviewDefaultSectionPageFooterTextStyles = makeStyles<
  Theme,
  ProposalPreviewDefaultSectionPageFooterTextStylesProps
>((theme) => ({
  root: {
    color: ({ color }): CSSProperties['color'] =>
      color || theme.palette.text.secondary,
    fontWeight: ({ fontWeight }): CSSProperties['fontWeight'] => fontWeight,
  },
}));
