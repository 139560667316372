import { Grid, MenuItem, SelectProps } from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SaveIcon from '@material-ui/icons/Save';
import { keyBy } from 'lodash';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ProposalLayoutAnyData } from '@easysolar/proposals/modules/Common/models/LayoutData/Layout';
import { ProposalLayoutSectionData } from '@easysolar/proposals/modules/Common/models/LayoutData/Section';
import { ProposalLayoutVersion } from '@easysolar/proposals/modules/Common/models/ProposalLayoutVersion';
import { ShadowRootSelect } from '@easysolar/proposals/shared/components/ShadowRootMaterialUI/Select';
import { useFormArray } from '@easysolar/proposals/shared/forms/hooks/useFormArray';
import { useFormGroup } from '@easysolar/proposals/shared/forms/hooks/useFormGroup';
import { FormGroup } from '@easysolar/proposals/shared/forms/models';

import { useLastValidFormData } from '../../../hooks/useLastValidFormData';
import { ProposalTemplatesPermission } from '../../../models/ProposalTemplatesPermission';
import {
  useCloneProposalTemplateMutation,
  useRenameProposalTemplateMutation,
  useSaveProposalTemplateMutation,
} from '../../../services/editorAPI';
import { ProposalFormBuilder } from '../../../services/ProposalFormBuilder';
import { useDeleteProposalTemplateSharedMutation } from '../../../services/proposalTemplatesAPI';
import {
  selectGetProposalLayoutTemplates,
  selectProposalTemplatesById,
} from '../../../store/proposalTemplates';
import { RootState } from '../../../store/root';
import { selectEmployeeProposalTemplatesPermission } from '../../../store/webComponentProps';
import { getProposalEditorToolbarTemplatesSectionStyles } from './TemplatesSection.styles';
import { useProposalTemplateConfirmAction } from './useProposalTemplateConfirmAction';
import { useProposalTemplateDiscardChangesDialog } from './useProposalTemplateDiscardChangesDialog';
import { useProposalTemplateNameAction } from './useProposalTemplateNameAction';

interface ProposalEditorToolbarTemplatesSectionProps {
  layoutVersion: ProposalLayoutVersion;
  proposalForm: FormGroup<ProposalLayoutAnyData>;
  selectedTemplateId: string | null;
  disabled: boolean;
  onSelectedTemplateIdChange: (templateId: string | null) => void;
}

// Select doesn't work with null id, we have to provide dummy string
const NEW_TEMPLATE_ID = 'new';

const templateIdToMenuItemId = (templateId: string | null): string =>
  templateId || NEW_TEMPLATE_ID;

const menuItemIdToTemplateId = (menuItemId: string): string | null =>
  menuItemId === NEW_TEMPLATE_ID ? null : menuItemId;

export const ProposalEditorToolbarTemplatesSection: FunctionComponent<ProposalEditorToolbarTemplatesSectionProps> =
  ({
    layoutVersion,
    proposalForm,
    selectedTemplateId,
    disabled,
    onSelectedTemplateIdChange: setSelectedTemplateId,
  }) => {
    const { t } = useTranslation();

    const permission = useSelector(selectEmployeeProposalTemplatesPermission);
    const canEmployeeEditTemplates = useMemo(
      () => permission === ProposalTemplatesPermission.FullAccess,
      [permission],
    );

    const templates = useSelector((state: RootState) =>
      selectGetProposalLayoutTemplates(state, layoutVersion.name),
    );
    const templatesById = useSelector(selectProposalTemplatesById);

    const {
      valid,
      dirty,
      reset: resetProposalForm,
    } = useFormGroup(proposalForm);
    const { insert: insertSectionControl, remove: removeSectionControl } =
      useFormArray(proposalForm.controls.sections);
    const lastValidFormData = useLastValidFormData(proposalForm);

    const selectedTemplate = selectedTemplateId
      ? templatesById[selectedTemplateId]
      : null;

    const defaultLayoutData = useMemo(() => {
      return new ProposalFormBuilder().getProposalForm(layoutVersion.schema)
        .value;
    }, [layoutVersion.schema]);

    const selectedTemplateInitialData: ProposalLayoutAnyData = useMemo(() => {
      // updated every time the layout or selectedTemplate changes
      if (!selectedTemplate) {
        return defaultLayoutData;
      }

      if (selectedTemplate.layoutVersion < layoutVersion.version) {
        return ProposalFormBuilder.mergeLayouts(
          selectedTemplate.layoutData,
          defaultLayoutData,
        );
      }

      return selectedTemplate.layoutData;
    }, [defaultLayoutData, layoutVersion.version, selectedTemplate]);

    useEffect(() => {
      // useForm method do not change,
      // therefore it runs only when the layout or selectedTemplate changes

      // need to reorder the sections' controls before updating their value
      const sectionsControlsByName =
        selectedTemplateInitialData.sections.reduce((result) => {
          const control = removeSectionControl(
            0,
          ) as FormGroup<ProposalLayoutSectionData>;

          // eslint-disable-next-line no-param-reassign
          result[control.value.name] = control;

          return result;
        }, {} as Record<string, FormGroup<ProposalLayoutSectionData>>);

      selectedTemplateInitialData.sections.forEach((section, index) => {
        insertSectionControl(index, sectionsControlsByName[section.name]);
      });

      resetProposalForm(selectedTemplateInitialData);
    }, [
      insertSectionControl,
      removeSectionControl,
      resetProposalForm,
      selectedTemplateInitialData,
    ]);

    const {
      dialog: discardChangesDialog,
      openDialog: openDiscardChangesDialog,
    } = useProposalTemplateDiscardChangesDialog({
      onDiscard: setSelectedTemplateId,
    });

    const onTemplateChange = useCallback<
      Exclude<SelectInputProps['onChange'], undefined>
    >(
      (event) => {
        const { value } = event.target;
        const templateId = menuItemIdToTemplateId(value as string);

        openDiscardChangesDialog(templateId, dirty);
      },
      [dirty, openDiscardChangesDialog],
    );

    const isNewTemplateSelected = useMemo(
      () => selectedTemplateId === null,
      [selectedTemplateId],
    );

    const classNames = getProposalEditorToolbarTemplatesSectionStyles({
      isNewTemplateSelected,
    });

    const menuItems = useMemo<TemplateMenuItem[]>(
      () => [
        {
          id: NEW_TEMPLATE_ID,
          name: t('Editor:Toolbar.Templates.NewTemplateLabel'),
          className: classNames.newTemplateMenuItem,
        },
        ...(templates || [])
          ?.map((template) => ({
            id: template.id,
            name: template.name,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      ],
      [classNames.newTemplateMenuItem, t, templates],
    );

    const menuItemsById = useMemo(
      () => keyBy(menuItems, (menuItem) => menuItem.id),
      [menuItems],
    );

    const renderSelectValue = useCallback(
      (templateId: string): string => {
        const menuItem = menuItemsById[templateId];

        return `${menuItem.name}${dirty ? ' *' : ''}`;
      },
      [dirty, menuItemsById],
    );

    const selectedMenuItemId = useMemo(
      () => templateIdToMenuItemId(selectedTemplateId),
      [selectedTemplateId],
    );
    const select = useMemo(
      () => (
        <ShadowRootSelect
          variant="outlined"
          classes={{
            root: classNames.select,
          }}
          disabled={disabled}
          value={selectedMenuItemId}
          onChange={onTemplateChange}
          renderValue={renderSelectValue as SelectProps['renderValue']}
        >
          {menuItems.map((menuItem) => (
            <MenuItem
              key={menuItem.id}
              value={menuItem.id}
              classes={{ root: menuItem.className }}
            >
              {menuItem.name}
            </MenuItem>
          ))}
        </ShadowRootSelect>
      ),
      [
        classNames.select,
        disabled,
        menuItems,
        onTemplateChange,
        renderSelectValue,
        selectedMenuItemId,
      ],
    );

    const selectedMenuItemName = useMemo(
      () => menuItemsById[selectedMenuItemId].name,
      [menuItemsById, selectedMenuItemId],
    );

    const [
      saveProposalTemplate,
      { data: savedProposalTemplate, isLoading: isSavingProposalTemplate },
    ] = useSaveProposalTemplateMutation();

    const onSave = useCallback(
      (templateName) => {
        saveProposalTemplate({
          id: selectedTemplateId === null ? undefined : selectedTemplateId,
          name: templateName,
          layoutName: layoutVersion.name,
          layoutVersion: layoutVersion.version,
          layoutData: lastValidFormData,
        });
      },
      [
        lastValidFormData,
        layoutVersion.name,
        layoutVersion.version,
        saveProposalTemplate,
        selectedTemplateId,
      ],
    );

    const { button: saveButton } = useProposalTemplateNameAction({
      button: {
        tooltip: t('Editor:Toolbar.Templates.Actions.Save.ButtonTooltip'),
        IconComponent: SaveIcon,
      },
      dialog: {
        title: t('Editor:Toolbar.Templates.Actions.Save.Dialog.Title'),
        confirmButtonLabel: t(
          'Editor:Toolbar.Templates.Actions.Save.Dialog.ConfirmButtonLabel',
        ),
      },
      disabled: disabled || (!isNewTemplateSelected && !dirty) || !valid,
      loading: isSavingProposalTemplate,
      initialTemplateName: selectedMenuItemName,
      onConfirm: onSave,
    });

    useEffect(() => {
      if (savedProposalTemplate) {
        setSelectedTemplateId(savedProposalTemplate.id);
      }
    }, [savedProposalTemplate, setSelectedTemplateId]);

    const [renameProposalTemplate, { isLoading: isRenamingProposalTemplate }] =
      useRenameProposalTemplateMutation();

    const onRename = useCallback(
      (templateName: string) => {
        if (selectedTemplateId) {
          renameProposalTemplate({
            id: selectedTemplateId,
            name: templateName,
          });
        }
      },
      [renameProposalTemplate, selectedTemplateId],
    );

    const { button: renameButton } = useProposalTemplateNameAction({
      button: {
        tooltip: t('Editor:Toolbar.Templates.Actions.Rename.ButtonTooltip'),
        IconComponent: EditIcon,
      },
      dialog: {
        title: t('Editor:Toolbar.Templates.Actions.Rename.Dialog.Title'),
        confirmButtonLabel: t(
          'Editor:Toolbar.Templates.Actions.Rename.Dialog.ConfirmButtonLabel',
        ),
      },
      disabled: disabled || isNewTemplateSelected || !valid,
      loading: isRenamingProposalTemplate,
      initialTemplateName: selectedMenuItemName,
      onConfirm: onRename,
    });

    const initialClonedTemplateName = useMemo(
      () =>
        t('Editor:Toolbar.Templates.Actions.Clone.InitialCloneName', {
          templateName: selectedMenuItemName,
        }),
      [selectedMenuItemName, t],
    );

    const [
      cloneProposalTemplate,
      { data: clonedProposalTemplate, isLoading: isCloningProposalTemplate },
    ] = useCloneProposalTemplateMutation();

    const onClone = useCallback(
      (templateName) => {
        cloneProposalTemplate({
          name: templateName,
          layoutName: layoutVersion.name,
          layoutVersion: layoutVersion.version,
          layoutData: lastValidFormData,
        });
      },
      [
        cloneProposalTemplate,
        lastValidFormData,
        layoutVersion.name,
        layoutVersion.version,
      ],
    );

    const { button: makeCopyButton } = useProposalTemplateNameAction({
      button: {
        tooltip: t('Editor:Toolbar.Templates.Actions.Clone.ButtonTooltip'),
        IconComponent: FileCopyIcon,
      },
      dialog: {
        title: t('Editor:Toolbar.Templates.Actions.Clone.Dialog.Title'),
        confirmButtonLabel: t(
          'Editor:Toolbar.Templates.Actions.Clone.Dialog.ConfirmButtonLabel',
        ),
      },
      disabled: disabled || isNewTemplateSelected || !valid,
      loading: isCloningProposalTemplate,
      initialTemplateName: initialClonedTemplateName,
      onConfirm: onClone,
    });

    useEffect(() => {
      if (clonedProposalTemplate) {
        setSelectedTemplateId(clonedProposalTemplate.id);
      }
    }, [clonedProposalTemplate, setSelectedTemplateId]);

    const [deleteProposalTemplate, { isLoading: isDeletingProposalTemplate }] =
      useDeleteProposalTemplateSharedMutation();

    const onDelete = useCallback(() => {
      const selectedTemplateIndex = menuItems.findIndex(
        (template) => template.id === selectedTemplateId,
      );
      if (selectedTemplateIndex) {
        const previousMenuItem = menuItems[selectedTemplateIndex - 1];
        setSelectedTemplateId(menuItemIdToTemplateId(previousMenuItem.id));
      }

      if (selectedTemplateId) {
        deleteProposalTemplate(selectedTemplateId);
      }
    }, [
      deleteProposalTemplate,
      menuItems,
      selectedTemplateId,
      setSelectedTemplateId,
    ]);

    const { button: deleteButton } = useProposalTemplateConfirmAction({
      button: {
        tooltip: t('Editor:Toolbar.Templates.Actions.Delete.ButtonTooltip'),
        IconComponent: DeleteIcon,
      },
      dialog: {
        title: t('Editor:Toolbar.Templates.Actions.Delete.Dialog.Title'),
        confirmButtonLabel: t(
          'Editor:Toolbar.Templates.Actions.Delete.Dialog.ConfirmButtonLabel',
        ),
      },
      message: (
        <Trans
          t={t}
          i18nKey="Editor:Toolbar.Templates.Actions.Delete.Dialog.Message"
          values={{
            templateName: selectedMenuItemName,
          }}
        >
          NormalText
          <strong>BoldText</strong>
        </Trans>
      ),
      disabled: isNewTemplateSelected || !valid,
      loading: isDeletingProposalTemplate,
      onConfirm: onDelete,
    });

    const buttons = useMemo(
      () => (
        <>
          {saveButton}
          {renameButton}
          {makeCopyButton}
          {deleteButton}
        </>
      ),
      [deleteButton, makeCopyButton, renameButton, saveButton],
    );

    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classNames.root}
        >
          {select}
          {canEmployeeEditTemplates && buttons}
        </Grid>
        {discardChangesDialog}
      </>
    );
  };

interface TemplateMenuItem {
  id: string;
  name: string;
  className?: string;
}
