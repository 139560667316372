/* eslint-disable class-methods-use-this */
import { JSONSchemaType, ValidateFunction } from 'ajv';
import { flatten, keyBy, merge } from 'lodash';

import { ProposalLayoutGroupOfSettingsData } from '@easysolar/proposals/modules/Common/models/LayoutData/GroupOfSettings';
import {
  ProposalLayoutAnyData,
  ProposalLayoutArrayOfSectionsData,
} from '@easysolar/proposals/modules/Common/models/LayoutData/Layout';
import { ProposalLayoutSectionData } from '@easysolar/proposals/modules/Common/models/LayoutData/Section';
import { ProposalLayoutSettingData } from '@easysolar/proposals/modules/Common/models/LayoutData/Setting';
import {
  isProposalSettingOrGroupOfSettingsSchema,
  ProposalLayoutGroupOfSettingsSchema,
} from '@easysolar/proposals/modules/Common/models/LayoutSchema/GroupOfSettings';
import { ProposalLayoutSchema } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Layout';
import { ProposalLayoutSectionSchema } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Section';
import {
  ProposalLayoutSettingSchema,
  ProposalLayoutSettingSchemaValueType,
} from '@easysolar/proposals/modules/Common/models/LayoutSchema/Settings';
import {
  AbstractFormControl,
  FormArray,
  FormControl,
  FormGroup,
  FormGroupControls,
  ValidatorFn,
} from '@easysolar/proposals/shared/forms/models';

import { ProposalSettingsValidator } from '../ProposalSettingsValidator/ProposalSettingsValidator';

export class ProposalFormBuilder {
  public getProposalForm(
    layoutSchema: ProposalLayoutSchema,
  ): FormGroup<ProposalLayoutAnyData> {
    const {
      properties: {
        generalSettings,
        sections: {
          items: { oneOf: sections },
        },
      },
    } = layoutSchema;

    return new FormGroup<ProposalLayoutAnyData>({
      controls: {
        generalSettings: this.groupOfSettingsSchemaToFormGroup(generalSettings),
        sections: this.arrayOfSectionsSchemasToFormArray(sections),
      },
    });
  }

  private arrayOfSectionsSchemasToFormArray<
    T extends Array<ProposalLayoutSectionSchema>,
  >(
    arrayOfSectionsSchemas: T,
  ): FormArray<ProposalLayoutArrayOfSectionsData<T>> {
    return new FormArray<ProposalLayoutArrayOfSectionsData<T>>({
      controls: arrayOfSectionsSchemas.map((sectionSchema) =>
        this.sectionSchemaToFormGroup(sectionSchema),
      ) as FormGroupControls<ProposalLayoutArrayOfSectionsData<T>>,
    });
  }

  private sectionSchemaToFormGroup<T extends ProposalLayoutSectionSchema>(
    sectionSchema: T,
  ): FormGroup<ProposalLayoutSectionData<T>> {
    const {
      name,
      properties: { enabled, settings },
    } = sectionSchema;

    return new FormGroup<ProposalLayoutSectionData<T>>({
      controls: {
        name: new FormControl({ initialValue: name }),
        enabled: new FormControl({ initialValue: enabled.default }),
        settings: this.groupOfSettingsSchemaToFormGroup(settings),
      } as FormGroupControls<ProposalLayoutSectionData<T>>,
    });
  }

  private groupOfSettingsSchemaToFormGroup<
    T extends ProposalLayoutGroupOfSettingsSchema,
  >(groupOfSettingsSchema: T): FormGroup<ProposalLayoutGroupOfSettingsData<T>> {
    return new FormGroup<ProposalLayoutGroupOfSettingsData<T>>({
      controls: groupOfSettingsSchema.settings.reduce(
        (controls, settingName) => {
          // eslint-disable-next-line no-param-reassign
          controls[settingName as keyof ProposalLayoutGroupOfSettingsData<T>] =
            this.settingOrGroupOfSettingsSchemaToFormControl(
              groupOfSettingsSchema.properties[settingName],
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any;

          return controls;
        },
        {} as FormGroupControls<ProposalLayoutGroupOfSettingsData<T>>,
      ),
    });
  }

  private settingOrGroupOfSettingsSchemaToFormControl<
    T extends ProposalLayoutSettingSchema | ProposalLayoutGroupOfSettingsSchema,
  >(
    settingOrGroupOfSettingsSchema: T,
  ): T extends ProposalLayoutGroupOfSettingsSchema
    ? FormGroup<ProposalLayoutGroupOfSettingsData<T>>
    : T extends ProposalLayoutSettingSchema
    ? FormControl<ProposalLayoutSettingData<T>>
    : never {
    const { isGroup, settingSchema, groupOfSettingsSchema } =
      isProposalSettingOrGroupOfSettingsSchema(settingOrGroupOfSettingsSchema);

    const formControl = isGroup
      ? this.groupOfSettingsSchemaToFormGroup(groupOfSettingsSchema)
      : this.settingSchemaToFormControl(settingSchema);

    return formControl as T extends ProposalLayoutGroupOfSettingsSchema
      ? FormGroup<ProposalLayoutGroupOfSettingsData<T>>
      : T extends ProposalLayoutSettingSchema
      ? FormControl<ProposalLayoutSettingData<T>>
      : never;
  }

  private settingSchemaToFormControl<
    SettingSchema extends ProposalLayoutSettingSchema,
  >(
    settingSchema: SettingSchema,
  ): FormControl<ProposalLayoutSettingSchemaValueType<SettingSchema>> {
    const initialValue = this.settingSchemaToInitialValue(settingSchema);
    const validator = this.settingSchemaToValidator(settingSchema);

    return new FormControl<ProposalLayoutSettingSchemaValueType<SettingSchema>>(
      { initialValue, validators: [validator] },
    );
  }

  private settingSchemaToInitialValue<
    SettingSchema extends ProposalLayoutSettingSchema,
  >(
    settingSchema: SettingSchema,
  ): ProposalLayoutSettingSchemaValueType<SettingSchema> {
    return settingSchema.default as ProposalLayoutSettingSchemaValueType<SettingSchema>;
  }

  private settingSchemaToValidator<
    SettingSchema extends ProposalLayoutSettingSchema,
  >(
    settingSchema: SettingSchema,
  ): ValidatorFn<ProposalLayoutSettingSchemaValueType<SettingSchema>> {
    const jsonSchemaValidator =
      ProposalSettingsValidator.getJSONSchemaValidator(
        settingSchema as JSONSchemaType<
          ProposalLayoutSettingSchemaValueType<SettingSchema>
        >,
      );

    return ProposalFormBuilder.wrapJSONSchemaValidator(jsonSchemaValidator);
  }

  private static wrapJSONSchemaValidator<
    SettingSchema extends ProposalLayoutSettingSchema,
  >(
    jsonSchemaValidator: ValidateFunction<
      ProposalLayoutSettingSchemaValueType<SettingSchema>
    >,
  ): ValidatorFn<ProposalLayoutSettingSchemaValueType<SettingSchema>> {
    return (
      control: AbstractFormControl<
        ProposalLayoutSettingSchemaValueType<SettingSchema>
      >,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Record<string, any> => {
      const { value } = control;

      jsonSchemaValidator(value);

      const { errors } = jsonSchemaValidator;

      if (!errors) {
        return {};
      }

      return errors.reduce(
        (result, error) => ({
          ...result,
          [error.keyword]: error.params,
        }),
        {},
      );
    };
  }

  public static mergeLayouts(
    layoutData: ProposalLayoutAnyData,
    defaultLayoutData: ProposalLayoutAnyData,
  ): ProposalLayoutAnyData {
    const generalSettings = merge(
      {},
      defaultLayoutData.generalSettings,
      layoutData.generalSettings,
    );

    const defaultSections = defaultLayoutData.sections;

    const validSectionNames = new Set(
      defaultSections.map((section) => section.name),
    );
    const existingSections = layoutData.sections.filter((section) =>
      validSectionNames.has(section.name),
    );

    const defaultSectionsByName = keyBy(
      defaultSections,
      (section) => section.name,
    );
    const filledExistingSections = existingSections.map((section) =>
      merge({}, defaultSectionsByName[section.name], section),
    );

    const usedSectionNames = new Set(
      filledExistingSections.map((section) => section.name),
    );
    const missingSectionsByPrecedingSectionName: Record<
      string,
      Array<ProposalLayoutSectionData>
    > = {};
    const NULL = 'NULL';
    let currentPredecessor: string = NULL;
    defaultSections.forEach((section) => {
      if (usedSectionNames.has(section.name)) {
        currentPredecessor = section.name;
      } else {
        if (!missingSectionsByPrecedingSectionName[currentPredecessor]) {
          missingSectionsByPrecedingSectionName[currentPredecessor] = [];
        }

        missingSectionsByPrecedingSectionName[currentPredecessor].push(section);
      }
    });
    const filledSections: Array<Array<ProposalLayoutSectionData>> = [];
    if (missingSectionsByPrecedingSectionName[NULL]) {
      filledSections.push(missingSectionsByPrecedingSectionName[NULL]);
    }
    filledExistingSections.forEach((section) => {
      filledSections.push([section]);

      if (missingSectionsByPrecedingSectionName[section.name]) {
        filledSections.push(
          missingSectionsByPrecedingSectionName[section.name],
        );
      }
    });

    const sections = flatten(filledSections);

    return {
      generalSettings,
      sections,
    };
  }
}
