import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultSystemDesignVisualizationStyles =
  makeStyles((theme) => ({
    root: {
      paddingBottom: '3%',
    },
    icon: {
      fontSize: '8mm',
      color: theme.palette.text.secondary,
      padding: '0 4mm',
    },
    visualization: {
      width: '100%',
      maxHeight: '130mm',
      paddingTop: '24mm',
      objectFit: 'contain',
    },
  }));
