import { ThemeProvider } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

import {
  withI18NInstance,
  WithLanguageProps,
} from '@easysolar/proposals/infrastructure/components/withI18NInstance';
import { useLoadGoogleFonts } from '@easysolar/proposals/infrastructure/hooks/useLoadGoogleFonts';
import { Namespace } from '@easysolar/proposals/infrastructure/services/InternationalizationService';
import { theme } from '@easysolar/proposals/infrastructure/Theme';
import { APIProposalContext } from '@easysolar/proposals/modules/Common/models/APIProposalContextData';
import { ProposalContextService } from '@easysolar/proposals/modules/Common/services/ProposalContextService';

import { ProposalPreview, ProposalPreviewProps } from '../Preview';

const FONT_DEFINITIONS = [
  {
    family: 'Roboto Condensed',
    weights: [300, 400, 500, 700],
  },
];

const ProposalPreviewWrapperWithOverwrittenProps: FunctionComponent<
  ProposalPreviewProps & {
    apiContextData?: APIProposalContext;
  }
> = ({
  printing,
  contextData,
  apiContextData,
  layoutName,
  layoutVersion,
  layoutData,
  activeSectionName,
  onRendered,
}) => {
  const overwrittenContextData = useMemo(() => {
    if (apiContextData) {
      return ProposalContextService.apiProposalContextToProposalContext(
        apiContextData,
      );
    }

    return contextData;
  }, [apiContextData, contextData]);

  useLoadGoogleFonts(FONT_DEFINITIONS);

  return (
    overwrittenContextData && (
      <ProposalPreview
        printing={printing}
        contextData={overwrittenContextData}
        layoutName={layoutName}
        layoutVersion={layoutVersion}
        layoutData={layoutData}
        activeSectionName={activeSectionName || null}
        onRendered={onRendered}
      />
    )
  );
};

export type ProposalPreviewWrapperProps = ProposalPreviewProps &
  WithLanguageProps & {
    apiContextData?: APIProposalContext;
  };

export const TranslatedProposalPreview = withI18NInstance(
  ProposalPreviewWrapperWithOverwrittenProps,
  [Namespace.Layouts, Namespace.Shared],
  Namespace.Layouts,
);

export const ProposalPreviewWrapper: FunctionComponent<ProposalPreviewWrapperProps> =
  (props) => {
    return (
      <ThemeProvider theme={theme}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <TranslatedProposalPreview {...props} />
      </ThemeProvider>
    );
  };

ProposalPreviewWrapper.displayName = 'ProposalPreviewWrapper';
