import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProposalCurrencyNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalCurrencyNumberFormat';
import { ProposalContextDataValuation } from '@easysolar/proposals/modules/Common/models/ProposalContextData';

import { ProposalPreviewDefaultCustomerProposalComment } from '../Comment';
import { getProposalPreviewDefaultCustomerProposalSummaryStyles } from './Summary.styles';

export interface ProposalPreviewDefaultCustomerProposalSummaryProps {
  valuation: ProposalContextDataValuation;
}

interface Item {
  label: string;
  value: number;
  className?: string;
}

export const ProposalPreviewDefaultCustomerProposalSummary: FunctionComponent<ProposalPreviewDefaultCustomerProposalSummaryProps> =
  ({
    valuation: {
      taxed,
      discounted,
      summary: {
        comment,
        netPrice,
        taxSum,
        grossPrice,
        discountSum,
        totalPrice,
      },
    },
  }) => {
    const { t } = useTranslation();

    const classes = getProposalPreviewDefaultCustomerProposalSummaryStyles();

    const items = useMemo(() => {
      const result: Item[] = [];

      if (taxed) {
        result.push(
          {
            label: t(
              'Layouts:Default.Sections.CustomerProposal.Summary.NetPriceLabel',
            ),
            value: netPrice,
          },
          {
            label: t(
              'Layouts:Default.Sections.CustomerProposal.Summary.TaxSumLabel',
            ),
            value: taxSum,
          },
        );
      }

      if (taxed || discounted) {
        result.push({
          label: t(
            'Layouts:Default.Sections.CustomerProposal.Summary.GrossPriceLabel',
          ),
          value: grossPrice,
        });
      }

      if (discounted) {
        result.push({
          label: t(
            'Layouts:Default.Sections.CustomerProposal.Summary.DiscountSumLabel',
          ),
          value: discountSum,
        });
      }

      result.push({
        label: t(
          'Layouts:Default.Sections.CustomerProposal.Summary.TotalPriceLabel',
        ),
        value: totalPrice,
        className: classes.totalPrice,
      });

      return result;
    }, [
      classes.totalPrice,
      discountSum,
      discounted,
      grossPrice,
      netPrice,
      t,
      taxSum,
      taxed,
      totalPrice,
    ]);

    return (
      <>
        <Divider />
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyContent="space-between"
          className={classes.container}
        >
          <Box className={classes.comment}>
            {comment && (
              <ProposalPreviewDefaultCustomerProposalComment
                label={t(
                  'Layouts:Default.Sections.CustomerProposal.Summary.CommentLabel',
                )}
                comment={comment}
              />
            )}
          </Box>
          <Grid item container className={classes.items}>
            {items.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={index} className={classes.itemContainer}>
                {index > 0 && <Divider />}
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.item}
                >
                  <Typography variant="body2">{item.label}</Typography>
                  <ProposalCurrencyNumberFormat
                    value={item.value}
                    defaultProps={{
                      variant: 'body2',
                      color: 'textPrimary',
                      className: item.className,
                    }}
                  />
                </Grid>
              </Box>
            ))}
          </Grid>
        </Grid>
      </>
    );
  };
