import { FunctionComponent, ReactElement, useCallback, useMemo } from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';

import { ProposalLayoutSectionData } from '@easysolar/proposals/modules/Common/models/LayoutData/Section';
import { ProposalLayoutSectionSchema } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Section';
import { useFormControl } from '@easysolar/proposals/shared/forms/hooks/useFormControl';
import { useFormGroup } from '@easysolar/proposals/shared/forms/hooks/useFormGroup';
import { FormGroup } from '@easysolar/proposals/shared/forms/models';

import { ProposalEditorSideBarSectionsListItem } from '../Item';
import { getProposalEditorSideBarSectionsListDraggableItemStyles } from './DraggableItem.styles';

interface ProposalEditorSideBarSectionsListDraggableItemProps {
  index: number;
  sectionForm: FormGroup<ProposalLayoutSectionData>;
  sectionSchema: ProposalLayoutSectionSchema;
  disabled: boolean;
}

export const ProposalEditorSideBarSectionsListDraggableItem: FunctionComponent<ProposalEditorSideBarSectionsListDraggableItemProps> =
  ({ index, sectionForm, sectionSchema, disabled }) => {
    const classes = getProposalEditorSideBarSectionsListDraggableItemStyles();

    const { valid } = useFormGroup(sectionForm.controls.settings);

    const renderProvidedItem = useCallback(
      (
        providedItem: DraggableProvided,
        snapshot: DraggableStateSnapshot,
      ): ReactElement<HTMLElement> => (
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...providedItem.draggableProps}
          ref={providedItem.innerRef}
          className={classes.item}
        >
          <ProposalEditorSideBarSectionsListItem
            dragHandleProps={providedItem.dragHandleProps}
            isDragging={snapshot.isDragging}
            sectionTitle={sectionSchema.properties.settings.title}
            enabledFormControl={sectionForm.controls.enabled}
            disabled={disabled || !valid}
          />
        </div>
      ),
      [
        classes.item,
        disabled,
        sectionForm.controls.enabled,
        sectionSchema.properties.settings.title,
        valid,
      ],
    );

    const { value: name } = useFormControl(sectionForm.controls.name);

    const draggable = useMemo(
      () => (
        <Draggable
          draggableId={name as string}
          index={index}
          isDragDisabled={disabled}
        >
          {renderProvidedItem}
        </Draggable>
      ),
      [name, index, disabled, renderProvidedItem],
    );

    return draggable;
  };
