import { Box, Divider, Typography } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

import { getProposalPreviewDefaultSubsectionTitleProps } from './Title.styles';

interface ProposalPreviewDefaultSubsectionTitleProps {
  title: string;
}

export const ProposalPreviewDefaultSubsectionTitle: FunctionComponent<ProposalPreviewDefaultSubsectionTitleProps> =
  ({ title }) => {
    const classes = getProposalPreviewDefaultSubsectionTitleProps();

    const renderedTitle = useMemo(
      () => (
        <Box className={classes.root}>
          <Typography className={classes.title} variant="body2">
            {title}
          </Typography>
          <Divider />
        </Box>
      ),
      [classes.root, classes.title, title],
    );

    return renderedTitle;
  };
