import { Typography } from '@material-ui/core';
import { FunctionComponent, useCallback, useMemo } from 'react';
import * as React from 'react';

import { getProposalPreviewDefaultTableStyles } from './Table.styles';

export interface ProposalPreviewDefaultTableProps<DataType extends object> {
  columns: ProposalPreviewDefaultTableColumn<DataType>[];
  items: DataType[];

  showHeader?: boolean;
  onRenderHeader?: FunctionComponent<
    ProposalPreviewDefaultTableColumn<DataType>
  >;
}

export interface ProposalPreviewDefaultTableColumn<DataType extends object> {
  key: string;
  name: string;
  fieldName: keyof DataType;

  width?: React.CSSProperties['width'];

  headerTextStyle?: React.CSSProperties;
  onRenderHeader?: FunctionComponent<
    ProposalPreviewDefaultTableColumn<DataType>
  >;

  cellStyle?: (props: { index: number }) => React.CSSProperties | undefined;
  cellTextStyle?: React.CSSProperties;
  onRenderCell?: ProposalPreviewDefaultTableColumnOnRenderCell<DataType>;
}

export type ProposalPreviewDefaultTableColumnOnRenderCell<
  DataType extends object,
> = FunctionComponent<{
  column: ProposalPreviewDefaultTableColumn<DataType>;
  item: DataType;
  index: number;
}>;

export const ProposalPreviewDefaultTable = <DataType extends object>({
  columns,
  items,
  showHeader = true,
  onRenderHeader,
}: ProposalPreviewDefaultTableProps<DataType>): React.ReactElement => {
  const classes = getProposalPreviewDefaultTableStyles({ showHeader });

  const onRenderDefaultColumnHeader = useCallback(
    (column: ProposalPreviewDefaultTableColumn<DataType>) => {
      return (
        <Typography
          component="div"
          variant="caption"
          className={classes.headerCellText}
          style={column.headerTextStyle}
        >
          {column.name}
        </Typography>
      );
    },
    [classes.headerCellText],
  );

  const headerCellClassName = useMemo(
    () => [classes.cell, classes.headerCell].join(' '),
    [classes.cell, classes.headerCell],
  );

  const onRenderColumnHeader = useCallback(
    (column: ProposalPreviewDefaultTableColumn<DataType>) => {
      const render =
        column.onRenderHeader || onRenderHeader || onRenderDefaultColumnHeader;

      return (
        <th
          key={column.key}
          className={headerCellClassName}
          style={{
            width: column.width,
          }}
        >
          {render(column)}
        </th>
      );
    },
    [onRenderHeader, onRenderDefaultColumnHeader, headerCellClassName],
  );

  const tableHead = useMemo(
    () => (
      <thead className={classes.header}>
        <tr>{columns.map((column) => onRenderColumnHeader(column))}</tr>
      </thead>
    ),
    [classes.header, columns, onRenderColumnHeader],
  );

  const dataCellClassName = useMemo(
    () => [classes.cell, classes.bodyCell].join(' '),
    [classes.bodyCell, classes.cell],
  );

  const tableBody = useMemo(
    () => (
      <tbody>
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={index} className={classes.bodyRow}>
            {columns.map((column) => (
              <td
                key={column.key}
                className={dataCellClassName}
                style={{
                  width: column.width,
                  ...(column.cellStyle
                    ? column.cellStyle({ index })
                    : undefined),
                }}
              >
                {column.onRenderCell ? (
                  column.onRenderCell({ column, item, index })
                ) : (
                  <Typography
                    component="div"
                    variant="caption"
                    className={classes.bodyCellText}
                    style={column.cellTextStyle}
                  >
                    {column?.fieldName && item[column.fieldName]}
                  </Typography>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    ),
    [classes.bodyCellText, classes.bodyRow, columns, dataCellClassName, items],
  );

  const table = useMemo(
    () => (
      <table className={classes.table}>
        {tableHead}
        {tableBody}
      </table>
    ),
    [classes.table, tableBody, tableHead],
  );

  return table;
};
