import { makeStyles } from '@material-ui/core';

export const getProposalPreviewDefaultLabelWithIconStyles = makeStyles(
  (theme) => ({
    root: {
      width: 'unset',
    },
    icon: {
      fontSize: '18pt',
      paddingRight: theme.typography.caption.fontSize,
      color: theme.palette.text.secondary,
    },
  }),
);
