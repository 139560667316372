import { AbstractFormControl, ValidatorFn } from '../../forms/models';

export enum TextFieldErrorType {
  Required = 'required',
  MaxLength = 'maxLength',
}

export const getTextFieldValidators = ({
  required = false,
  maxLength,
}: {
  required?: boolean;
  maxLength?: number;
}): ValidatorFn<string, Record<TextFieldErrorType, unknown>> => {
  return ({
    value,
  }: AbstractFormControl<string, Record<TextFieldErrorType, unknown>>): Partial<
    Record<TextFieldErrorType, unknown>
  > | null => {
    if (required && !value?.length) {
      return { [TextFieldErrorType.Required]: null };
    }

    if (maxLength !== undefined && value?.length > maxLength) {
      return { [TextFieldErrorType.MaxLength]: null };
    }

    return null;
  };
};
