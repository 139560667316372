import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { FunctionComponent, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShadowRootDialog } from '@easysolar/proposals/shared/components/ShadowRootMaterialUI/Dialog';

export interface ProposalEditorToolbarTemplatesSectionDialogProps {
  open: boolean;
  title: string;
  content?: ReactElement;
  confirmButtonLabel: string;
  confirmButtonDisabled?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

export const ProposalEditorToolbarTemplatesSectionDialog: FunctionComponent<ProposalEditorToolbarTemplatesSectionDialogProps> =
  ({
    open,
    title,
    content,
    confirmButtonLabel,
    confirmButtonDisabled = false,
    onClose,
    onConfirm,
  }) => {
    const { t } = useTranslation();

    const cancelButton = useMemo(
      () => (
        <Button color="primary" onClick={onClose}>
          {t('Editor:Toolbar.Templates.Dialog.CancelButtonLabel')}
        </Button>
      ),
      [onClose, t],
    );

    const confirmButton = useMemo(
      () => (
        <Button
          color="primary"
          disabled={confirmButtonDisabled}
          onClick={onConfirm}
        >
          {confirmButtonLabel}
        </Button>
      ),
      [confirmButtonDisabled, confirmButtonLabel, onConfirm],
    );

    return (
      // TODO: remove disableEnforceFocus after MUI update to v5
      <ShadowRootDialog
        disableEnforceFocus
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={onClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          {cancelButton}
          {confirmButton}
        </DialogActions>
      </ShadowRootDialog>
    );
  };
