import { FormControl, FormControlResult } from '../models';
import { useAbstractFormControl } from './useAbstractFormControl';

export function useFormControl<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
>(formControl: FormControl<T, E>): FormControlResult<T, E> {
  return useAbstractFormControl(formControl);
}
