import { alpha, makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from 'react';

interface ImageFieldDropzoneStylesProps {
  isLoading: boolean;
}

export const getImageFieldDropzoneStyles = makeStyles<
  Theme,
  ImageFieldDropzoneStylesProps
>((theme) => ({
  root: {
    width: '100%',
    cursor: 'pointer',
    '&:focus': {
      outline: 'unset',
    },
  },
  container: {
    position: 'relative',
    padding: theme.spacing(2),
  },
  image: {
    width: '100%',
    height: theme.spacing(15),
    objectFit: 'contain',
    objectPosition: 'center',
    opacity: ({ isLoading }): CSSProperties['opacity'] =>
      isLoading ? '20%' : 'unset',
  },
  loader: {
    position: 'absolute',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: alpha(theme.palette.background.default, 0.95),
  },
  dropText: {
    maxWidth: '60%',
  },
}));
