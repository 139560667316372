import {
  Button,
  createTheme,
  FormControl,
  FormLabel,
  useTheme,
} from '@material-ui/core';
import {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as React from 'react';
import { BlockPicker, ColorResult } from 'react-color';
import { BlockPickerProps } from 'react-color/lib/components/block/Block';

import { ShadowRootPopover } from '../ShadowRootMaterialUI/Popover';
import { getColorFieldStyles } from './ColorField.styles';

export interface ColorFieldProps {
  value: string;
  onChange: (color: string) => void;
  label: string;
  colors?: string[];
}

export const ColorField: FunctionComponent<ColorFieldProps> = ({
  value,
  onChange,
  label,
  colors = [],
}) => {
  const theme = useTheme();

  const paletteColor = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: value,
          },
        },
      }).palette.primary,
    [value],
  );

  const classes = getColorFieldStyles({ paletteColor });

  const ref = useRef<HTMLDivElement | null>(null);

  const [anchor, setAnchor] = useState<Element | null>(null);

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  }, []);

  const onClose = useCallback(() => setAnchor(null), []);

  const open = Boolean(anchor);

  const pickerStyles: BlockPickerProps['styles'] = useMemo(
    () => ({
      default: {
        head: {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
        },
      },
    }),
    [theme.shape.borderRadius],
  );

  const onColorChange = useCallback(
    (color: ColorResult) => {
      if (onChange) {
        onChange(color.hex);
      }
    },
    [onChange],
  );

  return (
    <FormControl margin="normal" ref={ref}>
      <FormLabel>{label}</FormLabel>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onClick}
      />
      <ShadowRootPopover
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        disableEnforceFocus
        PaperProps={{ elevation: 12 }}
        open={open}
        onClose={onClose}
      >
        <BlockPicker
          triangle="hide"
          colors={colors}
          color={value}
          onChange={onColorChange}
          styles={pickerStyles}
        />
      </ShadowRootPopover>
    </FormControl>
  );
};
