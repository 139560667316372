import { FunctionComponent, PropsWithChildren, useCallback } from 'react';

import { useFormControl } from '../../forms/hooks/useFormControl';
import { FormControl } from '../../forms/models';

export interface FieldWrapperBaseProps<T> {
  value: T;
  onChange: (value: T) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ControlledFieldProps<T, E = any> {
  formControl: FormControl<T, E>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FieldWrapperProps<T, E = any> = FieldWrapperBaseProps<T> &
  ControlledFieldProps<T, E>;

export const withControlledField = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FieldWrapperPropsType extends FieldWrapperProps<any>,
>(
  Component: FieldWrapperPropsType extends FieldWrapperProps<infer _T>
    ? FunctionComponent<FieldWrapperPropsType>
    : never,
): FunctionComponent<
  PropsWithChildren<Omit<FieldWrapperPropsType, 'value' | 'onChange'>>
> => {
  const ControlledWrappedComponent = ({
    formControl,
    ...props
  }: PropsWithChildren<
    Omit<FieldWrapperPropsType, 'value' | 'onChange'>
  >): JSX.Element => {
    const { value, setValue } = useFormControl(formControl);

    const onChange = useCallback(
      (newValue: typeof value) => {
        setValue(newValue, { triggeredByUser: true });
      },
      [setValue],
    );

    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
        {...(props as any)}
        formControl={formControl}
        value={value}
        onChange={onChange}
      />
    );
  };

  return ControlledWrappedComponent;
};
