import { Box, Grid, Toolbar } from '@material-ui/core';
import { FunctionComponent, useMemo, useState } from 'react';

import { ProposalLayoutAnyData } from '@easysolar/proposals/modules/Common/models/LayoutData/Layout';
import { ProposalLayoutVersion } from '@easysolar/proposals/modules/Common/models/ProposalLayoutVersion';
import { FormGroup } from '@easysolar/proposals/shared/forms/models';

import { ProposalEditorToolbarGenerationSection } from './GenerationSection';
import { ProposalEditorToolbarTemplatesSection } from './TemplatesSection';

interface ProposalEditorToolbarProps {
  layoutVersion: ProposalLayoutVersion;
  proposalForm: FormGroup<ProposalLayoutAnyData>;
  disabled: boolean;
}

export const ProposalEditorToolbar: FunctionComponent<ProposalEditorToolbarProps> =
  ({ layoutVersion, proposalForm, disabled }) => {
    const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
      null,
    );

    const templatesSection = useMemo(
      () => (
        <ProposalEditorToolbarTemplatesSection
          layoutVersion={layoutVersion}
          proposalForm={proposalForm}
          selectedTemplateId={selectedTemplateId}
          disabled={disabled}
          onSelectedTemplateIdChange={setSelectedTemplateId}
        />
      ),
      [disabled, layoutVersion, proposalForm, selectedTemplateId],
    );

    const generationSection = useMemo(
      () => (
        <ProposalEditorToolbarGenerationSection
          layoutVersion={layoutVersion}
          proposalForm={proposalForm}
          selectedTemplateId={selectedTemplateId}
          disabled={disabled}
        />
      ),
      [disabled, layoutVersion, proposalForm, selectedTemplateId],
    );

    return (
      <Toolbar>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>{templatesSection}</Box>
          <Box>{generationSection}</Box>
        </Grid>
      </Toolbar>
    );
  };
