/* eslint-disable max-classes-per-file */

import { isEmpty } from 'lodash';
import { Subject } from 'rxjs';

export interface AbstractFormControlState<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> {
  value: T;
  errors: Partial<E>;
  valid: boolean;
  dirty: boolean;
}

export interface AbstractFormControlResult<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> extends AbstractFormControlState<T, E> {
  patchValue: (
    newValue?: Partial<T>,
    options?: AbstractFormControlUpdateValueOptions,
  ) => void;
  setValue: (
    newValue?: T,
    options?: AbstractFormControlUpdateValueOptions,
  ) => void;
  reset: (
    newValue?: T,
    options?: AbstractFormControlUpdateValueOptions,
  ) => void;
}

export abstract class AbstractFormControl<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> implements AbstractFormControlResult<T, E>
{
  public abstract value: T;

  public validators: ValidatorFn<T, E>[] = [];

  public errors: Partial<E> = {};

  public valid = true;

  public dirty = false;

  public parent?: FormGroup | FormArray;

  public readonly valueChanges = new Subject<T | undefined>();

  public readonly stateChanges = new Subject<AbstractFormControlState<T, E>>();

  constructor({ validators = [] }: AbstractFormControlOptions<T, E>) {
    if (validators) {
      this.validators = validators;
    }
  }

  public setParent(parent: FormGroup | FormArray): void {
    this.parent = parent;
  }

  public abstract setValue(
    newValue?: T,
    options?: AbstractFormControlUpdateValueOptions,
  ): void;

  public abstract patchValue(
    newValue?: Partial<T>,
    options?: AbstractFormControlUpdateValueOptions,
  ): void;

  public abstract reset(
    newValue?: Partial<T>,
    options?: AbstractFormControlUpdateValueOptions,
  ): void;

  public updateValueAndValidity(
    options: AbstractFormControlUpdateValueOptions = {},
  ): void {
    const {
      emitEvent = true,
      onlySelf = false,
      triggeredByUser = false,
    } = options;

    this.updateValueBasedOnControls();

    this.errors = this.runValidators();
    this.valid = this.checkValidity();

    if (triggeredByUser && !this.dirty) {
      this.dirty = true;
    }

    if (emitEvent !== false) {
      this.valueChanges.next(this.value);
      this.stateChanges.next({
        value: this.value,
        errors: this.errors,
        valid: this.valid,
        dirty: this.dirty,
      });
    }

    if (this.parent && !onlySelf) {
      this.parent.updateValueAndValidity(options);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  protected updateValueBasedOnControls(): void {}

  private runValidators(): Partial<E> {
    if (this.value === undefined) {
      return {};
    }

    return this.validators.reduce(
      (result, validator) => ({
        ...result,
        ...validator(this),
      }),
      {},
    );
  }

  private checkValidity(): boolean {
    if (!isEmpty(this.errors)) {
      return false;
    }

    if (this.anyControlIsInvalid()) {
      return false;
    }

    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  protected anyControlIsInvalid(): boolean {
    return false;
  }
}

export interface AbstractFormControlOptions<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> {
  validators?: ValidatorFn<T, E>[];
}

export type ValidatorFn<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> = (control: AbstractFormControl<T, E>) => Partial<E> | null;

export interface AbstractFormControlUpdateValueOptions {
  onlySelf?: boolean;
  emitEvent?: boolean;
  triggeredByUser?: boolean;
}

export type FormControlResult<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> = AbstractFormControlResult<T, E>;

export class FormControl<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> extends AbstractFormControl<T, E> {
  public value!: T;

  constructor({ initialValue, validators = [] }: FormControlOptions<T, E>) {
    super({ validators });

    this.setInternalValue(initialValue, { onlySelf: true, emitEvent: false });
  }

  public setValue(
    newValue: T,
    options?: AbstractFormControlUpdateValueOptions,
  ): void {
    this.patchValue(newValue, options);
  }

  public patchValue(
    newValue: T,
    options?: AbstractFormControlUpdateValueOptions,
  ): void {
    this.setInternalValue(newValue, options);
  }

  public reset(
    newValue: T,
    options?: AbstractFormControlUpdateValueOptions,
  ): void {
    this.dirty = false;

    this.setValue(newValue, options);
  }

  private setInternalValue(
    newValue: T,
    options?: AbstractFormControlUpdateValueOptions,
  ): void {
    if (newValue !== this.value) {
      this.value = newValue;
      this.updateValueAndValidity(options);
    }
  }
}

export interface FormControlOptions<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> extends AbstractFormControlOptions<T, E> {
  initialValue: T;
}

export abstract class FormControlParent<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> extends AbstractFormControl<T, E> {
  public value!: T;

  public controls: FormControlParentControls<T>;

  constructor({ validators, controls }: FormControlParentOptions<T, E>) {
    super({ validators });

    this.controls = controls;
    this.setupControls();

    this.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  private setupControls(): void {
    Object.values(this.controls).forEach((control) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      control.setParent(this as any),
    );
  }

  public setValue(
    newValue?: T,
    options?: AbstractFormControlUpdateValueOptions,
  ): void {
    this.updateValue(newValue, options, (control) =>
      control.setValue.bind(control),
    );
  }

  public patchValue(
    newValue?: Partial<T>,
    options?: AbstractFormControlUpdateValueOptions,
  ): void {
    this.updateValue(newValue as T, options, (control) =>
      control.patchValue.bind(control),
    );
  }

  public reset(
    newValue?: T,
    options?: AbstractFormControlUpdateValueOptions,
  ): void {
    this.dirty = false;

    this.updateValue(newValue, options, (control) =>
      control.reset.bind(control),
    );
  }

  private updateValue(
    newValue: T | undefined,
    options: AbstractFormControlUpdateValueOptions = {},
    getControlUpdateValue: (
      control: AbstractFormControl<FormControlParentControlValue<T>>,
    ) => (
      newValue: FormControlParentControlValue<T>,
      options?: AbstractFormControlUpdateValueOptions,
    ) => void,
  ): void {
    if (newValue !== undefined) {
      Object.keys(newValue).forEach((controlName) => {
        const control = this.controls[controlName] as AbstractFormControl<
          FormControlParentControlValue<T>
        >;

        if (control) {
          const controlUpdateValue = getControlUpdateValue(control);
          controlUpdateValue(newValue[controlName], {
            onlySelf: true,
          });
        }
      });

      this.updateValueAndValidity(options);
    }
  }

  protected anyControlIsInvalid(): boolean {
    return Object.values(this.controls).some((control) => !control.valid);
  }
}

export type FormControlParentControls<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [ControlName in keyof T]: FormValueToControl<T[ControlName]>;
};

interface FormControlParentOptions<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> extends AbstractFormControlOptions<T, E> {
  controls: FormGroupControls<T>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormControlParentControlValue<T> = T extends Record<string, any>
  ? T[string]
  : // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Array<any>
  ? T[number]
  : T extends boolean
  ? never
  : never;

export interface FormGroupResult<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any> = Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> extends AbstractFormControlResult<T, E> {
  value: T;
}

export class FormGroup<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T extends Record<string, any> = Record<string, any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    E extends Record<string, any> = Record<string, any>,
  >
  extends FormControlParent<T, E>
  implements FormGroupResult<T, E>
{
  protected updateValueBasedOnControls(): void {
    this.value = Object.keys(this.controls).reduce(
      (result, controlName) => ({
        ...result,
        [controlName]: this.controls[controlName].value,
      }),
      {} as Partial<T>,
    ) as T;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormGroupControls<T extends Record<string, any>> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Key in keyof T]: FormValueToControl<T[Key]>;
};

export interface FormArrayResult<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Array<any> = Array<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> extends AbstractFormControlResult<T, E> {
  value: T;
}

export class FormArray<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T extends Array<any> = Array<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    E extends Record<string, any> = Record<string, any>,
  >
  extends FormControlParent<T, E>
  implements FormArrayResult<T>
{
  public setControl(
    index: number,
    control: FormControlParentControls<T>[number],
  ): void {
    this.controls[index] = control;

    this.updateValueAndValidity();
  }

  public insert(
    index: number,
    control: FormControlParentControls<T>[number],
  ): void {
    this.controls.splice(index, 0, control);

    this.updateValueAndValidity();
  }

  public remove(index: number): AbstractFormControl<T[number]> {
    const [control] = this.controls.splice(index, 1);

    this.updateValueAndValidity();

    return control;
  }

  public reorder(
    startIndex: number,
    endIndex: number,
    options?: AbstractFormControlUpdateValueOptions,
  ): void {
    const [control] = this.controls.splice(startIndex, 1);

    this.controls.splice(endIndex, 0, control);

    this.updateValueAndValidity(options);
  }

  protected updateValueBasedOnControls(): void {
    this.value = this.controls.map((control) => control.value) as T;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormArrayControls<T extends Array<any>> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Index in keyof T]: FormValueToControl<T[Index]>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormValueToControl<T> = T extends Array<any>
  ? FormArray<T>
  : // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>
  ? FormGroup<T>
  : T extends boolean
  ? FormControl<boolean>
  : FormControl<T>;
