import { ProposalLayoutSettingSchemaToggleableText } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Settings/ToggleableText';
import { ControlledToggleableTextField } from '@easysolar/proposals/shared/components/ControlledToggleableTextField';

import { ProposalEditorSettingBase } from './Base';

export const ProposalEditorSettingToggleableText: ProposalEditorSettingBase<ProposalLayoutSettingSchemaToggleableText> =
  ({ formControl, settingSchema }) => {
    const {
      title,
      properties: { text },
    } = settingSchema;

    return (
      <ControlledToggleableTextField
        formControl={formControl}
        label={title}
        maxLength={text?.maxLength}
      />
    );
  };
