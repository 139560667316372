import { FunctionComponent, useMemo } from 'react';

import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';
import { millimetresToPixels } from '@easysolar/proposals/modules/ProposalPreview/utils/units';

import { ProposalPreviewDefaultChart } from '../../../Shared/Chart';

export const ProposalPreviewDefaultCashFlowChart: FunctionComponent = () => {
  const {
    project: {
      financialAnalysis: {
        forecast: { cumulativeCashFlow },
      },
    },
  } = useProposalContext();

  const width = useMemo(() => millimetresToPixels(155), []);

  const height = useMemo(() => millimetresToPixels(85), []);

  const data = useMemo(
    () =>
      cumulativeCashFlow.map((cashFlow, index) => ({
        year: index + 1,
        cashFlow,
      })),
    [cumulativeCashFlow],
  );

  return (
    <ProposalPreviewDefaultChart
      width={width}
      height={height}
      data={data}
      xAxisDataKey="year"
      yAxisDataKey="cashFlow"
      yAxisCompactNotation
    />
  );
};
