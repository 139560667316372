import { Box, Grid } from '@material-ui/core';
import { zip } from 'lodash';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';
import { millimetresToPixels } from '@easysolar/proposals/modules/ProposalPreview/utils/units';

import { ProposalPreviewDefaultChart } from '../../../../Shared/Chart';
import { ProposalPreviewDefaultEnergyProductionCaption } from '../Caption';
import { getProposalPreviewDefaultEnergyProductionChartStyles } from './Chart.styles';

export const ProposalPreviewDefaultEnergyProductionChart: FunctionComponent =
  () => {
    const { i18n, t } = useTranslation();
    const {
      project: { energyProductionByMonth },
    } = useProposalContext();

    const classes = getProposalPreviewDefaultEnergyProductionChartStyles();

    const width = useMemo(() => millimetresToPixels(105), []);

    const height = useMemo(() => millimetresToPixels(70), []);

    const intl = useMemo(
      () => new Intl.DateTimeFormat(i18n.language, { month: 'numeric' }),
      [i18n.language],
    );

    const months = useMemo(
      () =>
        new Array(12)
          .fill(null)
          .map((_, index) => new Date(0, index))
          .map((date) => intl.formatToParts(date))
          .map(
            (parts, index) =>
              parts.find((part) => part.type === 'month')?.value ||
              index.toString(),
          ),
      [intl],
    );

    const data = useMemo(
      () =>
        zip(months, energyProductionByMonth).map(
          ([month, monthProduction]) => ({
            month,
            production: monthProduction,
          }),
        ),
      [months, energyProductionByMonth],
    );

    const caption = useMemo(
      () => (
        <Box className={classes.caption}>
          <ProposalPreviewDefaultEnergyProductionCaption>
            {t(
              'Layouts:Default.Sections.EnergyProductionAndEnvironmentalImpact.Subsections.EnergyProduction.ChartCaption',
            )}
          </ProposalPreviewDefaultEnergyProductionCaption>
        </Box>
      ),
      [classes.caption, t],
    );

    const chart = useMemo(
      () => (
        <ProposalPreviewDefaultChart
          width={width}
          height={height}
          data={data}
          xAxisDataKey="month"
          yAxisDataKey="production"
        />
      ),
      [data, height, width],
    );

    const container = useMemo(
      () => (
        <Grid container direction="column" alignItems="flex-end">
          {caption}
          {chart}
        </Grid>
      ),
      [caption, chart],
    );

    return container;
  };
