import { Box, Grid, Typography } from '@material-ui/core';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProposalCurrencyNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalCurrencyNumberFormat';
import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';

import {
  ProposalPreviewDefaultTable,
  ProposalPreviewDefaultTableColumn,
  ProposalPreviewDefaultTableColumnOnRenderCell,
} from '../../../../Shared/Table';
import { getProposalPreviewDefaultInvestmentCostsEnergyCostsTableStyles } from './Table.styles';

interface Data {
  label: string;
  value: number;
  additionalUnit?: string;
}

export const ProposalPreviewDefaultInvestmentCostsEnergyCostsTable: FunctionComponent =
  () => {
    const { t } = useTranslation();

    const classes =
      getProposalPreviewDefaultInvestmentCostsEnergyCostsTableStyles();

    const {
      project: {
        totalPrice,
        financialAnalysis: {
          ownContributionRatio,
          loanTerm,
          forecast: { savingsIncludingPVSystemCosts, monthlyLoanPayment },
        },
      },
    } = useProposalContext();

    const usesLoan = useMemo(
      () => ownContributionRatio < 1,
      [ownContributionRatio],
    );

    const onRenderLabelCell: ProposalPreviewDefaultTableColumnOnRenderCell<Data> =
      useCallback(
        ({ item }) => (
          <Grid
            container
            alignItems="center"
            className={classes.labelContainer}
          >
            <Typography
              className={classes.label}
              variant="body2"
              color="textSecondary"
              align="left"
              component="div"
            >
              {item.label}
            </Typography>
          </Grid>
        ),
        [classes.label, classes.labelContainer],
      );

    const onRenderValueCell: ProposalPreviewDefaultTableColumnOnRenderCell<Data> =
      useCallback(
        ({ item: { value, additionalUnit } }) => (
          <ProposalCurrencyNumberFormat
            value={value}
            additionalUnit={additionalUnit}
            defaultProps={{
              className: classes.value,
            }}
            partTypesProps={{
              currency: {
                variant: 'body2',
                className: classes.valueCurrency,
              },
            }}
          />
        ),
        [classes.value, classes.valueCurrency],
      );

    const columns: ProposalPreviewDefaultTableColumn<Data>[] = useMemo(
      () => [
        {
          key: 'label',
          name: '',
          fieldName: 'label',
          width: '30%',
          onRenderCell: onRenderLabelCell,
        },
        {
          key: 'value',
          name: '',
          fieldName: 'value',
          width: '70%',
          onRenderCell: onRenderValueCell,
        },
      ],
      [onRenderLabelCell, onRenderValueCell],
    );

    const baseItems: Data[] = useMemo(
      () => [
        {
          label: t(
            'Layouts:Default.Sections.InvestmentCosts.Subsections.EnergyCosts.SavingsLabel',
          ),
          value: savingsIncludingPVSystemCosts,
        },
        {
          label: t(
            'Layouts:Default.Sections.InvestmentCosts.Subsections.EnergyCosts.TotalPriceLabel',
          ),
          value: totalPrice,
        },
      ],
      [savingsIncludingPVSystemCosts, t, totalPrice],
    );

    const items: Data[] = useMemo(
      () =>
        [
          ...baseItems,
          usesLoan && {
            label: t(
              'Layouts:Default.Sections.InvestmentCosts.Subsections.EnergyCosts.LoanLabel',
              { count: loanTerm },
            ),
            value: monthlyLoanPayment,
            additionalUnit: t('Shared:Units.MonthAbbreviation'),
          },
        ].filter((item) => !!item) as Data[],
      [baseItems, loanTerm, monthlyLoanPayment, t, usesLoan],
    );

    const table = useMemo(
      () => (
        <Box className={classes.root}>
          <ProposalPreviewDefaultTable
            columns={columns}
            items={items}
            showHeader={false}
          />
        </Box>
      ),
      [classes.root, columns, items],
    );

    return table;
  };
