// eslint-disable-next-line import/no-extraneous-dependencies
import { rest } from 'msw';

import { ConfigurationService } from '@easysolar/proposals/infrastructure/services/ConfigurationService';

import { TEST_API_PROPOSAL_CONTEXT_DATA } from '../../Common/data/APIProposalContextData';
import { TEST_PROPOSAL_LAYOUT_SCHEMA } from '../../Common/data/ProposalLayoutSchema';
import { APIProposalContext } from '../../Common/models/APIProposalContextData';
import { ProposalLayoutVersion } from '../../Common/models/ProposalLayoutVersion';
import {
  CreateProposalImageData,
  CreateProposalImageResult,
} from '../models/CreateProposaImage';
import { CreateProposalData } from '../models/CreateProposal';
import { APICreateProposalTemplateData } from '../models/CreateProposalTemplate';
import { APIProposal } from '../models/Proposal';
import { ProposalLayout } from '../models/ProposalLayout';
import { APIProposalTemplate } from '../models/ProposalTemplate';
import { APIUpdateProposalTemplateData } from '../models/UpdateProposalTemplate';
import { ProposalFormBuilder } from '../services/ProposalFormBuilder';

const TEST_PROPOSAL_LAYOUT_DATA = new ProposalFormBuilder().getProposalForm(
  TEST_PROPOSAL_LAYOUT_SCHEMA,
).value;

const templates: APIProposalTemplate[] = [
  {
    id: crypto.randomUUID(),
    name: 'Default Layout Template',
    layout_name: 'default',
    layout_version: 1,
    layout_data: {
      ...TEST_PROPOSAL_LAYOUT_DATA,
      generalSettings: {
        ...TEST_PROPOSAL_LAYOUT_DATA.generalSettings,
        primaryColor: '#0000ff',
      },
    },
  },
  {
    id: crypto.randomUUID(),
    name: 'Template for bigger companies',
    layout_name: 'default',
    layout_version: 1,
    layout_data: {
      ...TEST_PROPOSAL_LAYOUT_DATA,
      generalSettings: {
        ...TEST_PROPOSAL_LAYOUT_DATA.generalSettings,
        primaryColor: '#ff0000',
      },
    },
  },
  {
    id: crypto.randomUUID(),
    name: 'Outdated template',
    layout_name: 'default',
    layout_version: 0,
    layout_data: {
      generalSettings: {
        primaryColor: '#00ff00',
        unusedNameForSecondaryColor: '#ffffff',
      },
      sections: [
        ...TEST_PROPOSAL_LAYOUT_DATA.sections.slice(2),
        {
          ...TEST_PROPOSAL_LAYOUT_DATA.sections[0],
          settings: {
            ...TEST_PROPOSAL_LAYOUT_DATA.sections[0].settings,
            title: 'Solar panels system',
          },
        },
        {
          name: 'nonExistentSection',
          enabled: true,
          settings: {},
        },
      ],
    },
  },
  {
    id: crypto.randomUUID(),
    name: 'Unsupported version of the layout',
    layout_name: 'default',
    layout_version: 2,
    layout_data: TEST_PROPOSAL_LAYOUT_DATA,
  },
  {
    id: crypto.randomUUID(),
    name: 'Unsupported layout',
    layout_name: 'unsupported-layout',
    layout_version: 2,
    layout_data: TEST_PROPOSAL_LAYOUT_DATA,
  },
];

export const handlers = [
  rest.get(
    `${ConfigurationService.APP_CONFIG.apiUrl}/projects/:projectId/proposal-context`,
    (_req, res, ctx) => {
      return res(
        ctx.delay(50),
        ctx.status(200),
        ctx.json<APIProposalContext>(TEST_API_PROPOSAL_CONTEXT_DATA),
      );
    },
  ),
  rest.get(
    `${ConfigurationService.APP_CONFIG.apiUrl}/proposal-layouts/:layoutName`,
    (req, res, ctx) => {
      const { layoutName } = req.params;

      return res(
        ctx.status(200),
        ctx.json<ProposalLayout>({
          name: layoutName as string,
          versions: [1],
        }),
      );
    },
  ),
  rest.get(
    `${ConfigurationService.APP_CONFIG.apiUrl}/projects/:projectId/proposal-layouts/:layoutName/versions/:layoutVersion`,
    (req, res, ctx) => {
      const { layoutName, layoutVersion } = req.params;

      return res(
        ctx.delay(50),
        ctx.status(200),
        ctx.json<ProposalLayoutVersion>({
          name: layoutName as string,
          version: +(layoutVersion as string),
          schema: TEST_PROPOSAL_LAYOUT_SCHEMA,
        }),
      );
    },
  ),
  rest.post<CreateProposalData>(
    `${ConfigurationService.APP_CONFIG.apiUrl}/proposals`,
    (req, res, ctx) => {
      const { projectId } = req.body;

      const response: APIProposal = {
        id: 'random-id',
        project: projectId,
        pdf_file:
          'https://easysolar-api-production.s3.amazonaws.com/media/40101c16-dcfd-4ffd-9037-2d82aa35cb8e/proposals/345f8f64-54ce-43ff-8b47-0c3888042079/Home_6zLxZTM.pdf',
      };

      return res(ctx.delay(5000), ctx.status(201), ctx.json(response));
    },
  ),
  rest.post<CreateProposalImageData>(
    `${ConfigurationService.APP_CONFIG.apiUrl}/proposal-images`,
    (req, res, ctx) => {
      const { file } = req.body;

      const response: CreateProposalImageResult = {
        id: crypto.randomUUID(),
        name: file.name,
        file: URL.createObjectURL(file),
        created: new Date().toISOString(),
      };

      return res(ctx.delay(500), ctx.status(201), ctx.json(response));
    },
  ),
  rest.get<APIProposalTemplate[]>(
    `${ConfigurationService.APP_CONFIG.apiUrl}/proposal-templates`,
    (req, res, ctx) => {
      const layoutName = req.url.searchParams.get('layout_name');

      const filteredTemplates = layoutName
        ? templates.filter((template) => template.layout_name === layoutName)
        : templates;

      return res(ctx.delay(50), ctx.status(200), ctx.json(filteredTemplates));
    },
  ),
  rest.post<APICreateProposalTemplateData>(
    `${ConfigurationService.APP_CONFIG.apiUrl}/proposal-templates`,
    (req, res, ctx) => {
      const { name, layout_name, layout_version, layout_data } = req.body;

      const response: APIProposalTemplate = {
        id: crypto.randomUUID(),
        name,
        layout_name,
        layout_version,
        layout_data,
      };

      return res(ctx.delay(1000), ctx.status(201), ctx.json(response));
    },
  ),
  rest.patch<APIUpdateProposalTemplateData>(
    `${ConfigurationService.APP_CONFIG.apiUrl}/proposal-templates/:proposalTemplateId`,
    (req, res, ctx) => {
      const { proposalTemplateId } = req.params;
      const { name, layout_name, layout_version, layout_data } = req.body;

      const response: APIProposalTemplate = {
        id: proposalTemplateId as string,
        name: name || '',
        layout_name: layout_name || 'default',
        layout_version: layout_version || 1,
        layout_data: layout_data || TEST_PROPOSAL_LAYOUT_DATA,
      };

      return res(ctx.delay(1000), ctx.status(200), ctx.json(response));
    },
  ),
  rest.delete<string>(
    `${ConfigurationService.APP_CONFIG.apiUrl}/proposal-templates/:proposalTemplateId`,
    (_req, res, ctx) => {
      return res(ctx.delay(200), ctx.status(204));
    },
  ),
];
