import { ProposalLayoutSettingSchema } from './Settings';

export type ProposalLayoutRecordOfSettingsSchemas = Record<
  string,
  | ProposalLayoutSettingSchema
  | ProposalLayoutGroupOfSettingsSchema<ProposalLayoutRecordOfSettingsSchemas>
>;

export interface ProposalLayoutGroupOfSettingsSchema<
  Settings extends ProposalLayoutRecordOfSettingsSchemas = ProposalLayoutRecordOfSettingsSchemas,
> {
  title: string;
  type: 'object';
  settings: Array<keyof Settings>;
  properties: Settings;
}

export const isProposalSettingOrGroupOfSettingsSchema = (
  settingOrGroupOfSettingsSchema:
    | ProposalLayoutSettingSchema
    | ProposalLayoutGroupOfSettingsSchema,
): {
  isGroup: boolean;
  groupOfSettingsSchema: ProposalLayoutGroupOfSettingsSchema;
  settingSchema: ProposalLayoutSettingSchema;
} => {
  const settingSchema =
    settingOrGroupOfSettingsSchema as ProposalLayoutSettingSchema;
  const groupOfSettingsSchema =
    settingOrGroupOfSettingsSchema as ProposalLayoutGroupOfSettingsSchema;

  return {
    isGroup: !!(!settingSchema.settingType && groupOfSettingsSchema.settings),
    groupOfSettingsSchema,
    settingSchema,
  };
};
