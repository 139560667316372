import { makeStyles } from '@material-ui/core';

export const useProposalLayoutEditorStyles = makeStyles((theme) => ({
  sideBar: {
    overflowY: 'scroll',
  },
  previewColumn: {
    flex: 1,
    overflow: 'auto',
    backgroundColor: theme.palette.background.default,
  },
  previewContainer: {
    padding: `0 ${theme.spacing(6)}px`,
  },
  preview: {
    maxWidth: 800,
    margin: '0 auto',
  },
}));
