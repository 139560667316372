import Ajv, { ValidateFunction } from 'ajv';
import { JSONSchemaType } from 'ajv/dist/types/json-schema';
import ajvFormats from 'ajv-formats';

export class ProposalSettingsValidator {
  private static ajv = ajvFormats(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new Ajv({ allErrors: true, strict: false }) as any,
  );

  public static getJSONSchemaValidator<T>(
    jsonSchema: JSONSchemaType<T>,
  ): ValidateFunction<T> {
    return ProposalSettingsValidator.ajv.compile(jsonSchema);
  }
}
