import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProposalEditorToolbarTemplatesSectionConfirmDialog } from './ConfirmDialog';

interface UseProposalTemplateDiscardChangesDialogProps {
  onDiscard: (templateId: string | null) => void;
}

interface UseProposalTemplateDiscardChangesDialogResult {
  dialog: ReactElement;
  openDialog: (templateId: string | null, dirty: boolean) => void;
}

export function useProposalTemplateDiscardChangesDialog({
  onDiscard,
}: UseProposalTemplateDiscardChangesDialogProps): UseProposalTemplateDiscardChangesDialogResult {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>();

  const openDialog = useCallback(
    (templateId: string | null, dirty: boolean) => {
      if (dirty) {
        setSelectedTemplateId(templateId);
        setOpen(true);
      } else {
        onDiscard(templateId);
      }
    },
    [onDiscard],
  );

  const onClose = useCallback(() => {
    setSelectedTemplateId(null);
    setOpen(false);
  }, []);

  const onConfirm = useCallback(() => {
    if (selectedTemplateId) {
      onDiscard(selectedTemplateId);
    }

    onClose();
  }, [onClose, onDiscard, selectedTemplateId]);

  const dialog = useMemo(
    () => (
      <ProposalEditorToolbarTemplatesSectionConfirmDialog
        open={open}
        title={t('Editor:Toolbar.Templates.DiscardChangesDialog.Title')}
        message={t('Editor:Toolbar.Templates.DiscardChangesDialog.Message')}
        confirmButtonLabel={t(
          'Editor:Toolbar.Templates.DiscardChangesDialog.ConfirmButtonLabel',
        )}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    ),
    [onClose, onConfirm, open, t],
  );

  return { dialog, openDialog };
}
