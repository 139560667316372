import { Dialog, DialogProps } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { useShadowRootContainer } from '@easysolar/proposals/infrastructure/hooks/useShadowRootContainer';

export const ShadowRootDialog: FunctionComponent<DialogProps> = ({
  container,
  children,
  ...props
}) => {
  const shadowRootContainerRef = useShadowRootContainer();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dialog {...props} container={container || shadowRootContainerRef}>
      {children}
    </Dialog>
  );
};
