import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { selectAuthToken } from '../store/webComponentProps';
import { ProposalEditorWrapper } from './EditorWrapper';

export const App: FunctionComponent = () => {
  const authToken = useSelector(selectAuthToken);

  return authToken ? <ProposalEditorWrapper /> : null;
};
