import { AccordionProps } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';

import { ProposalLayoutGroupOfSettingsSchema } from '@easysolar/proposals/modules/Common/models/LayoutSchema/GroupOfSettings';
import { useFormGroup } from '@easysolar/proposals/shared/forms/hooks/useFormGroup';
import { FormGroup } from '@easysolar/proposals/shared/forms/models';

import { ProposalEditorSettings } from '../../Settings';
import { ProposalEditorSideBarAccordion } from '../Accordion';

interface ProposalEditorSideBarSettingsProps {
  icon?: JSX.Element;
  expanded?: boolean;
  onExpand?: AccordionProps['onChange'];
  formGroup: FormGroup;
  groupOfSettingsSchema: ProposalLayoutGroupOfSettingsSchema;
  disabled?: boolean;
}

export const ProposalEditorSideBarSettings: FunctionComponent<ProposalEditorSideBarSettingsProps> =
  ({
    icon,
    expanded,
    onExpand,
    formGroup,
    groupOfSettingsSchema,
    disabled,
  }) => {
    const { valid } = useFormGroup(formGroup);

    const content = useMemo(
      () => (
        <ProposalEditorSettings
          formGroup={formGroup}
          groupOfSettingsSchema={groupOfSettingsSchema}
        />
      ),
      [formGroup, groupOfSettingsSchema],
    );

    const accordion = useMemo(
      () => (
        <ProposalEditorSideBarAccordion
          title={groupOfSettingsSchema.title}
          icon={icon}
          expanded={expanded && !disabled}
          onExpand={onExpand}
          disabled={disabled}
          error={!valid}
        >
          {content}
        </ProposalEditorSideBarAccordion>
      ),
      [
        content,
        disabled,
        expanded,
        groupOfSettingsSchema.title,
        icon,
        onExpand,
        valid,
      ],
    );

    return accordion;
  };
