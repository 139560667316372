import { ProposalLayoutGroupOfSettingsSchema } from '../../../LayoutSchema/GroupOfSettings';
import { ProposalLayoutSettingSchemaBoolean } from '../../../LayoutSchema/Settings/Boolean';
import { ProposalLayoutSettingSchemaChoice } from '../../../LayoutSchema/Settings/Choice';
import { ProposalLayoutSettingSchemaColor } from '../../../LayoutSchema/Settings/Color';
import { ProposalLayoutSettingSchemaToggleableText } from '../../../LayoutSchema/Settings/ToggleableText';

export type ProposalLayoutDefaultGeneralSettingsSchema =
  ProposalLayoutGroupOfSettingsSchema<{
    primaryColor?: ProposalLayoutSettingSchemaColor;
    textColor?: ProposalLayoutSettingSchemaColor;
    secondaryColor?: ProposalLayoutSettingSchemaColor;
    isLogoVisible?: ProposalLayoutSettingSchemaBoolean;
    footer?: ProposalLayoutGroupOfSettingsSchema<{
      alignment?: ProposalLayoutSettingSchemaChoice<ProposalLayoutDefaultAlignment>;
      text?: ProposalLayoutSettingSchemaToggleableText;
    }>;
    coverPageFooter?: ProposalLayoutGroupOfSettingsSchema<{
      left?: ProposalLayoutSettingSchemaToggleableText;
      right?: ProposalLayoutSettingSchemaToggleableText;
    }>;
  }>;

export enum ProposalLayoutDefaultAlignment {
  Left = 'left',
  Right = 'right',
}
