import { ProposalLayoutSettingSchemaChoice } from '@easysolar/proposals/modules/Common/models/LayoutSchema/Settings/Choice';
import { ControlledRadioGroupField } from '@easysolar/proposals/shared/components/ControlledRadioGroupField';

import { ProposalEditorSettingBase } from './Base';

export const ProposalEditorSettingChoice: ProposalEditorSettingBase<
  ProposalLayoutSettingSchemaChoice<string>
> = ({ formControl, settingSchema }) => {
  return (
    <ControlledRadioGroupField
      formControl={formControl}
      label={settingSchema.title}
      choices={settingSchema.choices}
    />
  );
};
