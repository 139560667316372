import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CurrencyNumberFormat,
  CurrencyNumberFormatProps,
} from '@easysolar/proposals/shared/components/CurrencyNumberFormat';

import { useProposalContext } from '../hooks/useProposalContext';

export type ProposalCurrencyNumberFormatProps = Omit<
  CurrencyNumberFormatProps,
  'locales' | 'currency'
>;

export const ProposalCurrencyNumberFormat: FunctionComponent<ProposalCurrencyNumberFormatProps> =
  (props) => {
    const { i18n } = useTranslation();
    const proposalContext = useProposalContext();

    return (
      <CurrencyNumberFormat
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        locales={i18n.language}
        currency={proposalContext.project.currencyCode}
      />
    );
  };
