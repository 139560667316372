import { FunctionComponent, useEffect, useMemo, useRef } from 'react';

import { ProposalLayoutData } from '@easysolar/proposals/modules/Common/models/LayoutData/Layout';
import { ProposalLayoutName } from '@easysolar/proposals/modules/Common/models/Layouts/Base';
import { ProposalLayoutDefaultSchema } from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema';
import { ProposalLayoutDefaultVersionNumber } from '@easysolar/proposals/modules/Common/models/Layouts/Default/Versions';
import { ProposalLayoutVersionNumber } from '@easysolar/proposals/modules/Common/models/Layouts/Version';
import { ProposalContextData } from '@easysolar/proposals/modules/Common/models/ProposalContextData';

import { ProposalContext } from '../../../Common/hooks/useProposalContext';
import { PrintingContext } from '../../hooks/usePrintingContext';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { PrintingContextData } from '../../models/PrintingContextData';
import {
  ProposalPreviewDefault,
  ProposalPreviewDefaultProps,
} from '../Layouts/Default/Default';
import { useProposalPreviewStyles } from './Preview.styles';

export interface ProposalPreviewProps {
  printing: boolean;
  contextData: ProposalContextData;
  layoutName: ProposalLayoutName;
  layoutVersion: ProposalLayoutVersionNumber;
  layoutData: ProposalLayoutData<ProposalLayoutDefaultSchema>;
  activeSectionName: string | null;
  onRendered?: () => {};
}

export const PROPOSAL_PREVIEW_COMPONENTS: Record<
  ProposalLayoutName,
  Record<
    ProposalLayoutVersionNumber,
    FunctionComponent<ProposalPreviewDefaultProps>
  >
> = {
  [ProposalLayoutName.Default]: {
    [ProposalLayoutDefaultVersionNumber.V1]: ProposalPreviewDefault,
  },
};

export const ProposalPreview: FunctionComponent<ProposalPreviewProps> = ({
  printing,
  contextData: proposalContextData,
  layoutName,
  layoutVersion,
  layoutData,
  activeSectionName,
  onRendered,
}) => {
  const PreviewComponent = useMemo(() => {
    return PROPOSAL_PREVIEW_COMPONENTS[layoutName][layoutVersion] || null;
  }, [layoutName, layoutVersion]);

  const preview = useMemo(
    () =>
      PreviewComponent && (
        <PreviewComponent
          layoutData={layoutData}
          activeSectionName={activeSectionName}
        />
      ),
    [PreviewComponent, activeSectionName, layoutData],
  );

  const sizeContainerRef = useRef<HTMLObjectElement>(null);
  const sizeContainerContentRect = useResizeObserver(sizeContainerRef);

  const scaleContainerRef = useRef<HTMLObjectElement>(null);
  const scaleContainerContentRect = useResizeObserver(scaleContainerRef);

  const scale = useMemo(() => {
    if (!printing && sizeContainerContentRect && scaleContainerContentRect) {
      return sizeContainerContentRect.width / scaleContainerContentRect.width;
    }

    return 1;
  }, [printing, scaleContainerContentRect, sizeContainerContentRect]);

  const printingContextData = useMemo<PrintingContextData>(
    () => ({
      printing,
    }),
    [printing],
  );

  const classes = useProposalPreviewStyles({
    scaleContainerHeight: scaleContainerContentRect?.height,
    scale,
  });

  useEffect(() => {
    // call onRendered in the next animation frame
    setTimeout(() => {
      if (onRendered) {
        onRendered();
      }
    });
  }, [onRendered]);

  const previewContainer = useMemo(
    () => (
      <PrintingContext.Provider value={printingContextData}>
        <ProposalContext.Provider value={proposalContextData}>
          <div ref={sizeContainerRef} className={classes.sizeContainer}>
            <div ref={scaleContainerRef} className={classes.scaleContainer}>
              {preview}
            </div>
          </div>
        </ProposalContext.Provider>
      </PrintingContext.Provider>
    ),
    [
      printingContextData,
      proposalContextData,
      classes.sizeContainer,
      classes.scaleContainer,
      preview,
    ],
  );

  return previewContainer;
};
