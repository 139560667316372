import { FunctionComponent, useMemo } from 'react';

import { NonBreakingSpace } from '../NonBreakingSpace';
import {
  NumberFormat,
  NumberFormatPart,
  NumberFormatProps,
} from '../NumberFormat';

export interface UnitNumberFormatProps
  extends Omit<NumberFormatProps, 'additionalParts'> {
  unit?: string;
  addWhitespace?: boolean;
}

export const UnitNumberFormat: FunctionComponent<UnitNumberFormatProps> = ({
  unit,
  addWhitespace = true,
  ...props
}) => {
  const additionalParts: NumberFormatPart[] = useMemo(() => {
    if (unit) {
      return [
        addWhitespace
          ? {
              type: 'literal',
              value: NonBreakingSpace,
            }
          : undefined,
        {
          type: 'unit',
          value: unit,
        },
      ].filter((part) => !!part) as NumberFormatPart[];
    }

    return [];
  }, [addWhitespace, unit]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <NumberFormat {...props} additionalParts={additionalParts} />;
};
