import { Box, Typography } from '@material-ui/core';
import { FunctionComponent, useCallback, useMemo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ProposalCurrencyNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalCurrencyNumberFormat';
import { ProposalNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalNumberFormat';
import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';
import { ProposalContextDataValuationRow } from '@easysolar/proposals/modules/Common/models/ProposalContextData';

import {
  ProposalPreviewDefaultTable,
  ProposalPreviewDefaultTableColumn,
  ProposalPreviewDefaultTableColumnOnRenderCell,
} from '../../../Shared/Table';
import { ProposalPreviewDefaultCustomerProposalCaption } from '../Caption';
import { ProposalPreviewDefaultCustomerProposalComment } from '../Comment';
import { getProposalPreviewDefaultCustomerProposalRowStyles } from './Row.styles';

export interface ProposalPreviewDefaultCustomerProposalRowProps {
  header?: string;
  row: ProposalContextDataValuationRow;
  showColumns: ProposalPreviewDefaultCustomerProposalRowShowColumns;
}

export type ProposalPreviewDefaultCustomerProposalRowShowColumns = Record<
  keyof Omit<ProposalContextDataValuationRow, 'comment'>,
  boolean
>;

export const ProposalPreviewDefaultCustomerProposalRow: FunctionComponent<ProposalPreviewDefaultCustomerProposalRowProps> =
  ({ header, row, showColumns }) => {
    const { t } = useTranslation();

    const {
      project: { currencyCode },
    } = useProposalContext();

    const classes = getProposalPreviewDefaultCustomerProposalRowStyles();

    const getNameWithUnit = useCallback(
      (name: string, unit: string) => `${name} [${unit}]`,
      [],
    );

    const getNameWithCurrency = useCallback(
      (name: string) => getNameWithUnit(name, currencyCode),
      [currencyCode, getNameWithUnit],
    );

    const onRenderNameHeader = useCallback(
      () => (
        <Typography variant="body2" className={classes.header}>
          {header}
        </Typography>
      ),
      [classes.header, header],
    );

    const onRenderEmptyHeader = useCallback(() => null, []);

    const onRenderNameRow = useCallback<
      ProposalPreviewDefaultTableColumnOnRenderCell<ProposalContextDataValuationRow>
    >(
      ({ column: { name } }) => (
        <ProposalPreviewDefaultCustomerProposalCaption caption={name} />
      ),
      [],
    );

    const getOnRenderCell = useCallback<
      (
        onRender: ProposalPreviewDefaultTableColumnOnRenderCell<ProposalContextDataValuationRow>,
      ) => ProposalPreviewDefaultTableColumnOnRenderCell<ProposalContextDataValuationRow>
    >(
      (onRender) =>
        (props): React.ReactElement | null => {
          const { index } = props;

          if (index === 0) {
            return onRenderNameRow(props);
          }

          if (index === 1) {
            return onRender(props);
          }

          return null;
        },
      [onRenderNameRow],
    );

    const onRenderTextCellValue = useCallback<
      ProposalPreviewDefaultTableColumnOnRenderCell<ProposalContextDataValuationRow>
    >(
      ({ column, item }) => (
        <Typography variant="caption" className={classes.valueCell}>
          {item[column.fieldName]}
        </Typography>
      ),
      [classes.valueCell],
    );

    const onRenderTextCell = useMemo(
      () => getOnRenderCell(onRenderTextCellValue),
      [getOnRenderCell, onRenderTextCellValue],
    );

    const onRenderAmountCellValue = useCallback<
      ProposalPreviewDefaultTableColumnOnRenderCell<ProposalContextDataValuationRow>
    >(
      ({ column, item }) => (
        <ProposalNumberFormat
          value={item[column.fieldName] as number}
          defaultProps={{ variant: 'caption', className: classes.valueCell }}
        />
      ),
      [classes.valueCell],
    );

    const onRenderAmountCell = useMemo(
      () => getOnRenderCell(onRenderAmountCellValue),
      [getOnRenderCell, onRenderAmountCellValue],
    );

    const onRenderCurrencyCellValue = useCallback<
      ProposalPreviewDefaultTableColumnOnRenderCell<ProposalContextDataValuationRow>
    >(
      ({ column, item }) => (
        <ProposalCurrencyNumberFormat
          value={item[column.fieldName] as number}
          omitParts={['currency', 'literal']}
          defaultProps={{
            variant: 'caption',
            className: classes.valueCell,
          }}
        />
      ),
      [classes.valueCell],
    );

    const onRenderCurrencyCell = useMemo(
      () => getOnRenderCell(onRenderCurrencyCellValue),
      [getOnRenderCell, onRenderCurrencyCellValue],
    );

    const onRenderRatioCellValue = useCallback<
      ProposalPreviewDefaultTableColumnOnRenderCell<ProposalContextDataValuationRow>
    >(
      ({ column, item }) => (
        <ProposalNumberFormat
          value={(item[column.fieldName] as number) * 100}
          defaultProps={{
            variant: 'caption',
            className: classes.valueCell,
          }}
        />
      ),
      [classes.valueCell],
    );

    const onRenderRatioCell = useMemo(
      () => getOnRenderCell(onRenderRatioCellValue),
      [getOnRenderCell, onRenderRatioCellValue],
    );

    const NAME_COLUMN_WIDTH = 11;
    const DEFAULT_COLUMN_WIDTH = 5;

    const columnsDefinitions = useMemo<
      ProposalPreviewDefaultTableColumn<ProposalContextDataValuationRow>[]
    >(
      () => [
        {
          key: 'name',
          fieldName: 'name',
          name: t('Layouts:Default.Sections.CustomerProposal.Rows.NameLabel'),
          width: NAME_COLUMN_WIDTH,
          onRenderHeader: onRenderNameHeader,
          onRenderCell: onRenderTextCell,
        },
        {
          key: 'amount',
          fieldName: 'amount',
          name: t('Layouts:Default.Sections.CustomerProposal.Rows.AmountLabel'),
          width: DEFAULT_COLUMN_WIDTH,
          onRenderHeader: onRenderEmptyHeader,
          onRenderCell: onRenderAmountCell,
        },
        {
          key: 'unit',
          fieldName: 'unit',
          name: t('Layouts:Default.Sections.CustomerProposal.Rows.UnitLabel'),
          width: DEFAULT_COLUMN_WIDTH,
          onRenderHeader: onRenderEmptyHeader,
          onRenderCell: onRenderTextCell,
        },
        {
          key: 'netUnitPrice',
          fieldName: 'netUnitPrice',
          name: getNameWithCurrency(
            t(
              'Layouts:Default.Sections.CustomerProposal.Rows.NetUnitPriceLabel',
            ),
          ),
          width: DEFAULT_COLUMN_WIDTH,
          onRenderHeader: onRenderEmptyHeader,
          onRenderCell: onRenderCurrencyCell,
        },
        {
          key: 'tax',
          fieldName: 'tax',
          name: getNameWithUnit(
            t('Layouts:Default.Sections.CustomerProposal.Rows.TaxLabel'),
            '%',
          ),
          width: DEFAULT_COLUMN_WIDTH,
          onRenderHeader: onRenderEmptyHeader,
          onRenderCell: onRenderRatioCell,
        },
        {
          key: 'discount',
          fieldName: 'discount',
          name: getNameWithUnit(
            t('Layouts:Default.Sections.CustomerProposal.Rows.DiscountLabel'),
            '%',
          ),
          width: DEFAULT_COLUMN_WIDTH,
          onRenderHeader: onRenderEmptyHeader,
          onRenderCell: onRenderRatioCell,
        },
        {
          key: 'netPrice',
          fieldName: 'netPrice',
          name: getNameWithCurrency(
            t('Layouts:Default.Sections.CustomerProposal.Rows.NetPriceLabel'),
          ),
          width: DEFAULT_COLUMN_WIDTH,
          onRenderHeader: onRenderEmptyHeader,
          onRenderCell: onRenderCurrencyCell,
        },
        {
          key: 'totalPrice',
          fieldName: 'totalPrice',
          name: getNameWithCurrency(
            t('Layouts:Default.Sections.CustomerProposal.Rows.TotalPriceLabel'),
          ),
          width: DEFAULT_COLUMN_WIDTH,
          onRenderHeader: onRenderEmptyHeader,
          onRenderCell: onRenderCurrencyCell,
        },
      ],
      [
        getNameWithCurrency,
        getNameWithUnit,
        onRenderAmountCell,
        onRenderCurrencyCell,
        onRenderEmptyHeader,
        onRenderNameHeader,
        onRenderRatioCell,
        onRenderTextCell,
        t,
      ],
    );

    const columns = useMemo(() => {
      const filteredColumns = columnsDefinitions.filter(
        (column) =>
          showColumns[
            column.fieldName as keyof ProposalPreviewDefaultCustomerProposalRowShowColumns
          ],
      );

      const sumOfRelativeWidths = filteredColumns.reduce(
        (sum, column) => sum + (column.width as number),
        0,
      );

      return filteredColumns.map((column) => ({
        ...column,
        width: `${((column.width as number) / sumOfRelativeWidths) * 100}%`,
      }));
    }, [columnsDefinitions, showColumns]);

    const items = useMemo(() => [row, row], [row]);

    return (
      <Box className={classes.root}>
        <ProposalPreviewDefaultTable
          columns={columns}
          items={items}
          showHeader={!!header}
        />
        {row.comment && (
          <Box className={classes.commentContainer}>
            <ProposalPreviewDefaultCustomerProposalComment
              label={t(
                'Layouts:Default.Sections.CustomerProposal.Rows.CommentLabel',
              )}
              comment={row.comment}
            />
          </Box>
        )}
      </Box>
    );
  };
