import { Grid, GridJustification, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { FunctionComponent, useMemo } from 'react';

import { getProposalPreviewDefaultLabelWithIconStyles } from './LabelWithIcon.styles';

interface ProposalPreviewDefaultLabelWithIconProps {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  justifyContent?: GridJustification;
}

export const ProposalPreviewDefaultLabelWithIcon: FunctionComponent<ProposalPreviewDefaultLabelWithIconProps> =
  ({ children, Icon, justifyContent = 'flex-start' }) => {
    const classes = getProposalPreviewDefaultLabelWithIconStyles();

    const renderedIcon = useMemo(
      () => <Icon className={classes.icon} />,
      [Icon, classes.icon],
    );

    return (
      <Grid
        item
        container
        className={classes.root}
        justifyContent={justifyContent}
        alignItems="center"
        wrap="nowrap"
      >
        {renderedIcon}
        {children}
      </Grid>
    );
  };
