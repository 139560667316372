import { ThemeProvider } from '@material-ui/core';
import { DireflowComponent } from 'direflow-component';
import { FunctionComponent, useEffect } from 'react';
import { Provider } from 'react-redux';

import { ShadowRootContainer } from '@easysolar/proposals/infrastructure/components/ShadowRootContainer';
import { withI18NInstance } from '@easysolar/proposals/infrastructure/components/withI18NInstance';
import { useLoadGoogleFonts } from '@easysolar/proposals/infrastructure/hooks/useLoadGoogleFonts';
import { ConfigurationService } from '@easysolar/proposals/infrastructure/services/ConfigurationService';
import {
  InternationalizationService,
  Namespace,
} from '@easysolar/proposals/infrastructure/services/InternationalizationService';
import { theme } from '@easysolar/proposals/infrastructure/Theme';

import { App } from './components/App';
import { EmployeeRole } from './models/EmployeeRole';
import { ProposalTemplatesPermission } from './models/ProposalTemplatesPermission';
import { ProposalEditorWebComponentProps } from './models/WebComponentProps';
import { store } from './store';
import { resetStore } from './store/root';
import {
  setApiBaseUrl,
  setAuthToken,
  setEmployeeProposalTemplatesPermission,
  setEmployeeRole,
  setLanguage,
  setProjectId,
} from './store/webComponentProps';

const TranslatedApp = withI18NInstance(
  App,
  [Namespace.Shared, Namespace.Editor, Namespace.Layouts],
  Namespace.Editor,
);

const FONT_DEFINITIONS = [
  {
    family: 'Roboto',
    weights: [300, 400, 500, 700],
  },
];

const WrappedProposalEditor: FunctionComponent<ProposalEditorWebComponentProps> =
  ({
    language,
    apiBaseUrl,
    authToken,
    projectId,
    employeeRole,
    employeeProposalTemplatesPermission,
  }) => {
    useLoadGoogleFonts(FONT_DEFINITIONS);

    useEffect((): (() => void) | undefined => {
      if (ConfigurationService.APP_CONFIG.useMocks) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
        const { worker } = require('./mocks/browser');

        worker.start({
          onUnhandledRequest: 'bypass',
        });

        return (): void => {
          worker.stop();
        };
      }

      return undefined;
    }, []);

    useEffect(() => {
      if (language) {
        store.dispatch(setLanguage(language));
      }
    }, [language]);

    useEffect(() => {
      store.dispatch(setApiBaseUrl(apiBaseUrl));
    }, [apiBaseUrl]);

    useEffect(() => {
      if (authToken) {
        store.dispatch(setAuthToken(authToken));
      }
    }, [authToken]);

    useEffect(() => {
      if (projectId) {
        store.dispatch(setProjectId(projectId));
      }
    }, [projectId]);

    useEffect(() => {
      if (employeeRole) {
        store.dispatch(setEmployeeRole(employeeRole));
      }
    }, [employeeRole]);

    useEffect(() => {
      if (employeeRole) {
        store.dispatch(
          setEmployeeProposalTemplatesPermission(
            employeeProposalTemplatesPermission,
          ),
        );
      }
    }, [employeeProposalTemplatesPermission, employeeRole]);

    useEffect(() => {
      return (): void => {
        store.dispatch(resetStore());
      };
    }, []);

    return (
      <ShadowRootContainer>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <TranslatedApp language={language} />
          </ThemeProvider>
        </Provider>
      </ShadowRootContainer>
    );
  };

export const ProposalEditorWebComponent = DireflowComponent.create({
  component: WrappedProposalEditor,
  configuration: {
    tagname: 'easysolar-proposal-editor',
  },
  properties: {
    language: ConfigurationService.APP_CONFIG.useMocks
      ? InternationalizationService.DEFAULT_LANGUAGE
      : undefined,
    apiBaseUrl: ConfigurationService.APP_CONFIG.apiUrl,
    authToken: ConfigurationService.APP_CONFIG.useMocks ? 'mocked_token' : null,
    projectId: ConfigurationService.APP_CONFIG.useMocks
      ? 'mocked-project-id'
      : null,
    employeeRole: ConfigurationService.APP_CONFIG.useMocks
      ? EmployeeRole.Owner
      : EmployeeRole.Member,
    employeeProposalTemplatesPermission: ConfigurationService.APP_CONFIG
      .useMocks
      ? ProposalTemplatesPermission.FullAccess
      : ProposalTemplatesPermission.TemplatesUsage,
  } as ProposalEditorWebComponentProps,
  plugins: [
    {
      name: 'material-ui',
    },
  ],
});
