import { ProposalLayoutAnyData } from '../../Common/models/LayoutData/Layout';

export interface ProposalTemplate {
  id: string;
  name: string;
  layoutName: string;
  layoutVersion: number;
  layoutData: ProposalLayoutAnyData;
}

export interface APIProposalTemplate {
  id: string;
  name: string;
  layout_name: string;
  layout_version: number;
  layout_data: ProposalLayoutAnyData;
}

export const PROPOSAL_TEMPLATE_NAME_MAX_LENGTH = 120;
