import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  UnitNumberFormat,
  UnitNumberFormatProps,
} from '@easysolar/proposals/shared/components/UnitNumberFormat';

export type ProposalUnitNumberFormatProps = Omit<
  UnitNumberFormatProps,
  'locales'
>;

export const ProposalUnitNumberFormat: FunctionComponent<ProposalUnitNumberFormatProps> =
  (props) => {
    const { i18n } = useTranslation();

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <UnitNumberFormat {...props} locales={i18n.language} />;
  };
