import { MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { MutationResultSelectorResult } from '@reduxjs/toolkit/dist/query/core/buildSelectors';
import {
  MutationTrigger,
  UseMutation,
  UseMutationStateOptions,
  UseMutationStateResult,
} from '@reduxjs/toolkit/dist/query/react/buildHooks';

export const getFixedCacheKeyMutation = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  D extends MutationDefinition<any, any, any, any>,
>(
  useMutation: UseMutation<D>,
  fixedCacheKey: string,
) => {
  return (
    options?: UseMutationStateOptions<D, MutationResultSelectorResult<D>>,
  ): readonly [
    MutationTrigger<D>,
    UseMutationStateResult<D, MutationResultSelectorResult<D>>,
  ] =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation({ ...options, fixedCacheKey });
};
