import {
  createElement,
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import {
  PROPOSAL_PREVIEW_WEB_COMPONENT_TAG_NAME,
  ProposalPreviewWebComponentProps,
} from '@easysolar/proposals/modules/ProposalPreview/api';

type AttributeName = keyof ProposalPreviewWebComponentProps;
type AttrbiuteValue = ProposalPreviewWebComponentProps[AttributeName];

export const ProposalEditorPreviewWebComponentWrapper: FunctionComponent<ProposalPreviewWebComponentProps> =
  (props) => {
    const previewRef = useRef<ProposalPreviewWebComponentProps>();

    const preview = useMemo(
      () =>
        createElement(PROPOSAL_PREVIEW_WEB_COMPONENT_TAG_NAME, {
          ref: previewRef,
        }),
      [],
    );

    useEffect(() => {
      if (previewRef.current) {
        (
          Object.entries(props) as Array<[AttributeName, AttrbiuteValue]>
        ).forEach(([key, value]) => {
          ((previewRef.current as ProposalPreviewWebComponentProps)[
            key
          ] as AttrbiuteValue) = value;
        });
      }
    }, [props]);

    return preview;
  };
