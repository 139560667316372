import { Grid, Typography } from '@material-ui/core';
import { FunctionComponent, useCallback, useMemo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ProposalUnitNumberFormat } from '@easysolar/proposals/modules/Common/components/ProposalUnitNumberFormat';
import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';

import {
  ProposalPreviewDefaultIcon,
  ProposalPreviewDefaultSvgIcon,
} from '../../../../Shared/SvgIcon';
import {
  ProposalPreviewDefaultTable,
  ProposalPreviewDefaultTableColumn,
  ProposalPreviewDefaultTableColumnOnRenderCell,
} from '../../../../Shared/Table';
import { getProposalPreviewDefaultEnvironmentalImpactTableStyles } from './Table.styles';

export interface ProposalPreviewDefaultEnvironmentalImpactTableProps {
  labelsHeader: string;
  items: EnvironmentalImpactTableItem[];
}

export interface EnvironmentalImpactTableItem {
  label: {
    icon: ProposalPreviewDefaultIcon;
    text: string;
  };
  values: number[];
}

type EnvironmentalImpactTableGeneratedItem = {
  label: {
    icon: ProposalPreviewDefaultIcon;
    text: string;
  };
} & Record<string, unknown>;

export const ProposalPreviewDefaultEnvironmentalImpactTable: FunctionComponent<ProposalPreviewDefaultEnvironmentalImpactTableProps> =
  ({ labelsHeader, items }) => {
    const { t } = useTranslation();

    const {
      project: { analysisPeriod },
    } = useProposalContext();

    const classes = getProposalPreviewDefaultEnvironmentalImpactTableStyles();

    const years = useMemo(
      () => [1, 5, 10, analysisPeriod].filter((year) => year <= analysisPeriod),
      [analysisPeriod],
    );

    const getYearKey = useCallback((year: number) => `year-${year}`, []);

    const generatedItems: EnvironmentalImpactTableGeneratedItem[] = useMemo(
      () =>
        items.map((item) => ({
          label: item.label,
          ...years.reduce((result, year) => {
            const key = getYearKey(year);
            // eslint-disable-next-line no-param-reassign
            result[key] = item.values[year - 1];

            return result;
          }, {} as Record<string, string | number>),
        })),
      [getYearKey, items, years],
    );

    const onRenderLabelCell: ProposalPreviewDefaultTableColumnOnRenderCell<EnvironmentalImpactTableGeneratedItem> =
      useCallback(
        ({ item }) => (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            wrap="nowrap"
          >
            <ProposalPreviewDefaultSvgIcon
              icon={item.label.icon}
              className={classes.labelIcon}
            />
            <Typography variant="caption" className={classes.labelText}>
              {item.label.text}
            </Typography>
          </Grid>
        ),
        [classes.labelIcon, classes.labelText],
      );

    const labelColumn = useMemo(
      () => ({
        key: 'label',
        name: labelsHeader,
        headerTextStyle: {
          textTransform: 'uppercase',
          textAlign: 'start',
        },
        onRenderCell: onRenderLabelCell,
      }),
      [labelsHeader, onRenderLabelCell],
    );

    const onRenderValueCell: ProposalPreviewDefaultTableColumnOnRenderCell<EnvironmentalImpactTableGeneratedItem> =
      useCallback(
        ({ column, item }): React.ReactElement | null =>
          column?.fieldName ? (
            <ProposalUnitNumberFormat
              value={item[column.fieldName] as number}
              display="block"
              options={{ maximumFractionDigits: 0 }}
              defaultProps={{
                variant: 'caption',
                className: classes.valueCell,
              }}
            />
          ) : null,
        [classes.valueCell],
      );

    const valuesColumns = useMemo(() => {
      const width = `${48 / years.length}%`;

      return years.map((year) => {
        const yearKey = getYearKey(year);

        return {
          key: yearKey,
          name: t(
            'Layouts:Default.Sections.EnergyProductionAndEnvironmentalImpact.Subsections.EnvironmentalImpact.Years',
            {
              count: year,
            },
          ),
          fieldName: yearKey,
          width,
          onRenderCell: onRenderValueCell,
        };
      });
    }, [getYearKey, onRenderValueCell, t, years]);

    const columns = useMemo(
      () =>
        [
          labelColumn,
          ...valuesColumns,
        ] as ProposalPreviewDefaultTableColumn<EnvironmentalImpactTableGeneratedItem>[],
      [labelColumn, valuesColumns],
    );

    return (
      <ProposalPreviewDefaultTable columns={columns} items={generatedItems} />
    );
  };
