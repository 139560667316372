import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from 'react';

interface ProposalPreviewStylesProps {
  scaleContainerHeight?: number;
  scale: number;
}

export const useProposalPreviewStyles = makeStyles<
  Theme,
  ProposalPreviewStylesProps
>(() => ({
  sizeContainer: {
    overflow: 'hidden',
    height: ({ scaleContainerHeight, scale }): CSSProperties['height'] => {
      if (scaleContainerHeight) {
        return scaleContainerHeight * scale;
      }

      return 'initial';
    },
  },
  scaleContainer: {
    transformOrigin: 'left top',
    display: 'inline-block',
    transform: ({ scale }): CSSProperties['transform'] => `scale(${scale})`,
  },
  '@media print': {
    sizeContainer: {
      height: 'initial',
    },
    scaleContainer: {
      transform: 'unset',
    },
  },
}));
