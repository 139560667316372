import {
  AbstractFormControlResult,
  FormGroup,
  FormGroupResult,
} from '../models';
import { useAbstractFormControl } from './useAbstractFormControl';

export interface UseFormGroupResult<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any> = Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
> extends FormGroupResult<T, E> {
  formGroup: FormGroup<T, E>;
}

export function useFormGroup<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any> = Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Record<string, any> = Record<string, any>,
>(formGroup: FormGroup<T, E>): AbstractFormControlResult<T, E> {
  return useAbstractFormControl(
    formGroup as FormGroup,
  ) as AbstractFormControlResult<T, E>;
}
