import { useMemo } from 'react';

import {
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName,
} from '@easysolar/proposals/modules/Common/models/Layouts/Default/Schema';

import { ProposalPreviewSection } from '../../../../Section';
import { ProposalPreviewDefaultSectionPage } from '../../Shared/SectionPage';
import { ProposalPreviewDefaultInvestmentCostsElectricityBill } from './ElectricityBill';
import { ProposalPreviewDefaultInvestmentCostsEnergyCosts } from './EnergyCosts';

export const ProposalPreviewDefaultInvestmentCosts: ProposalPreviewSection<
  ProposalLayoutDefaultSchema,
  ProposalLayoutDefaultSectionName.InvestmentCosts
> = ({ generalSettings, sectionSettings }) => {
  const electricityBill = useMemo(
    () => (
      <ProposalPreviewDefaultInvestmentCostsElectricityBill
        title={sectionSettings.electricityBill.title}
        showUpfrontCostsAfterIncentives={
          sectionSettings.electricityBill.showUpfrontCostsAfterIncentives
        }
        showAverageAnnualSavings={
          sectionSettings.electricityBill.showAverageAnnualSavings
        }
        showSavings={sectionSettings.electricityBill.showSavings}
        showSubsidies={sectionSettings.electricityBill.showSubsidies}
      />
    ),
    [
      sectionSettings.electricityBill.showAverageAnnualSavings,
      sectionSettings.electricityBill.showSavings,
      sectionSettings.electricityBill.showSubsidies,
      sectionSettings.electricityBill.showUpfrontCostsAfterIncentives,
      sectionSettings.electricityBill.title,
    ],
  );

  const electricityBillElement = useMemo(
    () => sectionSettings.electricityBill.showSubsection && electricityBill,
    [electricityBill, sectionSettings.electricityBill.showSubsection],
  );

  const energyCosts = useMemo(
    () => (
      <ProposalPreviewDefaultInvestmentCostsEnergyCosts
        title={sectionSettings.energyCosts.title}
      />
    ),
    [sectionSettings.energyCosts.title],
  );

  const energyCostsElement = useMemo(
    () => sectionSettings.energyCosts.showSubsection && energyCosts,
    [energyCosts, sectionSettings.energyCosts.showSubsection],
  );

  const page = useMemo(
    () => (
      <ProposalPreviewDefaultSectionPage
        generalSettings={generalSettings}
        title={sectionSettings.title}
        subtitle={sectionSettings.subtitle}
      >
        {electricityBillElement}
        {energyCostsElement}
      </ProposalPreviewDefaultSectionPage>
    ),
    [
      electricityBillElement,
      energyCostsElement,
      generalSettings,
      sectionSettings.subtitle,
      sectionSettings.title,
    ],
  );

  return page;
};
