import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  InternationalizationService,
  Language,
} from '@easysolar/proposals/infrastructure/services/InternationalizationService';

import { EmployeeRole } from '../models/EmployeeRole';
import { ProposalTemplatesPermission } from '../models/ProposalTemplatesPermission';
import { ProposalEditorWebComponentProps } from '../models/WebComponentProps';
import type { RootState } from './root';

export interface WebComponentPropsState
  extends ProposalEditorWebComponentProps {
  language: Language;
}

export const webComponentPropsSlice = createSlice({
  name: 'webComponentProps',
  initialState: {
    language: InternationalizationService.DEFAULT_LANGUAGE,
    apiBaseUrl: null,
    authToken: null,
    projectId: null,
  } as WebComponentPropsState,
  reducers: {
    setLanguage: (state, { payload: language }: PayloadAction<Language>) => {
      state.language = language;
    },
    setApiBaseUrl: (
      state,
      { payload: apiBaseUrl }: PayloadAction<string | null>,
    ) => {
      state.apiBaseUrl = apiBaseUrl;
    },
    setAuthToken: (state, { payload: authToken }: PayloadAction<string>) => {
      state.authToken = authToken;
    },
    setProjectId: (state, { payload: projectId }: PayloadAction<string>) => {
      state.projectId = projectId;
    },
    setEmployeeRole: (
      state,
      { payload: employeeRole }: PayloadAction<EmployeeRole>,
    ) => {
      state.employeeRole = employeeRole;
    },
    setEmployeeProposalTemplatesPermission: (
      state,
      {
        payload: employeeProposalTemplatesPermission,
      }: PayloadAction<ProposalTemplatesPermission>,
    ) => {
      state.employeeProposalTemplatesPermission =
        employeeProposalTemplatesPermission;
    },
  },
});

export const {
  setLanguage,
  setApiBaseUrl,
  setAuthToken,
  setProjectId,
  setEmployeeRole,
  setEmployeeProposalTemplatesPermission,
} = webComponentPropsSlice.actions;

export const selectWebComponentProps = createSelector(
  (state: RootState) => state.webComponentProps,
  (webComponentProps) => webComponentProps,
);

export const selectLanguage = createSelector(
  selectWebComponentProps,
  (props) => props.language,
);

export const selectApiBaseUrl = createSelector(
  selectWebComponentProps,
  (props) => props.apiBaseUrl,
);

export const selectAuthToken = createSelector(
  selectWebComponentProps,
  (props) => props.authToken,
);

export const selectProjectId = createSelector(
  selectWebComponentProps,
  (props) => props.projectId,
);

export const selectEmployeeRole = createSelector(
  selectWebComponentProps,
  (props) => props.employeeRole,
);

const selectEmployeeProposalTemplatesPlainPermission = createSelector(
  selectWebComponentProps,
  (props) => props.employeeProposalTemplatesPermission,
);

export const selectEmployeeProposalTemplatesPermission = createSelector(
  selectEmployeeRole,
  selectEmployeeProposalTemplatesPlainPermission,
  (role, permission) =>
    role === EmployeeRole.Owner || role === EmployeeRole.Admin
      ? ProposalTemplatesPermission.FullAccess
      : permission,
);
