import { Box, useTheme } from '@material-ui/core';
import { clamp } from 'lodash';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useProposalContext } from '@easysolar/proposals/modules/Common/hooks/useProposalContext';

import { ProposalPreviewDefaultSubsection } from '../../../Shared/Subsection';
import { getProposalPreviewDefaultInvestmentCostsEnergyCostsStyles } from './EnergyCosts.styles';
import { ProposalPreviewDefaultInvestmentCostsEnergyCostsProgressBar } from './ProgressBar';
import { ProposalPreviewDefaultInvestmentCostsEnergyCostsTable } from './Table';

interface ProposalPreviewDefaultInvestmentCostsEnergyCostsProps {
  title: string;
}

export const ProposalPreviewDefaultInvestmentCostsEnergyCosts: FunctionComponent<ProposalPreviewDefaultInvestmentCostsEnergyCostsProps> =
  ({ title }) => {
    const { t } = useTranslation();

    const theme = useTheme();

    const classes = getProposalPreviewDefaultInvestmentCostsEnergyCostsStyles();

    const {
      project: {
        financialAnalysis: {
          forecast: {
            electricityCostsWithPVSystem,
            electricityCostsWithoutPVSystem,
          },
        },
      },
    } = useProposalContext();

    const nonNegativeElectricityCostsWithPVSystem = useMemo(
      () => Math.max(0, electricityCostsWithPVSystem),
      [electricityCostsWithPVSystem],
    );

    const progressWithPVSystem = useMemo(
      () =>
        clamp(
          nonNegativeElectricityCostsWithPVSystem /
            electricityCostsWithoutPVSystem,
          0,
          1,
        ),
      [
        electricityCostsWithoutPVSystem,
        nonNegativeElectricityCostsWithPVSystem,
      ],
    );

    const electricityCostsWithPVSystemBar = useMemo(
      () => (
        <ProposalPreviewDefaultInvestmentCostsEnergyCostsProgressBar
          className={classes.electricityCostsWithPVSystem}
          label={t(
            'Layouts:Default.Sections.InvestmentCosts.Subsections.EnergyCosts.ElectricityCostsWithPVSystemLabel',
          )}
          value={nonNegativeElectricityCostsWithPVSystem}
          progress={progressWithPVSystem}
          color={theme.palette.secondary.main}
        />
      ),
      [
        classes.electricityCostsWithPVSystem,
        nonNegativeElectricityCostsWithPVSystem,
        progressWithPVSystem,
        t,
        theme.palette.secondary.main,
      ],
    );

    const progressWithoutPVSystem = useMemo(
      () =>
        clamp(
          electricityCostsWithoutPVSystem /
            nonNegativeElectricityCostsWithPVSystem,
          0,
          1,
        ),
      [
        electricityCostsWithoutPVSystem,
        nonNegativeElectricityCostsWithPVSystem,
      ],
    );

    const electricityCostsWithoutPVSystemBar = useMemo(
      () => (
        <ProposalPreviewDefaultInvestmentCostsEnergyCostsProgressBar
          label={t(
            'Layouts:Default.Sections.InvestmentCosts.Subsections.EnergyCosts.ElectricityCostsWithoutPVSystemLabel',
          )}
          value={electricityCostsWithoutPVSystem}
          progress={progressWithoutPVSystem}
          color={theme.palette.text.primary}
        />
      ),
      [
        electricityCostsWithoutPVSystem,
        progressWithoutPVSystem,
        t,
        theme.palette.text.primary,
      ],
    );

    const table = useMemo(
      () => <ProposalPreviewDefaultInvestmentCostsEnergyCostsTable />,
      [],
    );

    const subsection = useMemo(
      () => (
        <ProposalPreviewDefaultSubsection title={title}>
          <Box>
            {electricityCostsWithPVSystemBar}
            {electricityCostsWithoutPVSystemBar}
          </Box>
          {table}
        </ProposalPreviewDefaultSubsection>
      ),
      [
        electricityCostsWithPVSystemBar,
        electricityCostsWithoutPVSystemBar,
        table,
        title,
      ],
    );

    return subsection;
  };
