import { Divider, Grid } from '@material-ui/core';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProposalContextDataDesign } from '@easysolar/proposals/modules/Common/models/ProposalContextData';
import {
  ProposalPreviewDefaultIcon,
  ProposalPreviewDefaultSvgIcon,
} from '@easysolar/proposals/modules/ProposalPreview/components/Layouts/Default/Shared/SvgIcon';

import { ProposalPreviewDefaultSubsection } from '../../../Shared/Subsection';
import { ProposalPreviewDefaultSystemDesignVisualizationCharacteristic } from './Characteristic';
import { getProposalPreviewDefaultSystemDesignVisualizationStyles } from './Visualization.styles';

export interface ProposalPreviewDefaultSystemDesignVisualizationProps {
  design: ProposalContextDataDesign;
}

export const ProposalPreviewDefaultSystemDesignVisualization: FunctionComponent<ProposalPreviewDefaultSystemDesignVisualizationProps> =
  ({ design }) => {
    const classes = getProposalPreviewDefaultSystemDesignVisualizationStyles();

    const { t } = useTranslation();

    const numberOfPanels = useMemo(
      () => (
        <ProposalPreviewDefaultSystemDesignVisualizationCharacteristic
          icon={ProposalPreviewDefaultIcon.Module}
          label={t(
            'Layouts:Default.Sections.SystemDesign.NumberOfModulesLabel',
          )}
          value={design.numberOfPanels}
          unit={t('Shared:Units.Pieces')}
        />
      ),
      [design.numberOfPanels, t],
    );

    const nominalPower = useMemo(
      () => (
        <ProposalPreviewDefaultSystemDesignVisualizationCharacteristic
          icon={ProposalPreviewDefaultIcon.SunPower}
          label={t('Layouts:Default.Sections.SystemDesign.SystemPowerLabel')}
          value={design.nominalPower / 1000}
          unit="kWp"
          fractionDigits={2}
        />
      ),
      [design.nominalPower, t],
    );

    const characteristics = useMemo(
      () => (
        <Grid
          className={classes.root}
          container
          direction="row"
          justifyContent="space-between"
        >
          {numberOfPanels}
          <ProposalPreviewDefaultSvgIcon
            icon={ProposalPreviewDefaultIcon.ChevronRight}
            className={classes.icon}
          />
          {nominalPower}
        </Grid>
      ),
      [classes.icon, classes.root, nominalPower, numberOfPanels],
    );

    const visualization = useMemo(
      () =>
        design.visualization && (
          <img
            src={design.visualization}
            alt={design.name}
            className={classes.visualization}
          />
        ),
      [classes.visualization, design.name, design.visualization],
    );

    const subsection = useMemo(
      () => (
        <ProposalPreviewDefaultSubsection title={design.name}>
          {characteristics}
          <Divider />
          {visualization}
        </ProposalPreviewDefaultSubsection>
      ),
      [characteristics, design.name, visualization],
    );

    return subsection;
  };
